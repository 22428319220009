import { CheckCircleOutlined, CloseCircleOutlined, DeleteFilled, MailOutlined, SyncOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { Alert, Button, Descriptions, List, Space, Tag } from 'antd';
import { flatMap } from 'lodash';
import { useReducer } from 'react';

import { ROUTE } from '@/configs/general';
import { onError } from '@/helpers/message';
import { inbound, request } from '@/models';

import LinkButton from './LinkButton';

const CaseInfo = (props) => {
  const {
    caseData: { data: caseData },
    shipmentData
  } = props;

  console.log(caseData);

  if (!caseData) {
    return <Alert message="Nothing found" type="error" showIcon />;
  }

  if (!caseData.serviceProviderId || caseData.serviceProviderId !== shipmentData.customData?.serviceProviderId) {
    if (caseData.serviceProviderId || (caseData.type === 'caseCreate' && caseData.status === 'success')) {
      return (
        <Alert
          message={
            <>
              Provider mismatch. Check created{' '}
              <a href={`https://serviceorderhub.com/admin/orders/${caseData.id}`} target="_blank" rel="noreferrer">
                case
              </a>
            </>
          }
          type="warning"
          showIcon
        />
      );
    }

    if (caseData.message) {
      return <Alert message={`Case creating failed with message: ${caseData.message}`} type="error" showIcon />;
    }

    return <Alert message="That's weird, ask Viktor about" type="error" showIcon />;
  }

  return <Alert message="Everything seems right. You can mark the activity as resolved" type="success" showIcon />;
};

const ShipmentPreview = (props) => {
  const { data, onRemove, showCaseCreatedStatus } = props;
  const forceUpdate = useReducer(() => ({}))[1];
  const packageNumber = data?.shipmentData?.packages?.[0]?.packageNumber;

  const checkHubCase = useRequest(
    (activityNumber) => request.getJson(`/api/v1/activity/check_hub_case/${activityNumber}`),
    {
      // formatResult: (result) => result.data.filter(({ status }) => status === 'error')[0] || { response: null },
      fetchKey: (activityNumber) => activityNumber,
      manual: true,
      onError
    }
  );

  const sendPrealert = useRequest((onUserEmail) => inbound.sendPrealert(data.guid, onUserEmail), {
    manual: true,
    onError
  });

  const handleSendPrealert = (onUserEmail) => () => sendPrealert.run(onUserEmail);

  const changeCaseCreatedStatus = useRequest(
    (shipmentGuid, itemGuid, status) => inbound.changeCaseCreatedStatus(shipmentGuid, itemGuid, status),
    {
      fetchKey: (_, itemGuid) => itemGuid,
      manual: true,
      onSuccess: (_, [, itemGuid, status]) => {
        for (const { items } of data.packages) {
          for (const item of items) {
            if (item.guid === itemGuid) {
              // Fake it and force re-render to avoid useless data fetching
              item.customData.caseCreated = status;
              forceUpdate();

              return;
            }
          }
        }
      },
      onError
    }
  );

  const handleFetchRequests = (activityNumber) => () => {
    checkHubCase.run(activityNumber);
  };

  const handleCaseStatus = (shipmentGuid, itemGuid, status) => () => {
    changeCaseCreatedStatus.run(shipmentGuid, itemGuid, status);
  };

  return (
    <List
      className="shipment-preview"
      header={
        <Descriptions bordered>
          <Descriptions.Item label="Shipment GUID">
            <LinkButton to={`${ROUTE.INBOUND_SHIPMENT}/${data.guid}`}>{data.guid}</LinkButton>
          </Descriptions.Item>
          {packageNumber && <Descriptions.Item label="Package number">{packageNumber}</Descriptions.Item>}
          {data.finalized && (
            <Descriptions.Item>
              {!sendPrealert.data && (
                <Space>
                  <Button type="primary" onClick={handleSendPrealert()} icon={<MailOutlined />}>
                    Send pre-alert to provider
                  </Button>
                  <Button type="primary" onClick={handleSendPrealert(true)} icon={<MailOutlined />}>
                    Send pre-alert on my email
                  </Button>
                </Space>
              )}
              {sendPrealert?.data?.success && (
                <Alert
                  message="Pre-alert has been added to the queue. It may take up to 10 minutes"
                  type="success"
                  style={{ margin: 0 }}
                />
              )}
            </Descriptions.Item>
          )}
        </Descriptions>
      }
      size="small"
      bordered
      dataSource={flatMap(data.packages, (x) => x.items)}
      renderItem={(x) => (
        <List.Item>
          <LinkButton to={`${ROUTE.INBOUND_ACTIVITY}/${x.customData.activityNumber}`}>
            {x.customData.activityNumber}
          </LinkButton>{' '}
          {x.manufacturer} {x.model}{' '}
          {onRemove && !data.finalized && (
            <Button icon={<DeleteFilled />} size="small" danger onClick={onRemove(data, x)} />
          )}{' '}
          {showCaseCreatedStatus && (
            <>
              <br />
              {!x.customData.caseCreated && (
                <Tag color="processing" icon={<SyncOutlined spin />}>
                  Order info scheduled for sending to external workshop
                </Tag>
              )}
              {x.customData.caseCreated === true && (
                <Tag color="success" icon={<CheckCircleOutlined />}>
                  Order info sent to external workshop
                </Tag>
              )}
              {x.customData.caseCreated === 'fail' && (
                <Space>
                  <Tag color="error" icon={<CloseCircleOutlined />}>
                    Failed to send order info to external workshop due to an error
                  </Tag>
                  {!checkHubCase.fetches[x.customData.activityNumber]?.data && (
                    <Button
                      onClick={handleFetchRequests(x.customData.activityNumber)}
                      loading={checkHubCase.fetches[x.customData.activityNumber]?.loading}
                      size="small"
                      type="primary"
                    >
                      Check why
                    </Button>
                  )}
                  {!changeCaseCreatedStatus.fetches[x.guid]?.data && (
                    <Button
                      onClick={handleCaseStatus(data.guid, x.guid, false)}
                      loading={changeCaseCreatedStatus.fetches[x.guid]?.loading}
                      size="small"
                      type="primary"
                    >
                      Send order info again
                    </Button>
                  )}
                  {!changeCaseCreatedStatus.fetches[x.guid]?.data && (
                    <Button
                      onClick={handleCaseStatus(data.guid, x.guid, true)}
                      loading={changeCaseCreatedStatus.fetches[x.guid]?.loading}
                      size="small"
                      type="primary"
                    >
                      Mark as resolved
                    </Button>
                  )}
                </Space>
              )}
              {checkHubCase.fetches[x.customData.activityNumber]?.data && (
                <CaseInfo caseData={checkHubCase.fetches[x.customData.activityNumber]?.data} shipmentData={data} />
              )}
            </>
          )}
        </List.Item>
      )}
    />
  );
};

export default ShipmentPreview;
