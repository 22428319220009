import { QuestionCircleOutlined, ReloadOutlined } from '@ant-design/icons';
import { useMount, usePersistFn, useRequest, useUnmount } from 'ahooks';
import { Alert, Button, Card, PageHeader, Result, Skeleton, Space } from 'antd';
import { useContext } from 'react';

import { Block } from '@/components';
import { PERMISSION, ROUTE } from '@/configs/general';
import { BalanceInfo } from '@/containers/timeTracker/BalanceInfo';
import { GeneralEmployeeInfo } from '@/containers/timeTracker/GeneralEmployeeInfo';
import { GeneralInfo } from '@/containers/timeTracker/GeneralInfo';
import { useGoBack } from '@/helpers/hooks';
import userContext from '@/helpers/userContext';
import { request, timetracker } from '@/models';

import { TimeTrackerCalendar, VIEW_TYPE } from './components/Calendar';
import { TimeTrackerCheckInOutEdit } from './components/CheckInOutEdit';
import { TimeTrackerIntervalEditForm } from './components/IntervalEditForm';
import { TimeTrackerPendingRequests } from './components/PendingRequests';
import { TIMETRACKER_EVENT, timeTrackerContext, TimeTrackerProvider } from './timeTrackerContext';

const EmployeePage = (props) => {
  const userId = props?.match?.params?.employeeId;
  const { hasPermission } = useContext(userContext);
  const { toggleCallback } = useContext(timeTrackerContext);

  const isAdmin = hasPermission(PERMISSION.TIMETRACKER_ADMIN);

  const employee = useRequest(() => timetracker.getUser(userId));
  const employeeInfo = useRequest(() => timetracker.getEmployeeInfo({ userId }));
  const addWorkDay = useRequest(timetracker.changeWorkday, {
    manual: true
  });

  const timeTrackerUser = employee?.data?.timeTrackerUser;

  const employeeWorkPeriod = useRequest(() => timetracker.getCurrentWorkPeriod({ userId }), {
    ready: !employee.loading,
    refreshDeps: [employee.data, addWorkDay.data],
    onError: (error) => {
      if (error instanceof request.NotFoundError) {
        addWorkDay.run(
          {
            userId: timeTrackerUser?.userId,
            schema: timeTrackerUser?.schema
          },
          true
        );
      }
    }
  });
  const employeeWorkDay = useRequest(() => timetracker.getCurrentWorkDay({ userId }), {
    ready: !employee.loading,
    refreshDeps: [employee.data]
  });

  const handleUpdate = usePersistFn(() => {
    employee.refresh();
    employeeInfo.refresh();
  });

  useMount(() => toggleCallback(TIMETRACKER_EVENT.ON_INTERVAL_UPDATE, 'refreshEmployee', handleUpdate));

  useUnmount(() => toggleCallback(TIMETRACKER_EVENT.ON_INTERVAL_UPDATE, 'refreshEmployee', handleUpdate));

  const pendingRequests = useRequest(() => timetracker.getPendingRequests({ userId }), {
    initialData: [],
    ready: !employee.loading && timeTrackerUser?.pendingRequests > 0,
    refreshDeps: [employee.data]
  });

  const goBack = useGoBack(ROUTE.TIME_TRACKER);

  if (!employee.loading && !employee.data) {
    return (
      <>
        <PageHeader
          title="Time tracker"
          subTitle={
            <Button
              type="link"
              href="https://elkjopnordic.sharepoint.com/:w:/s/icw/IT/Eay7ym1-YGdFi3p4cYTCzZMBFesZpJ-aEBTyZlQnap3Tjw?e=mgvU0L"
              target="_blank"
              rel="noopener noreferrer"
            >
              <QuestionCircleOutlined />
              Manual
            </Button>
          }
          onBack={goBack}
        />
        <Block>
          <Result
            status="404"
            title="Employee not found"
            extra={
              <Button type="primary" onClick={goBack}>
                Back to time tracker dashboard
              </Button>
            }
          />
        </Block>
      </>
    );
  }

  const name = employee.data ? `: ${employee.data.firstName} ${employee.data.lastName}` : '';
  const isOvernight = timetracker.checkOvernight(timeTrackerUser?.activeCheckInTimestamp);
  const disableCalendar = isOvernight && !isAdmin;
  const hideFlexTime = employee?.data?.country === 'NO' || timetracker.isHourlyPaidEmployee(timeTrackerUser);

  const statisticsLoading =
    employee.loading ||
    employeeWorkPeriod.loading ||
    employeeWorkDay.loading ||
    addWorkDay.loading ||
    employeeInfo.loading;

  return (
    <>
      <PageHeader
        title={`Time tracker${name}`}
        subTitle={
          <Button
            type="link"
            href="https://elkjopnordic.sharepoint.com/:w:/s/icw/IT/Eay7ym1-YGdFi3p4cYTCzZMBFesZpJ-aEBTyZlQnap3Tjw?e=mgvU0L"
            target="_blank"
            rel="noopener noreferrer"
          >
            <QuestionCircleOutlined />
            Manual
          </Button>
        }
        onBack={goBack}
        extra={<Button icon={<ReloadOutlined />} onClick={employee.refresh} type="primary" />}
      />
      <GeneralEmployeeInfo title="Employee info" user={employee?.data} loading={employee.loading} />
      <GeneralInfo user={employee?.data} employeeInfo={employeeInfo?.data} loading={statisticsLoading} />
      <BalanceInfo
        user={employee?.data}
        employeeInfo={employeeInfo?.data}
        workPeriod={employeeWorkPeriod?.data}
        workDay={employeeWorkDay?.data}
        loading={statisticsLoading}
      />
      {!employee.loading && pendingRequests.data?.length > 0 && (
        <Block>
          <Card title="Pending requests">
            <Skeleton active loading={pendingRequests.loading}>
              <TimeTrackerPendingRequests data={pendingRequests.data} user={employee.data} />
            </Skeleton>
          </Card>
        </Block>
      )}
      <Block>
        <Card title="Overview">
          <Space direction="vertical" size="large">
            {disableCalendar && (
              <Alert
                message="This employee has stayed checked-in over night. Please add a check-out for this person or delete the check-in to be able to make other changes"
                type="error"
                showIcon
              />
            )}
            <TimeTrackerCalendar
              user={employee?.data}
              editable={!disableCalendar}
              type={VIEW_TYPE.INTERVALS_WEEK}
              hideFlexTime={hideFlexTime}
            />
            <TimeTrackerCalendar user={employee?.data} editable={!disableCalendar} type={VIEW_TYPE.SCHEMAS} />
            <Space direction="vertical" size="middle">
              <TimeTrackerIntervalEditForm
                timeTrackerUser={{ ...(timeTrackerUser || {}), user: employee?.data }}
                allowAddInterval
              />
              <TimeTrackerCheckInOutEdit loading={employee.loading} user={timeTrackerUser} />
            </Space>
          </Space>
        </Card>
      </Block>
    </>
  );
};

const TimeTrackerEmployeePage = (props) => (
  <TimeTrackerProvider>
    <EmployeePage {...props} />
  </TimeTrackerProvider>
);

export { TimeTrackerEmployeePage };
