import { PlusOutlined } from '@ant-design/icons';
import { useBoolean, useRequest } from 'ahooks';
import { Button, Card, Col, Divider, Input, message, PageHeader, Result, Row, Space, Table, Tag } from 'antd';
import { trim } from 'lodash';
import { useState } from 'react';

import { Block, DateInline, LinkButton } from '@/components';
import { ROUTE } from '@/configs/general';
import { SHIPMENTS_TYPE } from '@/configs/shipments';
import { ShipmentsTable } from '@/containers';
import { SelectAutocomplete } from '@/elements';
import { onError } from '@/helpers/message';
import { inbound, request } from '@/models';

const SearchResult = (props) => {
  const { cols, data, loading, title } = props;

  if (!data) {
    return null;
  }

  return (
    <>
      <Divider>{title}</Divider>
      <Block>
        {data.length > 0 && (
          <Table
            className="ant-table-clickable-rows"
            columns={cols}
            dataSource={data}
            rowKey="activityNumber"
            loading={loading}
            pagination={false}
          />
        )}
        {Boolean(!data.length) && <Result title="Nothing found" status="warning" />}
      </Block>
    </>
  );
};

const PushActivitiesPage = () => {
  const [searchActivity, setSearchActivity] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [selectedActivities, changeActivities] = useState({});
  const [providers, setProviders] = useState([]);
  const [selectedProviderId, setProviderId] = useState(null);
  const [areShipmentsVisible, { toggle: toggleShipmentsVisible }] = useBoolean(false);

  const searchByActivity = useRequest(
    () =>
      request.getJson('/api/v1/activity/search_activity', {
        query: { activityNumber: searchActivity, includeHubCase: true }
      }),
    {
      formatResult: (result) => result.data,
      manual: true,
      onError
    }
  );

  const searchByValue = useRequest(() => inbound.search(searchValue, true), {
    manual: true,
    onError
  });

  const getProviders = useRequest(
    () => request.getJson('/api/v1/shipment/providers', { query: { caseGeneratorOnly: true } }),
    {
      onSuccess: (result) => setProviders(result.data.map((x) => ({ label: x.name, value: x.id }))),
      onError
    }
  );
  const pushActivities = useRequest(inbound.pushActivities, {
    manual: true,
    onSuccess: () => {
      setSearchValue('');
      changeActivities({});
      setProviderId(null);
      searchByValue.reset();

      message.success('Activities have been successfully scheduled. We process all activities every 30 minutes', 10);
    },
    onError
  });

  const handleSearchChanged = (setAsValue) => (e) => {
    const input = trim(e.target.value);

    if (setAsValue) {
      setSearchValue(input);

      return;
    }

    setSearchActivity(input);
  };

  const addActivity = (activity) => () => changeActivities({ ...selectedActivities, [activity]: true });

  const removeActivity = (activity) => () => {
    const activities = {
      ...selectedActivities
    };

    delete activities[activity];

    changeActivities(activities);
  };

  const handlePushActivities = () => {
    pushActivities.run(Object.keys(selectedActivities), {
      serviceProviderId: selectedProviderId
    });
  };

  const cols = [
    {
      title: '',
      render: (_, { activityNumber }) => (
        <Button icon={<PlusOutlined />} type="primary" shape="circle" onClick={addActivity(activityNumber)} />
      )
    },
    {
      title: 'Activity',
      render: (_, { activityNumber }) => (
        <LinkButton to={`${ROUTE.INBOUND_ACTIVITY}/${activityNumber}`}>{activityNumber}</LinkButton>
      )
    },
    {
      title: 'Service provider',
      dataIndex: ['cases', '0', 'serviceProvider', 'name'],
      key: 'activityNumber'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status'
    },
    {
      title: 'Date',
      render: (_, activity) => <DateInline value={activity.timestamp || activity.timeStamp} />
    },
    {
      title: 'Model',
      dataIndex: 'prodId',
      key: 'prodId'
    },
    {
      title: 'Name',
      dataIndex: 'customerName',
      key: 'customerName'
    },
    {
      title: 'Address',
      dataIndex: 'customerAddress',
      key: 'customerAddress'
    }
  ];

  return (
    <>
      <PageHeader
        title="Push activities to a selected provider"
        extra={
          <Button type="primary" onClick={() => toggleShipmentsVisible()}>{`${
            areShipmentsVisible ? 'Hide' : 'Show'
          } pushed activities`}</Button>
        }
      />
      <Block
        flexVerticalAlign={
          !searchByActivity.data &&
          !searchByValue.data &&
          !Object.keys(selectedActivities).length &&
          !areShipmentsVisible
        }
        className="push-activities-search-fields"
      >
        <Row align="middle">
          <Col flex="250px">
            <Input.Search
              placeholder="Search by activity number"
              size="large"
              value={searchActivity}
              onChange={handleSearchChanged()}
              onSearch={searchByActivity.run}
              onPressEnter={searchByActivity.run}
              loading={searchByActivity.loading}
              disabled={searchByValue.loading || pushActivities.loading}
            />
          </Col>
          <Col flex="20px">
            <b>OR</b>
          </Col>
          <Col flex="auto">
            <Input.Search
              placeholder="Search by any field for an activity added no longer than 20 days ago"
              size="large"
              value={searchValue}
              onChange={handleSearchChanged(true)}
              onSearch={searchByValue.run}
              onPressEnter={searchByValue.run}
              loading={searchByValue.loading}
              disabled={searchByActivity.loading || pushActivities.loading}
            />
          </Col>
        </Row>
      </Block>
      <SearchResult cols={cols} title="Search by activity number" {...searchByActivity} />
      <SearchResult cols={cols} title="Search by any field" {...searchByValue} />
      {Object.keys(selectedActivities).length > 0 && (
        <Block>
          <Card title="Activities to be pushed">
            <Space direction="vertical" size="middle">
              <div>
                {Object.keys(selectedActivities).map((activityNumber) => (
                  <Tag closable onClose={removeActivity(activityNumber)} key={activityNumber} color="#1890ff">
                    {activityNumber}
                  </Tag>
                ))}
              </div>
              <Space direction="horizontal" size="middle">
                <SelectAutocomplete
                  options={providers}
                  value={selectedProviderId}
                  onSelect={setProviderId}
                  optionFilterProp="label"
                  skipMapping
                  placeholder="Select a provider"
                  style={{ minWidth: '200px' }}
                  loading={getProviders.loading}
                  disabled={pushActivities.loading}
                />
                <Button
                  type="primary"
                  disabled={!selectedProviderId}
                  onClick={handlePushActivities}
                  loading={pushActivities.loading}
                >
                  Push
                </Button>
              </Space>
            </Space>
          </Card>
        </Block>
      )}
      {areShipmentsVisible && (
        <>
          <Divider>Pushed activities</Divider>
          <ShipmentsTable customProviders={providers} type={SHIPMENTS_TYPE.PUSHED} />
        </>
      )}
    </>
  );
};

export { PushActivitiesPage };
