import { useRequest } from 'ahooks';
import { Alert, Button, Col, DatePicker, Form, Input, PageHeader, Result, Row, Select, Spin, Typography } from 'antd';
import moment from 'moment';
import { useContext, useEffect, useReducer, useState } from 'react';

import { Block, LinkButton } from '@/components';
import { ROUTE } from '@/configs/general';
import { LAYOUT } from '@/configs/layout';
import { FIELD, FIELD_SETTINGS, SESAM_OPTIONS } from '@/configs/newActivity';
import { SelectAutocomplete } from '@/elements';
import { onError, showError } from '@/helpers/message';
import userContext from '@/helpers/userContext';
import { inbound, request } from '@/models';

// eslint-disable-next-line no-unused-vars
import { ACTIVITY_FIELDS } from './mock';

const paramsToFetchKey = (params) => params.join('-');

const getFetchedData = (requestData, keys) => requestData.fetches[paramsToFetchKey(keys)]?.data || [];

const NewActivityResult = ({ activityNumber }) => {
  return (
    <Result
      title={`Activity ${activityNumber} was created successfully`}
      extra={
        <LinkButton to={`${ROUTE.INBOUND_ACTIVITY}/${activityNumber}`} type="primary">
          Go to the new activity
        </LinkButton>
      }
      status="success"
      showIcon
    />
  );
};

const AddNewToolboxActivity = () => {
  const { user } = useContext(userContext);
  const persDep = user?.logisticUser?.settings?.persDep;

  const [newActivityNumber, setNewActivityNumber] = useState(null);
  const [innerIsLoading, setInnerIsLoading] = useState(false);
  const [productTypeRequiredFields, setProductTypeRequiredFields] = useState({});
  const forceUpdate = useReducer(() => ({}))[1];

  const manufacturers = useRequest(() => request.getJsonData('/api/v1/inbound/manufacturers'), {
    formatResult: (results) => results.map((x) => ({ value: x.name })),
    initialData: [],
    onError
  });

  const serviceTypes = useRequest(
    (manufacturer) => request.getJsonData('/api/v1/inbound/service_types', { query: { manufacturer } }),
    {
      manual: true,
      formatResult: (results) => results.map((x) => ({ label: x.name, value: String(x.id) })),
      initialData: [],
      onError,
      fetchKey: (manufacturer) => manufacturer
    }
  );

  const productTypes = useRequest(
    (manufacturer, serviceType) =>
      request.getJsonData('/api/v1/inbound/product_types', { query: { manufacturer, serviceType } }),
    {
      manual: true,
      formatResult: (results) => results.map((x) => ({ value: x.name })),
      initialData: [],
      onError,
      fetchKey: (manufacturer, serviceType) => `${manufacturer}-${serviceType}`
    }
  );

  const [form] = Form.useForm();

  const onFinish = async (values) => {
    setInnerIsLoading(true);

    if (moment.isMoment(values?.[FIELD.PURCHASE_DATE])) {
      values[FIELD.PURCHASE_DATE] = values[FIELD.PURCHASE_DATE].format();
    }

    try {
      const activityNumber = await inbound.createActivity({
        ...values,
        PersDep: persDep
      });

      setNewActivityNumber(activityNumber);
    } catch (e) {
      showError(e.message);
    }

    setInnerIsLoading(false);
  };

  const onFinishFailed = ({ errorFields }) => {
    form.scrollToField(errorFields[0].name, { behavior: 'smooth', block: 'center' });
  };

  const setRequiredFieldsOfProductType = (productType) => {
    const isImeiRequired = Boolean(productType?.properties?.requireImei);
    const isSerialRequired = Boolean(productType?.properties?.requireSerial);

    if (!isImeiRequired) {
      form.setFields([
        {
          name: [FIELD.IMEI],
          errors: []
        }
      ]);
    }

    if (!isSerialRequired) {
      form.setFields([
        {
          name: [FIELD.SERIAL],
          errors: []
        }
      ]);
    }

    setProductTypeRequiredFields({
      [FIELD.IMEI]: isImeiRequired,
      [FIELD.SERIAL]: isSerialRequired
    });
  };

  const manufacturersData = manufacturers.data;
  const serviceTypesData = getFetchedData(serviceTypes, [form.getFieldValue(FIELD.MANUFACTURER)]);
  const productTypesData = getFetchedData(productTypes, [
    form.getFieldValue(FIELD.MANUFACTURER),
    form.getFieldValue(FIELD.SERVICE_TYPE)
  ]);

  const setProductType = (type) => {
    const currentProductType = type ?? form.getFieldValue(FIELD.PRODUCT_TYPE) ?? '';
    console.log(productTypesData);

    const productTypeDetails = productTypesData.find((x) => x.value.toLowerCase() === currentProductType.toLowerCase());
    let value = currentProductType;

    if (productTypesData.length === 1 && currentProductType !== productTypesData[0].value) {
      value = productTypesData[0].value;
      setRequiredFieldsOfProductType(productTypesData[0]);
    } else if (!productTypeDetails && currentProductType) {
      value = null;
    } else {
      setRequiredFieldsOfProductType(productTypeDetails);
    }

    form.setFieldsValue({ [FIELD.PRODUCT_TYPE]: value });
  };

  const loadServiceTypes = async () => {
    const manufacturer = form.getFieldValue(FIELD.MANUFACTURER);

    if (!manufacturer) {
      return;
    }

    if (getFetchedData(serviceTypes, [manufacturer]).length) {
      forceUpdate();

      return;
    }

    await serviceTypes.run(manufacturer);
  };

  const loadProductTypes = async () => {
    const manufacturer = form.getFieldValue(FIELD.MANUFACTURER);
    const serviceType = form.getFieldValue(FIELD.SERVICE_TYPE);

    if (!manufacturer || !serviceType) {
      return;
    }

    if (getFetchedData(productTypes, [manufacturer, serviceType]).length) {
      forceUpdate();

      return;
    }

    await productTypes.run(manufacturer, serviceType);
  };

  const onFormValuesUpdate = async (changedValues) => {
    if (changedValues[FIELD.MANUFACTURER]) {
      form.setFieldsValue({ [FIELD.PRODUCT_TYPE]: null });
      await loadServiceTypes();
      await loadProductTypes();
    }

    if (changedValues[FIELD.SERVICE_TYPE]) {
      form.setFieldsValue({ [FIELD.PRODUCT_TYPE]: null });
      await loadProductTypes();
    }

    if (changedValues[FIELD.PRODUCT_TYPE]) {
      setProductType();
    }
  };

  useEffect(() => {
    if (!productTypesData?.length) {
      return;
    }

    setProductType();
  }, [productTypesData]);

  const isLoading = manufacturers.loading || serviceTypes.loading || productTypes.loading || innerIsLoading;

  if (newActivityNumber) {
    return (
      <>
        <PageHeader title="Create a new activity" />
        <Block>
          <NewActivityResult activityNumber={newActivityNumber} />
        </Block>
      </>
    );
  }

  if (!persDep) {
    return (
      <>
        <PageHeader title="Create a new activity" />
        <Spin spinning={isLoading}>
          <Alert
            message="PersDep is missing"
            description={
              <>
                You can set it <a href={ROUTE.MY_PAGE}>here</a>
              </>
            }
            type="error"
          />
        </Spin>
      </>
    );
  }

  return (
    <Block>
      <Spin spinning={isLoading}>
        <Form
          form={form}
          // initialValues={ACTIVITY_FIELDS}
          layout="horizontal"
          name="item-details"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          onValuesChange={onFormValuesUpdate}
          // eslint-disable-next-line no-template-curly-in-string
          validateMessages={{ required: 'Please input ${label}' }}
        >
          <Row type="flex" gutter={16}>
            <Col span={12}>
              <Form.Item
                {...FIELD_SETTINGS[FIELD.MANUFACTURER]}
                {...LAYOUT.L6_W18}
                trigger="onSelect"
                validateTrigger={[]}
              >
                <SelectAutocomplete options={manufacturersData} skipMapping />
              </Form.Item>
              <Form.Item {...FIELD_SETTINGS[FIELD.SERVICE_TYPE]} {...LAYOUT.L6_W18}>
                <Select options={serviceTypesData} />
              </Form.Item>
              <Form.Item {...FIELD_SETTINGS[FIELD.PRODUCT_TYPE]} {...LAYOUT.L6_W18}>
                <Select options={productTypesData} />
              </Form.Item>
              <Form.Item {...FIELD_SETTINGS[FIELD.SESAM_DB]} {...LAYOUT.L6_W18}>
                <Select options={SESAM_OPTIONS} />
              </Form.Item>
              <Form.Item {...FIELD_SETTINGS[FIELD.CUSTOMER_NUMBER]} {...LAYOUT.L6_W18}>
                <Input />
              </Form.Item>
              <Form.Item {...FIELD_SETTINGS[FIELD.PROD_ID]} {...LAYOUT.L6_W18}>
                <Input maxLength={30} />
              </Form.Item>
              <Form.Item {...FIELD_SETTINGS[FIELD.PROD_DESC]} {...LAYOUT.L6_W18}>
                <Input maxLength={30} />
              </Form.Item>
              <Form.Item
                {...FIELD_SETTINGS[FIELD.SERIAL]}
                {...LAYOUT.L6_W18}
                rules={productTypeRequiredFields[FIELD.SERIAL] ? FIELD_SETTINGS[FIELD.SERIAL].rules : []}
              >
                <Input maxLength={30} />
              </Form.Item>
              <Form.Item
                {...FIELD_SETTINGS[FIELD.IMEI]}
                {...LAYOUT.L6_W18}
                rules={productTypeRequiredFields[FIELD.IMEI] ? FIELD_SETTINGS[FIELD.IMEI].rules : []}
              >
                <Input />
              </Form.Item>
              <Form.Item {...FIELD_SETTINGS[FIELD.REF_NO]} {...LAYOUT.L6_W18}>
                <Input maxLength={20} />
              </Form.Item>
              <Form.Item {...FIELD_SETTINGS[FIELD.INSURANCE_COMPANY]} {...LAYOUT.L6_W18}>
                <Input />
              </Form.Item>
              <Form.Item {...FIELD_SETTINGS[FIELD.INSURANCE_NUMBER]} {...LAYOUT.L6_W18}>
                <Input maxLength={20} />
              </Form.Item>
              <Form.Item {...FIELD_SETTINGS[FIELD.PROBLEM]} {...LAYOUT.L6_W18}>
                <Input.TextArea autosize="true" maxLength="50" />
              </Form.Item>
              <Form.Item {...FIELD_SETTINGS[FIELD.COMMENT1]} {...LAYOUT.L6_W18}>
                <Input.TextArea autosize="true" maxLength="50" />
              </Form.Item>
              <Form.Item {...FIELD_SETTINGS[FIELD.COMMENT3]} {...LAYOUT.L6_W18}>
                <Input.TextArea autosize="true" maxLength="50" />
              </Form.Item>
              <Form.Item {...FIELD_SETTINGS[FIELD.PURCHASE_DATE]} {...LAYOUT.L6_W18}>
                <DatePicker />
              </Form.Item>
              <Form.Item {...FIELD_SETTINGS[FIELD.SERVICE_CODE]} {...LAYOUT.L6_W18}>
                <Input />
              </Form.Item>
              <Form.Item {...FIELD_SETTINGS[FIELD.INITIAL_GROUP]} {...LAYOUT.L6_W18}>
                <Input />
              </Form.Item>
              <Form.Item {...FIELD_SETTINGS[FIELD.INITIAL_DISTRICT]} {...LAYOUT.L6_W18}>
                <Input />
              </Form.Item>
              <Form.Item {...FIELD_SETTINGS[FIELD.SPEC]} {...LAYOUT.L6_W18}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Row>
                <Col offset={6}>
                  <Typography.Title level={4}>Customer</Typography.Title>
                </Col>
              </Row>
              <Form.Item {...FIELD_SETTINGS[FIELD.NAME]} {...LAYOUT.L6_W18}>
                <Input />
              </Form.Item>
              <Form.Item {...FIELD_SETTINGS[FIELD.ADD_NAME]} {...LAYOUT.L6_W18}>
                <Input />
              </Form.Item>
              <Form.Item {...FIELD_SETTINGS[FIELD.ADDRESS]} {...LAYOUT.L6_W18}>
                <Input />
              </Form.Item>
              <Form.Item {...FIELD_SETTINGS[FIELD.POSTAL_CODE]} {...LAYOUT.L6_W18}>
                <Input />
              </Form.Item>
              <Form.Item {...FIELD_SETTINGS[FIELD.CITY]} {...LAYOUT.L6_W18}>
                <Input />
              </Form.Item>
              <Form.Item {...FIELD_SETTINGS[FIELD.COUNTRY]} {...LAYOUT.L6_W18}>
                <Input maxLength={2} />
              </Form.Item>
              <Form.Item {...FIELD_SETTINGS[FIELD.PHONE]} {...LAYOUT.L6_W18}>
                <Input />
              </Form.Item>
              <Form.Item {...FIELD_SETTINGS[FIELD.MOBILE]} {...LAYOUT.L6_W18}>
                <Input />
              </Form.Item>
              <Form.Item {...FIELD_SETTINGS[FIELD.EMAIL]} {...LAYOUT.L6_W18}>
                <Input />
              </Form.Item>
              <Row />
              <Row>
                <Col offset={6}>
                  <Typography.Title level={4}>Consumer</Typography.Title>
                </Col>
              </Row>
              <Form.Item {...FIELD_SETTINGS[FIELD.AFI_OWNER]} {...LAYOUT.L6_W18}>
                <Input />
              </Form.Item>
              <Form.Item {...FIELD_SETTINGS[FIELD.AFI_ADDRESS]} {...LAYOUT.L6_W18}>
                <Input />
              </Form.Item>
              <Form.Item {...FIELD_SETTINGS[FIELD.AFI_POSTAL_CODE]} {...LAYOUT.L6_W18}>
                <Input />
              </Form.Item>
              <Form.Item {...FIELD_SETTINGS[FIELD.AFI_POSTAL_DISTRICT]} {...LAYOUT.L6_W18}>
                <Input />
              </Form.Item>
              <Form.Item {...FIELD_SETTINGS[FIELD.AFI_COUNTRY]} {...LAYOUT.L6_W18}>
                <Input maxLength={2} />
              </Form.Item>
              <Form.Item {...FIELD_SETTINGS[FIELD.AFI_PHONE]} {...LAYOUT.L6_W18}>
                <Input />
              </Form.Item>
              <Form.Item {...FIELD_SETTINGS[FIELD.AFI_MOBILE]} {...LAYOUT.L6_W18}>
                <Input />
              </Form.Item>
              <Form.Item {...FIELD_SETTINGS[FIELD.AFI_EMAIL]} {...LAYOUT.L6_W18}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ paddingTop: 0 }}>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Create an activity
            </Button>
          </Row>
        </Form>
      </Spin>
    </Block>
  );
};

export default AddNewToolboxActivity;
