/* eslint-disable no-template-curly-in-string */
import { useRequest } from 'ahooks';
// prettier-ignore
import { Alert, Button, Checkbox, DatePicker, Divider, Form, Input, InputNumber, Select, Skeleton, Spin, Switch } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import { useState } from 'react';
import traverse from 'traverse';

import { PROPERTY_TYPE } from '@/configs/entities';
import { onError } from '@/helpers/message';
import { entities } from '@/models';

const LIMIT = 100;

const dataToOptions = (data = []) => data.map(({ id, name, value }) => ({ label: name || value, value: id }));

const isEmptyValue = (value) => {
  return (
    value === undefined ||
    value === null ||
    Number.isNaN(value) ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0)
  );
};

const CustomSelect = (props) => {
  const { customDataToOptions, entity, extraFilters = {}, value, values = [], ...restProps } = props;
  const isSearchable = _.isString(entity);

  const { data, loading } = useRequest(
    () => entities.getEntityValues(entity, { filters: { id: _.isArray(value) ? { $in: value } : value } }),
    {
      formatResult: (result) => ({
        ...result,
        options: customDataToOptions?.(result.data) || dataToOptions(result.data)
      }),
      initialData: null,
      ready: isSearchable && !isEmptyValue(value)
    }
  );
  const { data: searchResults, loading: searchLoading, run: search } = useRequest(
    (key, searchValue) => {
      const params = searchValue
        ? { filters: { [key]: { $ilike: `${searchValue}%` }, ...extraFilters } }
        : { filters: { ...extraFilters } };

      return entities.getEntityValues(entity, params);
    },
    {
      formatResult: (result) => {
        if (result) {
          return { ...result, options: customDataToOptions?.(result.data) || dataToOptions(result.data) };
        }
      },
      debounceInterval: 300,
      manual: true
    }
  );

  const onSearch = (key) => (searchValue) => {
    search(key, searchValue);
  };

  const onDropdownVisible = (isVisible) => {
    if (!isVisible) {
      return;
    }

    search();
  };

  const searchableProps = isSearchable
    ? {
        showSearch: true,
        filterOption: false,
        onSearch: onSearch('name'),
        onDropdownVisibleChange: onDropdownVisible
      }
    : {};

  return (
    <>
      <Select
        {...restProps}
        {...searchableProps}
        value={value}
        loading={loading}
        options={searchResults?.options || data?.options || values}
        notFoundContent={searchLoading ? <Spin size="small" /> : 'Not Found'}
      />
      {data?.pagination?.count > LIMIT && (
        <Alert message="Too many options, use search to narrow them." type="warning" />
      )}
    </>
  );
};

const CustomDatePicker = (props) => {
  const { onChange, value, ...restProps } = props;
  const [defaultValue] = useState(value ? moment(value) : null);

  const handleChange = (date) => onChange(date ? date.format('YYYY-MM-DD') : null);

  return (
    <>
      <DatePicker defaultValue={defaultValue} onChange={handleChange} {...restProps} />
    </>
  );
};

const typeToComponent = (type) => {
  const components = {
    [PROPERTY_TYPE.ARRAY]: CustomSelect,
    [PROPERTY_TYPE.BOOL]: Switch,
    [PROPERTY_TYPE.BOOLEAN]: Checkbox,
    [PROPERTY_TYPE.CHECKBOX]: Checkbox,
    [PROPERTY_TYPE.DATE]: CustomDatePicker,
    [PROPERTY_TYPE.ENUM]: CustomSelect,
    [PROPERTY_TYPE.NUMBER]: InputNumber,
    [PROPERTY_TYPE.STRING]: Input
  };

  return components[type] || Input;
};

// More information here: https://ant.design/components/form/#validateMessages
const typeToValidationType = (type) => {
  const validationTypes = {
    [PROPERTY_TYPE.ARRAY]: 'array',
    [PROPERTY_TYPE.BOOL]: 'boolean',
    [PROPERTY_TYPE.BOOLEAN]: 'boolean',
    [PROPERTY_TYPE.CHECKBOX]: 'boolean',
    [PROPERTY_TYPE.DATE]: 'string',
    [PROPERTY_TYPE.ENUM]: 'string',
    [PROPERTY_TYPE.NUMBER]: 'number',
    [PROPERTY_TYPE.STRING]: 'string'
  };

  return validationTypes[type] || 'string';
};

// Deeply map arrays of objects to arrays of ids of those objects
const dataToFormData = (data) => {
  // Don't use an arrow function to be able to use traverse's methods of 'this' argument
  // eslint-disable-next-line array-callback-return
  return traverse(data).map(function map(x) {
    // If it is a numeric key (index of array's item) update the value, otherwise do nothing
    if (/^\d+$/.test(this.key)) {
      this.update(x.id);
    }
  });
};

const schemaToFormFields = (schema, params = {}) => {
  const {
    customFormFieldsProps = {},
    data = {},
    extraFiltersByFieldKey = {},
    hiddenFieldsKeys = [],
    parentKeys = null,
    reorderKeys = []
  } = params;
  const { properties } = schema;

  if (!properties) {
    throw new Error(`Field "${parentKeys ? parentKeys.join() : 'unknown'}" has no properties`);
  }

  const fields = [];
  const isNew = _.isEmpty(data);

  const hiddenFields = {};
  _.keyBy(hiddenFieldsKeys, (key) => _.set(hiddenFields, key, true));

  const orderedKeys = Object.keys(properties);

  for (const { key, placeAfter, placeBefore } of reorderKeys) {
    const keyIndex = orderedKeys.findIndex((value) => value === key);

    if (keyIndex === -1) {
      continue;
    }

    if (placeAfter && orderedKeys.some((value) => value === placeAfter)) {
      orderedKeys.splice(keyIndex, 1);
      orderedKeys.splice(orderedKeys.findIndex((value) => value === placeAfter) + 1, 0, key);
    } else if (placeBefore && orderedKeys.some((value) => value === placeBefore)) {
      orderedKeys.splice(keyIndex, 1);
      // prettier-ignore
      orderedKeys.splice(orderedKeys.findIndex((value) => value === placeBefore), 0, key);
    }
  }

  for (const propertyKey of orderedKeys) {
    const property = properties[propertyKey];
    property.key = propertyKey;

    // We can set primary keys for newly created entities if they are not read-only
    if (
      _.get(hiddenFields, [...(parentKeys || []), propertyKey].filter(Boolean)) === true ||
      property.readOnly ||
      (property.primaryKey && !isNew) ||
      property.referencedProperties
    ) {
      continue;
    }

    if (property.title) {
      fields.push({
        title: _.capitalize(_.startCase(property.title))
      });
    }

    if (property.type === 'object') {
      if (property.properties) {
        fields.push(
          ...schemaToFormFields(property, {
            ...params,
            parentKeys: [...(parentKeys || []), propertyKey]
          })
        );
      }

      continue;
    }

    const path = parentKeys ? [...parentKeys, propertyKey] : propertyKey;
    const itemProps = {
      label: property.label || _.startCase(propertyKey),
      name: path,
      placeholder: property.description,
      required: Boolean(property.required),
      // More information here: https://ant.design/components/form/#validateMessages
      rules: [
        {
          required: Boolean(property.required),
          max: property.maxLength,
          min: property.minLength,
          type: typeToValidationType(property.validationType || property.type.toLowerCase())
        }
      ],
      ..._.get(customFormFieldsProps, _.isArray(path) ? [...path, 'itemProps'] : [path, 'itemProps'], {})
    };

    // Pass initial value ONLY if it is defined and the actual data doesn't have any value
    // Otherwise we will get console errors from Antd because of initial values in Form and Form.Item at the same time
    if (property.initialValue !== undefined && _.get(data, path) === undefined) {
      itemProps.initialValue = property.initialValue;
    }

    let componentProps = {};
    const componentType = property.referencedEntities ? PROPERTY_TYPE.ENUM : property.type.toLowerCase();

    if (componentType === PROPERTY_TYPE.NUMBER) {
      componentProps.min = property.min;
      componentProps.max = property.max;
    }

    if (componentType === PROPERTY_TYPE.ENUM) {
      componentProps.entity = property.referencedEntities?.[0];
      componentProps.values = property.values;
    }

    if (componentType === PROPERTY_TYPE.ARRAY) {
      componentProps.entity = property.items?.title;
      componentProps.mode = 'multiple';

      if (extraFiltersByFieldKey?.[property.key]) {
        componentProps.extraFilters = extraFiltersByFieldKey[property.key];
      }
    }

    if ([PROPERTY_TYPE.BOOL, PROPERTY_TYPE.BOOLEAN, PROPERTY_TYPE.CHECKBOX].includes(componentType)) {
      itemProps.valuePropName = 'checked';
    }

    if (componentType === PROPERTY_TYPE.DATE) {
      componentProps.allowClear = !property.required;
    }

    componentProps = {
      ...componentProps,
      ..._.get(customFormFieldsProps, _.isArray(path) ? [...path, 'componentProps'] : [path, 'componentProps'], {})
    };

    fields.push({
      componentProps,
      itemProps,
      Component: typeToComponent(componentType)
    });
  }

  return fields;
};

const SchemaForm = (props) => {
  const {
    customFormFieldsProps = {},
    entity,
    extraFiltersByFieldKey = {},
    // Can be a function
    hiddenFieldsKeys = [],
    id,
    predefinedObjects = {},
    relations = [],
    reorderKeys = [],
    saveButtonText,
    showTitles = true,
    transformValuesBeforeChange,
    withRelations = true
  } = props;

  const { data, loading } = useRequest(
    () => {
      if (id) {
        return entities.getEntityValueById(entity, id, withRelations, relations);
      }

      return entities.getEntitySchema(entity, withRelations);
    },
    {
      formatResult: (result) => {
        const overriddenSchema = _.clone(result.schema || result);

        /*
        Transform keys to be override from `key` to `properties.key`, and replace the initial value
        E.g.:
          `logisticUser.settings` will become `properties.logisticUser.properties.settings';
          `properties.logisticUser.properties.settings` will be overridden by `logisticUser.settings` value.
       */
        _.forIn(predefinedObjects, ({ merge, ...value } = {}, key) => {
          const schemaKey = `properties.${key.replace('.', '.properties.')}`;

          if (merge) {
            _.set(overriddenSchema, schemaKey, { ..._.get(overriddenSchema, schemaKey, {}), ...value });

            return;
          }

          _.set(overriddenSchema, schemaKey, value);
        });

        if (id) {
          return {
            data: dataToFormData(result.data),
            formSchema: schemaToFormFields(overriddenSchema, {
              customFormFieldsProps,
              data: result.data,
              extraFiltersByFieldKey,
              hiddenFieldsKeys: _.isFunction(hiddenFieldsKeys) ? hiddenFieldsKeys(result.data) : hiddenFieldsKeys,
              reorderKeys
            })
          };
        }

        return {
          data: {},
          formSchema: schemaToFormFields(overriddenSchema, {
            customFormFieldsProps,
            extraFiltersByFieldKey,
            hiddenFieldsKeys: _.isFunction(hiddenFieldsKeys) ? hiddenFieldsKeys(result.data) : hiddenFieldsKeys,
            reorderKeys
          })
        };
      },
      onError
    }
  );
  const changeEntityValues = useRequest(
    (values) =>
      entities.changeEntityValues(entity, transformValuesBeforeChange ? transformValuesBeforeChange(values) : values),
    {
      manual: true,
      onSuccess: () => {
        props.onEdit?.();
        props.onSuccess?.();
      },
      onError
    }
  );

  const [form] = Form.useForm();

  const handleFormFinish = (formValues) => {
    const newValues = {
      ...formValues
    };

    if (id) {
      newValues.id = id;
    }

    changeEntityValues.run(newValues);
  };

  return (
    <Skeleton active loading={!data?.formSchema}>
      {props.children}
      <Form
        className="schema-form"
        form={form}
        initialValues={data?.data}
        // More information here: https://ant.design/components/form/#validateMessages
        validateMessages={{
          required: 'Please input ${label}',
          string: {
            len: '${label} must be exactly ${len} characters',
            min: '${label} must be at least ${min} characters',
            max: '${label} cannot be longer than ${max} characters',
            range: '${label} must be between ${min} and ${max} characters'
          }
        }}
        scrollToFirstError
        onFinish={handleFormFinish}
      >
        {data?.formSchema?.map(({ componentProps, itemProps, title, Component }) =>
          title ? (
            showTitles && <Divider key={title}>{title}</Divider>
          ) : (
            <Form.Item key={itemProps.name} {...itemProps}>
              <Component {...componentProps} />
            </Form.Item>
          )
        )}
        <Button type="primary" htmlType="submit" disabled={loading} loading={changeEntityValues.loading}>
          {saveButtonText || `Save ${_.lowerCase(entity)}`}
        </Button>
      </Form>
    </Skeleton>
  );
};

export default SchemaForm;
