import { message, Spin } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import UserContext from '@/helpers/userContext';

class Loadable extends React.Component {
  state = {
    loading: true,
    queryParams: this.props.queryParams,
    queries: {},
    loaded: {}
  };

  async componentDidMount() {
    await this.reload();
  }

  handleQueryParams = (data) => {
    this.setState(
      (prevState) => ({ queryParams: { ...prevState.queryParams, ...data } }),
      async () => {
        await this.reload();
      }
    );
  };

  async requestData(key, endpoint) {
    const { match } = this.props;
    this.setState({ loading: true });

    try {
      const data = await endpoint({ ...(match ? match.params : {}), ...this.state.queryParams });

      this.setState((prevState) => ({
        queries: { ...prevState.queries, [key]: endpoint },
        loaded: { ...prevState.loaded, [key]: data },
        loading: false
      }));
    } catch (e) {
      message.error(e.message);
      this.setState({ loading: false });
    }
  }

  async reload() {
    const { endpoints } = this.props;

    for (const [k, v] of Object.entries(endpoints)) {
      await this.requestData(k, v);
    }
  }

  render() {
    const Component = this.props.component;
    const { permissionToEdit } = this.props;
    const { loading, loaded, queryParams } = this.state;

    return (
      <Spin spinning={loading} delay={500}>
        <UserContext.Consumer>
          {({ hasPermission }) => (
            <Component
              {...loaded}
              queryParams={queryParams}
              canEdit={permissionToEdit && hasPermission(permissionToEdit)}
              onChangeQueryParams={this.handleQueryParams}
            />
          )}
        </UserContext.Consumer>
      </Spin>
    );
  }
}

Loadable.propTypes = {
  component: PropTypes.func.isRequired,
  queryParams: PropTypes.object,
  endpoints: PropTypes.objectOf(PropTypes.func).isRequired,
  match: PropTypes.object
};

export default (Component, props) => <Loadable component={Component} {...props} />;
