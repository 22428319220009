import { DeleteOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { Button, PageHeader, Table } from 'antd';

import { DateInline } from '@/components';
import { onError, showError } from '@/helpers/message';
import CustomerCenterModel from '@/models/customerCenter';

const PAGE_SIZE = 20;

const UnrepliedEmailsPage = () => {
  const unrepliedEmails = useRequest(
    ({ page = 0, pageSize = PAGE_SIZE } = {}) => CustomerCenterModel.getUnrepliedEmails({ page, pageSize }),
    {
      initialData: { data: [], pagination: { offset: 0, count: 0 } },
      onError
    }
  );

  const handleSearch = (page = 1, pageSize = PAGE_SIZE) => unrepliedEmails.run({ page: page - 1, pageSize });

  const removeItem = (item) => async () => {
    try {
      await CustomerCenterModel.markEmailReplied(item.recId);
      handleSearch();
    } catch (e) {
      showError(e.message);
    }
  };

  const cols = [
    {
      title: 'Activity',
      dataIndex: 'activityNumber',
      key: 'activityNumber',
      width: 100
    },
    {
      title: 'Sender',
      dataIndex: 'sender',
      key: 'sender'
    },
    {
      title: 'Recipient',
      dataIndex: 'recipient',
      key: 'recipient'
    },
    {
      title: 'Subject',
      dataIndex: 'subject',
      key: 'subject'
    },
    {
      title: 'Date sent',
      dataIndex: 'sentDate',
      key: 'sentDate',
      width: 185,
      render: (text) => <DateInline value={text} />
    },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 75,
      render: (x, row) => (
        <Button onClick={removeItem(row)}>
          <DeleteOutlined />
        </Button>
      )
    }
  ];

  const paginationProps = {
    current: Math.ceil(unrepliedEmails.data.pagination.offset / PAGE_SIZE) + 1,
    pageSize: PAGE_SIZE,
    total: unrepliedEmails.data.pagination.count,
    onChange: handleSearch,
    showSizeChanger: false
  };

  return (
    <>
      <PageHeader title="Forwarded and unreplied emails" />
      <Table
        columns={cols}
        dataSource={unrepliedEmails.data.data}
        rowKey="recId"
        pagination={paginationProps}
        loading={unrepliedEmails.loading}
        scroll={{ x: 1200 }}
        sticky
      />
    </>
  );
};

export { UnrepliedEmailsPage };
