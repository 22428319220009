export const SESAM_OPTIONS = [{ value: 'Sesam16' }, { value: 'Sesam31' }, { value: 'Sesam56' }];

export const LOAD = {
  MANUFACTURERS: 'manufacturers',
  PRODUCT_TYPES: 'productTypes',
  ROUTING: 'routing',
  SERVICE_TYPES: 'serviceTypes'
};

export const FIELD = {
  MANUFACTURER: 'manufacturer',
  SERVICE_TYPE: 'serviceType',
  PRODUCT_TYPE: 'productType',
  SESAM_DB: 'SesamDb',
  CUSTOMER_NUMBER: 'CustomerNumber',
  PROD_ID: 'ProdId',
  PROD_DESC: 'ProdDesc',
  IMEI: 'IMEINumber',
  SERIAL: 'SerialNo',
  REF_NO: 'RefNo',
  INSURANCE_COMPANY: 'InsuranceCompany',
  INSURANCE_NUMBER: 'InsuranceNumber',
  PROBLEM: 'Problem',
  COMMENT1: 'Comment1',
  COMMENT3: 'Comment3',
  PURCHASE_DATE: 'PurchaseDate',
  SERVICE_CODE: 'ServiceCode',
  INITIAL_GROUP: 'InitialGroup',
  INITIAL_DISTRICT: 'InitialDistrict',
  SPEC: 'Spec',
  NAME: 'Name',
  ADD_NAME: 'AddName',
  ADDRESS: 'Address',
  POSTAL_CODE: 'PostalCode',
  CITY: 'City',
  COUNTRY: 'Country',
  PHONE: 'Phone',
  MOBILE: 'Mobile',
  EMAIL: 'Email',
  AFI_OWNER: 'Afi_ServiceOwner',
  AFI_ADDRESS: 'Afi_ServiceAddress',
  AFI_POSTAL_CODE: 'Afi_ServicePostalCode',
  AFI_POSTAL_DISTRICT: 'Afi_ServicePostalDistrict',
  AFI_COUNTRY: 'Afi_ServiceCountry',
  AFI_PHONE: 'Afi_ServiceLocationPhone',
  AFI_MOBILE: 'Afi_ServiceLocationMobile',
  AFI_EMAIL: 'Afi_ServiceLocationEmail'
};

export const FIELD_SETTINGS = {
  [FIELD.MANUFACTURER]: {
    label: 'Brand',
    name: FIELD.MANUFACTURER,
    rules: [{ required: true }]
  },
  [FIELD.SERVICE_TYPE]: {
    label: 'Service Type',
    name: FIELD.SERVICE_TYPE,
    rules: [{ required: true }]
  },
  [FIELD.PRODUCT_TYPE]: {
    label: 'Product type',
    name: FIELD.PRODUCT_TYPE,
    rules: [{ required: true }]
  },
  [FIELD.SESAM_DB]: {
    label: 'SesamDb',
    name: FIELD.SESAM_DB,
    rules: [{ required: true }]
  },
  [FIELD.CUSTOMER_NUMBER]: {
    label: 'Customer Number',
    name: FIELD.CUSTOMER_NUMBER,
    rules: [{ required: true }]
  },
  [FIELD.PROD_ID]: {
    label: 'Prod ID',
    name: FIELD.PROD_ID,
    rules: [{ required: true }]
  },
  [FIELD.PROD_DESC]: {
    label: 'Prod Desc',
    name: FIELD.PROD_DESC
  },
  [FIELD.IMEI]: {
    label: 'IMEI',
    name: FIELD.IMEI,
    rules: [{ required: true }]
  },
  [FIELD.SERIAL]: {
    label: 'Serial',
    name: FIELD.SERIAL,
    rules: [{ required: true }]
  },
  [FIELD.REF_NO]: {
    label: 'Ref No',
    name: FIELD.REF_NO
  },
  [FIELD.INSURANCE_COMPANY]: {
    label: 'Insurance company',
    name: FIELD.INSURANCE_COMPANY
  },
  [FIELD.INSURANCE_NUMBER]: {
    label: 'Insurance number',
    name: FIELD.INSURANCE_NUMBER
  },
  [FIELD.PROBLEM]: {
    label: 'Problem',
    name: FIELD.PROBLEM
  },
  [FIELD.COMMENT1]: {
    label: 'Comment1',
    name: FIELD.COMMENT1
  },
  [FIELD.COMMENT3]: {
    label: 'Comment3',
    name: FIELD.COMMENT3
  },
  [FIELD.PURCHASE_DATE]: {
    label: 'Purchase date',
    name: FIELD.PURCHASE_DATE
  },
  [FIELD.SERVICE_CODE]: {
    label: 'Service Code',
    name: FIELD.SERVICE_CODE,
    rules: [{ required: true }]
  },
  [FIELD.INITIAL_GROUP]: {
    label: 'Initial Group',
    name: FIELD.INITIAL_GROUP,
    rules: [{ required: true }]
  },
  [FIELD.INITIAL_DISTRICT]: {
    label: 'Initial District',
    name: FIELD.INITIAL_DISTRICT,
    rules: [{ required: true }]
  },
  [FIELD.SPEC]: {
    label: 'Spec',
    name: FIELD.SPEC
  },
  [FIELD.NAME]: {
    label: 'Name',
    name: FIELD.NAME,
    rules: [{ required: true }]
  },
  [FIELD.ADD_NAME]: {
    label: 'Add Name',
    name: FIELD.ADD_NAME
  },
  [FIELD.ADDRESS]: {
    label: 'Address',
    name: FIELD.ADDRESS,
    rules: [{ required: true }]
  },
  [FIELD.POSTAL_CODE]: {
    label: 'Postal Code',
    name: FIELD.POSTAL_CODE,
    rules: [{ required: true }]
  },
  [FIELD.CITY]: {
    label: 'City',
    name: FIELD.CITY,
    rules: [{ required: true }]
  },
  [FIELD.COUNTRY]: {
    label: 'Country',
    name: FIELD.COUNTRY,
    rules: [{ required: true }]
  },
  [FIELD.PHONE]: {
    label: 'Phone',
    name: FIELD.PHONE
  },
  [FIELD.MOBILE]: {
    label: 'Mobile',
    name: FIELD.MOBILE
  },
  [FIELD.EMAIL]: {
    label: 'Email',
    name: FIELD.EMAIL
  },
  [FIELD.AFI_OWNER]: {
    label: 'Owner',
    name: FIELD.AFI_OWNER,
    rules: [{ required: true }]
  },
  [FIELD.AFI_ADDRESS]: {
    label: 'Address',
    name: FIELD.AFI_ADDRESS,
    rules: [{ required: true }]
  },
  [FIELD.AFI_POSTAL_CODE]: {
    label: 'Postal code',
    name: FIELD.AFI_POSTAL_CODE,
    rules: [{ required: true }]
  },
  [FIELD.AFI_POSTAL_DISTRICT]: {
    label: 'Postal district',
    name: FIELD.AFI_POSTAL_DISTRICT,
    rules: [{ required: true }]
  },
  [FIELD.AFI_COUNTRY]: {
    label: 'Country',
    name: FIELD.AFI_COUNTRY,
    rules: [{ required: true }]
  },
  [FIELD.AFI_PHONE]: {
    label: 'Phone',
    name: FIELD.AFI_PHONE
  },
  [FIELD.AFI_MOBILE]: {
    label: 'Mobile',
    name: FIELD.AFI_MOBILE
  },
  [FIELD.AFI_EMAIL]: {
    label: 'Email',
    name: FIELD.AFI_EMAIL
  }
};
