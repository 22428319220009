import Evaporate from 'evaporate';
import sha256 from 'js-sha256';
import sparkMD5 from 'spark-md5';

import { showError } from './message';

const uuid = () => {
  const s4 = () =>
    Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);

  return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
};

const upload = async (file, { awsSettings, uploadFolder }) => {
  try {
    const evaporator = await Evaporate.create({
      signerUrl: '/sign',
      aws_key: awsSettings.key,
      aws_url: awsSettings.url,
      bucket: awsSettings.bucket,
      awsRegion: awsSettings.region,
      awsSignatureVersion: '4',
      computeContentMd5: true,
      cryptoMd5Method: (d) => btoa(sparkMD5.ArrayBuffer.hash(d, true)),
      cryptoHexEncodedHash256: sha256,
      logging: false
    });

    const fileId = uuid();
    const ext = file.name.split('.').reverse()[0].toString();
    const name = `${uploadFolder}/${fileId}.${ext}`;
    await evaporator.add({ name, file });

    return `${awsSettings.url}/${awsSettings.bucket}/${name}`;
  } catch (e) {
    console.log(e);
    showError(e.message);
  }
};

export default {
  upload
};
