import { SaveOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { Button, Form, Input, Select, Switch, TimePicker } from 'antd';
import moment from 'moment';
import { useContext } from 'react';

import { TIMETRACKER_SCHEMA_LABEL } from '@/configs/entities';
import { LAYOUT, LAYOUT_ONLY_WRAPPER } from '@/configs/layout';
import { SelectAutocomplete } from '@/elements';
import { showError } from '@/helpers/message';
import userContext from '@/helpers/userContext';
import { timetracker } from '@/models';

const SCHEMA_OPTIONS = (country) =>
  Object.keys(TIMETRACKER_SCHEMA_LABEL[country] || []).map((key) => ({
    label: TIMETRACKER_SCHEMA_LABEL[country][key],
    value: Number(key)
  }));

const FIELD = {
  EXTERNAL_ID: {
    name: 'externalId',
    label: 'Employee number'
  },
  SUPERVISOR: {
    name: 'supervisorUserId',
    label: 'Supervisor',
    rules: [{ required: true }]
  },
  SCHEMA: {
    name: 'schema',
    label: 'Schema',
    rules: [{ required: true }]
  },
  HOURLY_PAID: {
    name: 'isHourlyPaid',
    label: 'Hourly paid'
  },
  FLEX_TIME_BYPASSED: {
    name: 'trackFlexTime',
    label: 'Turn off trust time'
  },
  WORKDAY_START: {
    name: 'workdayStart',
    label: 'Workday start'
  },
  WORKDAY_END: {
    name: 'workdayEnd',
    label: 'Workday end'
  },
  PREMISE: {
    name: 'locationId',
    label: 'Premise',
    rules: [{ required: true }]
  }
};

const TIME_FORMAT = 'HH:mm';

const TimeTrackerEmployeeEditForm = (props) => {
  const { onSuccess, user } = props;

  const {
    user: { timeTrackerUser }
  } = useContext(userContext);
  const locations = useRequest(timetracker.getLocations, {
    formatResult: (results) => results.map(({ id, name, address }) => ({ label: `${name}: ${address}`, value: id }))
  });
  const users = useRequest(timetracker.getUsers, {
    formatResult: (results) =>
      results
        .filter(({ id }) => id !== user?.userId)
        .map(({ id, firstName, lastName }) => ({ label: `${firstName} ${lastName}`, value: id }))
  });
  const changeUser = useRequest(timetracker.changeUser, { manual: true, throwOnError: true });
  const initialValues = {
    schema: user?.schema || 100,
    isHourlyPaid: user?.isHourlyPaid,
    locationId: user?.locationId,
    externalId: user?.externalId,
    trackFlexTime: !user?.flexTimeBypassed,
    workdayStart: user?.workdayStart ? moment(user.workdayStart, TIME_FORMAT) : null,
    workdayEnd: user?.workdayEnd ? moment(user.workdayEnd, TIME_FORMAT) : null,
    supervisorUserId: user?.supervisorUserId
  };

  // Pre-select current user as a supervisor on user adding
  if (!user?.userId) {
    initialValues.supervisorUserId = timeTrackerUser?.userId;
  }

  const [form] = Form.useForm();

  const onFinish = async (values) => {
    const { externalId, isHourlyPaid, locationId } = values;
    const { schema, supervisorUserId, trackFlexTime, workdayStart, workdayEnd } = values;

    try {
      await changeUser.run({
        id: user?.userId || user.user.id,
        timeTrackerUser: {
          locationId,
          schema,
          isHourlyPaid,
          supervisorUserId: supervisorUserId || null,
          externalId,
          flexTimeBypassed: !trackFlexTime,
          workdayStart: workdayStart?.format(TIME_FORMAT) || null,
          workdayEnd: workdayEnd?.format(TIME_FORMAT) || null
        }
      });

      if (onSuccess) {
        onSuccess();
      }
    } catch (e) {
      showError(e.message, { messagePrefix: 'Time tracker error: ' });
    }
  };

  const loading = locations.loading || users.loading || changeUser.loading;

  return (
    <Form
      form={form}
      initialValues={initialValues}
      onFinish={onFinish}
      scrollToFirstError
      validateMessages={{
        // eslint-disable-next-line no-template-curly-in-string
        required: 'Please input ${label}'
      }}
    >
      <Form.Item {...FIELD.SUPERVISOR} {...LAYOUT.L8_W16}>
        <SelectAutocomplete options={users.data || []} skipMapping disabled={loading} />
      </Form.Item>
      <Form.Item {...FIELD.EXTERNAL_ID} {...LAYOUT.L8_W16}>
        <Input disabled={loading} />
      </Form.Item>
      <Form.Item {...FIELD.SCHEMA} {...LAYOUT.L8_W16}>
        <Select
          options={SCHEMA_OPTIONS(user?.user?.country)}
          placeholder="Select a schema"
          disabled={loading}
          defaultValue={100}
        />
      </Form.Item>
      <Form.Item {...FIELD.HOURLY_PAID} {...LAYOUT.L8_W16} valuePropName="checked">
        <Switch disabled={loading} />
      </Form.Item>
      <Form.Item {...FIELD.FLEX_TIME_BYPASSED} {...LAYOUT.L8_W16} valuePropName="checked">
        <Switch disabled={loading} />
      </Form.Item>
      <Form.Item {...FIELD.WORKDAY_START} {...LAYOUT.L8_W16}>
        <TimePicker format={TIME_FORMAT} allowClear showNow={false} />
      </Form.Item>
      <Form.Item {...FIELD.WORKDAY_END} {...LAYOUT.L8_W16}>
        <TimePicker format={TIME_FORMAT} allowClear showNow={false} />
      </Form.Item>
      <Form.Item {...FIELD.PREMISE} {...LAYOUT.L8_W16} trigger="onSelect" validateTrigger="onSelect">
        <SelectAutocomplete
          options={locations.data || []}
          skipMapping
          placeholder="Select a premise"
          disabled={loading}
        />
      </Form.Item>
      <Form.Item {...LAYOUT_ONLY_WRAPPER.W16_O8}>
        <Button icon={<SaveOutlined />} loading={loading} type="primary" htmlType="submit">
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

export { TimeTrackerEmployeeEditForm };
