import { PrinterOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { Button, Dropdown, Menu } from 'antd';
import { useContext } from 'react';

import userContext from '@/helpers/userContext';
import { inbound } from '@/models';

const PrintButton = (props) => {
  const { pdfUrl, type } = props;
  const { loading, run: print } = useRequest(inbound.printPdf, { manual: true });
  const { user } = useContext(userContext);

  if (!user?.printers?.length) {
    return null;
  }

  const handlePrint = (printerId) => {
    if (loading) {
      return;
    }

    print(Number(printerId), pdfUrl);
  };

  const onPrinterClick = (id) => () => handlePrint(id);

  const { printers } = user;

  if (printers.length === 1) {
    return <Button icon={<PrinterOutlined />} onClick={onPrinterClick(printers[0].id)} type="primary" />;
  }

  const defaultByTypePrinterId = (printers.find(({ settings }) => settings.type === type) || printers[0]).id;

  return (
    <Dropdown
      overlay={
        <Menu onClick={({ key }) => handlePrint(key)}>
          {printers.map(({ id, name }) => (
            <Menu.Item key={id}>{name}</Menu.Item>
          ))}
        </Menu>
      }
    >
      <Button
        icon={<PrinterOutlined />}
        onClick={onPrinterClick(defaultByTypePrinterId)}
        type="primary"
        loading={loading}
      />
    </Dropdown>
  );
};

export default PrintButton;
