import { Input, Modal } from 'antd';
import PropTypes from 'prop-types';
import { useState } from 'react';

const MissedItem = (props) => {
  const { onConfirm, onClose, ...modalProps } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [comment, setComment] = useState('');

  const handleInput = (e) => setComment(e.target.value);

  const handleCloseModal = (saveComment) => async () => {
    if (saveComment) {
      setIsLoading(true);

      await onConfirm(comment);

      setIsLoading(false);
    }

    onClose();
  };

  return (
    <Modal
      title="Missed item"
      {...modalProps}
      maskClosable={false}
      onClose={onClose}
      onOk={handleCloseModal(true)}
      onCancel={handleCloseModal(false)}
      okButtonProps={{ loading: isLoading }}
    >
      <p>Please write your comment here:</p>
      <Input.TextArea autosize value={comment} onChange={handleInput} />
    </Modal>
  );
};

MissedItem.propTypes = {
  visible: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

export default MissedItem;
