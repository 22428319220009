export const ENTITY = {
  AUTH_PERMISSION: 'AuthPermission',
  AUTH_PROJECT: 'AuthProject',
  AUTH_ROLE: 'AuthRole',
  CONTACT_DATA: 'ContactData',
  DEPARTMENT: 'Department',
  LOGISTIC_USER: 'LogisticUser',
  PRINTER: 'Printer',
  TEAM: 'Team',
  TIMETRACKER_USER: 'TimeTrackerUser',
  USER: 'User',
  TRANSPORT_CONTAINER: 'TransportContainer'
};

export const PRINTER_TYPE = {
  A4: 'a4',
  BIG_LABEL: 'bigLabel',
  SMALL_LABEL: 'smallLabel'
};

export const PRINTER_TYPE_NAME = {
  [PRINTER_TYPE.A4]: 'A4',
  [PRINTER_TYPE.BIG_LABEL]: 'Big label',
  [PRINTER_TYPE.SMALL_LABEL]: 'Small label'
};

export const PROPERTY_TYPE = {
  ARRAY: 'array',
  BOOL: 'bool',
  BOOLEAN: 'boolean',
  CHECKBOX: 'checkbox',
  DATE: 'date',
  ENUM: 'enum',
  NUMBER: 'number',
  STRING: 'string'
};

export const PRINTER_SETTINGS = {
  title: 'Settings',
  type: 'object',
  properties: {
    type: {
      type: PROPERTY_TYPE.ENUM,
      values: [
        { value: PRINTER_TYPE.A4, label: PRINTER_TYPE_NAME[PRINTER_TYPE.A4] },
        { value: PRINTER_TYPE.BIG_LABEL, label: PRINTER_TYPE_NAME[PRINTER_TYPE.BIG_LABEL] },
        { value: PRINTER_TYPE.SMALL_LABEL, label: PRINTER_TYPE_NAME[PRINTER_TYPE.SMALL_LABEL] }
      ],
      initialValue: PRINTER_TYPE.A4
    },
    quantity: { type: PROPERTY_TYPE.NUMBER, initialValue: 1, min: 1, required: true },
    printNodeId: { type: PROPERTY_TYPE.NUMBER, initialValue: 0, required: true },
    rotate: {
      type: PROPERTY_TYPE.ENUM,
      values: [
        { value: '0', label: '0º' },
        { value: '90', label: '90º' },
        { value: '180', label: '180º' },
        { value: '270', label: '270º' }
      ],
      required: false
    },
    labelHomeX: { title: 'Small labels only', type: PROPERTY_TYPE.NUMBER, initialValue: 0, required: true },
    labelHomeY: { type: PROPERTY_TYPE.NUMBER, initialValue: 0, required: true },
    labelShift: { type: PROPERTY_TYPE.NUMBER, initialValue: 0, required: true },
    labelTop: { type: PROPERTY_TYPE.NUMBER, initialValue: 0, required: true },
    inverted: { type: PROPERTY_TYPE.CHECKBOX, initialValue: false, required: false }
  }
};

export const LOGISTIC_USER_SETTINGS = {
  title: 'Settings',
  type: 'object',
  properties: {
    persDep: {
      type: PROPERTY_TYPE.ENUM,
      values: [
        { value: 'ICHEL', label: 'ICHEL (Helsinki)' },
        { value: 'ICCSK', label: 'ICCSK (Kongsvinger)' },
        { value: 'CSLIN', label: 'CSLIN (Linköping)' }
      ],
      required: true
    },
    hideComments: { type: PROPERTY_TYPE.CHECKBOX, initialValue: false },
    commentsInvisibleToCustomer: { type: PROPERTY_TYPE.CHECKBOX, initialValue: false }
  }
};

export const TIMETRACKER_SCHEMA_LABEL = {
  SE: {
    '0': '0%',
    '25': '25% (1 h 54 m)',
    '50': '50% (3 h 48 m)',
    '75': '75% (5 h 42 m)',
    '100': '100% (7 h 36 m)'
  },
  NO: {
    '0': '0%',
    '25': '25% (1 h 52 m)',
    '50': '50% (3 h 45 m)',
    '60': '60% (4 h 30 m)',
    '75': '75% (5 h 37 m)',
    '100': '100% (7 h 30 m)'
  }
};
