import { useGoTo } from '@/helpers/hooks';

const { Button } = require('antd');

const LinkButton = (props) => {
  const { to, children, ...buttonProps } = props;

  const goTo = useGoTo(to);

  return (
    <Button onClick={goTo} type="link" {...buttonProps}>
      {children}
    </Button>
  );
};

export default LinkButton;
