const DateInline = (props) => {
  const { asString, value } = props;

  const data = value ? new Date(value).toLocaleString('sv-SE') : '–';

  if (asString) {
    return data;
  }

  return <>{data}</>;
};

export default DateInline;
