import { Col, Row } from 'antd';
import classNames from 'classnames';

const Block = (props) => {
  const { className, children, fitContent, flexVerticalAlign, padded, transparent, ...rowProps } = props;

  if (!children) {
    return null;
  }

  return (
    <Row
      className={classNames(className, 'ant-block', {
        'ant-row-fit-content': fitContent,
        'ant-row-flex-vertical-align': flexVerticalAlign,
        'ant-row-padded': padded,
        'ant-row-transparent': transparent
      })}
      {...rowProps}
    >
      <Col span={24}>{children}</Col>
    </Row>
  );
};

export default Block;
