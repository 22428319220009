// import { DoubleRightOutlined } from '@ant-design/icons';
import { Badge, Card, Descriptions, Space, Spin, Tag } from 'antd';

import { LinkButton } from '@/components';
import { ROUTE } from '@/configs/general';

const STATUS = {
  WAITING: 'Waiting',
  MISSING: 'Missing'
};

const Item = (props) => {
  // const { item, spinning, canProcess, onToggleArrived } = props;
  const { item, hideStatus, spinning } = props;
  const activityNumber = item?.customData?.activityNumber || item?.activityNumber || '–';
  const note = item?.customData?.logisticNote;
  const status = item?.customData?.status;

  return (
    <Spin spinning={spinning} delay={500} key={item.guid}>
      <Card
        // extra={
        //   canProcess && (
        //     <Button onClick={onToggleArrived} type="primary">
        //       <DoubleRightOutlined /> Process activity
        //     </Button>
        //   )
        // }
        title={
          activityNumber ? (
            <LinkButton
              to={`${ROUTE.INBOUND_ACTIVITY}/${activityNumber}?goBack=${encodeURIComponent(
                window.location.pathname + window.location.search
              )}`}
            >
              {activityNumber}
            </LinkButton>
          ) : (
            '-'
          )
        }
        type="inner"
        size="small"
      >
        {hideStatus && (
          <Space size="large">
            <span>Ref No: {item.senderRef || '–'}</span>
            <span>
              Device: {item.brand} {item.model}
            </span>
            {note && (
              <span>
                Note: <Tag color="processing">{note}</Tag>
              </span>
            )}
            <span>Shipment item GUID: {item.guid}</span>
          </Space>
        )}
        {!hideStatus && (
          <Descriptions>
            <Descriptions.Item label="Ref No">{item.senderRef || '–'}</Descriptions.Item>
            <Descriptions.Item label="Device" span={2}>
              {item.brand} {item.model}
            </Descriptions.Item>
            <Descriptions.Item label="Status">
              <Badge status={status === STATUS.WAITING ? 'processing' : 'warning'} text={status} />
            </Descriptions.Item>
            {note && (
              <Descriptions.Item label="Note" span={2}>
                <Tag color="processing">{note}</Tag>
              </Descriptions.Item>
            )}
            <Descriptions.Item label="Shipment item GUID" span={3}>
              {item.guid}
            </Descriptions.Item>
          </Descriptions>
        )}
      </Card>
    </Spin>
  );
};

export default Item;
