import { DeleteOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';
import { useBoolean, useDebounceFn, useMount, useRequest, useUnmount } from 'ahooks';
import { Button, DatePicker, Divider, Drawer, Form, Popconfirm, Radio, Select, Slider, Space, TimePicker } from 'antd';
import moment from 'moment';
import { useContext, useState } from 'react';

import { onTimeTrackerError } from '@/helpers/message';
import { timetracker } from '@/models';

import {
  HOURLY_ABSENCE,
  HOURLY_ABSENCE_LABEL,
  HOURLY_ABSENCE_LABEL_NO,
  HOURLY_ABSENCE_NO,
  HOURLY_WORKTYPE_LABEL,
  RATE_LABEL,
  RATE_LABEL_NO,
  WAGE_CODE,
  WORKTYPE,
  WORKTYPE_LABEL
} from '../configs';
import { TIMETRACKER_EVENT, timeTrackerContext } from '../timeTrackerContext';

const WORKTYPE_OPTIONS_NO = [
  {
    label: WORKTYPE_LABEL[WORKTYPE.REGULAR],
    value: WORKTYPE.REGULAR
  },
  {
    label: 'At work outside schema',
    value: WORKTYPE.FLEX_TIME
  },
  {
    label: WORKTYPE_LABEL[WORKTYPE.PAID_OVERTIME],
    value: WORKTYPE.PAID_OVERTIME
  }
];

const WORKTYPE_OPTIONS = [
  {
    label: WORKTYPE_LABEL[WORKTYPE.REGULAR],
    value: WORKTYPE.REGULAR
  },
  {
    label: WORKTYPE_LABEL[WORKTYPE.FLEX_TIME],
    value: WORKTYPE.FLEX_TIME
  },
  {
    label: WORKTYPE_LABEL[WORKTYPE.COMPENSATION_TIME],
    value: WORKTYPE.COMPENSATION_TIME
  },
  {
    label: WORKTYPE_LABEL[WORKTYPE.PAID_OVERTIME],
    value: WORKTYPE.PAID_OVERTIME
  }
];

const WORKTYPE_OPTIONS_WITH_MERTID = [
  ...WORKTYPE_OPTIONS,
  {
    label: WORKTYPE_LABEL[WORKTYPE.MERTID],
    value: WORKTYPE.MERTID
  }
];

const WORKTYPE_HOURLY_PAID_OPTIONS = [
  {
    label: HOURLY_WORKTYPE_LABEL[WORKTYPE.REGULAR],
    value: WORKTYPE.REGULAR
  },
  {
    label: HOURLY_WORKTYPE_LABEL[WORKTYPE.COMPENSATION_TIME],
    value: WORKTYPE.COMPENSATION_TIME
  },
  {
    label: HOURLY_WORKTYPE_LABEL[WORKTYPE.PAID_OVERTIME],
    value: WORKTYPE.PAID_OVERTIME
  }
];

const APPROVED_OPTIONS = [
  {
    label: 'Approved',
    value: true
  },
  {
    label: 'Rejected',
    value: false
  }
];

const RATE_OPTIONS = [
  {
    label: RATE_LABEL[150],
    value: 150
  },
  {
    label: RATE_LABEL[200],
    value: 200
  }
];

const RATE_OPTIONS_NO = [
  {
    label: RATE_LABEL_NO[150],
    value: 150
  },
  {
    label: RATE_LABEL_NO[200],
    value: 200
  }
];

const FLEXTIME_RATE_OPTIONS_NO = [
  {
    label: 'Compensate time deficit',
    value: 100
  },
  {
    label: 'At good will',
    value: 0
  }
];

const BASIC_RATE = 100;
const RATED_WORKTYPES = [WORKTYPE.COMPENSATION_TIME, WORKTYPE.PAID_OVERTIME];
const RATED_WORKTYPES_NO = [WORKTYPE.FLEX_TIME, WORKTYPE.PAID_OVERTIME];
const FAKE_WORKTYPE = {
  TRAVEL_TIME_WEEKDAY: 'travelTimeWeekday',
  TRAVEL_TIME_WEEKEND: 'travelTimeWeekend',
  USE_COMPENSATION_TIME: 'useCompensationTime'
};

const ABSENCE_OPTIONS = {
  SE: Object.values(HOURLY_ABSENCE).map((value) => ({ label: HOURLY_ABSENCE_LABEL[value], value })),
  NO: Object.values(HOURLY_ABSENCE_NO).map((value) => ({ label: HOURLY_ABSENCE_LABEL_NO[value], value }))
};
const OTHER_ABSENCE_OPTIONS = [
  { label: 'Travel time workday', value: FAKE_WORKTYPE.TRAVEL_TIME_WEEKDAY },
  { label: 'Travel time weekend', value: FAKE_WORKTYPE.TRAVEL_TIME_WEEKEND },
  { label: 'Use compensation time', value: FAKE_WORKTYPE.USE_COMPENSATION_TIME }
];
const OTHER_HOURLY_PAID_ABSENCE_OPTIONS = [
  { label: 'Use compensation time', value: FAKE_WORKTYPE.USE_COMPENSATION_TIME }
];

const getWorkTypeFromProps = (props) => {
  const { payload, rate, workType } = props;

  if (payload?.wageCode) {
    if (payload.wageCode === WAGE_CODE[2274]) {
      return FAKE_WORKTYPE.TRAVEL_TIME_WEEKDAY;
    }

    if (payload.wageCode === WAGE_CODE[2275]) {
      return FAKE_WORKTYPE.TRAVEL_TIME_WEEKEND;
    }

    return WORKTYPE.REGULAR;
  }

  if (workType === WORKTYPE.COMPENSATION_TIME && rate < 0) {
    return FAKE_WORKTYPE.USE_COMPENSATION_TIME;
  }

  return props.workType;
};

const getWorktypeOptions = (timeTrackerUser) => {
  if (timeTrackerUser.user.country === 'NO') {
    return WORKTYPE_OPTIONS_NO;
  }

  const isHourlyPaid = timetracker.isHourlyPaidEmployee(timeTrackerUser);
  const isEmployeeWithReducedSchema = timetracker.isEmployeeWithReducedSchema(timeTrackerUser);

  if (isHourlyPaid) {
    return WORKTYPE_HOURLY_PAID_OPTIONS;
  }

  return isEmployeeWithReducedSchema ? WORKTYPE_OPTIONS_WITH_MERTID : WORKTYPE_OPTIONS;
};

const TimeTrackerIntervalEditForm = (props) => {
  const { toggleCallback } = useContext(timeTrackerContext);
  const [selectedInterval, selectInterval] = useState(null);

  const handleSelectInterval = (intervalId) => () => selectInterval(intervalId);

  const initCallbacks = () => {
    toggleCallback(TIMETRACKER_EVENT.ON_INTERVAL_SELECT, 'selectInterval', selectInterval);
    toggleCallback(TIMETRACKER_EVENT.ON_INTERVAL_UPDATE, 'closeIntervalModalOnUpdate', handleSelectInterval(null));
    toggleCallback(TIMETRACKER_EVENT.ON_INTERVAL_CANCEL, 'closeIntervalModal', handleSelectInterval(null));
  };

  useMount(initCallbacks);

  useUnmount(initCallbacks);

  const timeTrackerUser =
    selectedInterval?.timeTrackerUser || selectedInterval?.user?.timeTrackerUser || props.timeTrackerUser;

  if (!timeTrackerUser?.user) {
    if (selectedInterval?.timeTrackerUser?.user) {
      timeTrackerUser.user = selectedInterval.timeTrackerUser.user;
    } else if (selectedInterval?.user) {
      timeTrackerUser.user = selectedInterval.user;
    } else if (props.user) {
      timeTrackerUser.user = props.user;
    }
  }

  return (
    <>
      {props.allowAddInterval && (
        <Button type="primary" ghost onClick={handleSelectInterval('new')}>
          Add new interval
        </Button>
      )}
      <Drawer
        title={selectedInterval === 'new' ? 'Add time interval' : 'Edit time interval'}
        visible={selectedInterval !== null}
        onClose={handleSelectInterval(null)}
        width={720}
        destroyOnClose
      >
        <IntervalForm {...(selectedInterval === 'new' ? {} : selectedInterval)} timeTrackerUser={timeTrackerUser} />
      </Drawer>
    </>
  );
};

const formatTimeRangeToTimeSlider = (values) => {
  const start = values?.[0];
  const end = values?.[1];
  const endOfDate = start && !end ? 1439 : null;

  return [
    start ? start.diff(start.clone().startOf('day'), 'minutes') : null,
    end ? end.diff(end.clone().startOf('day'), 'minutes') : endOfDate
  ];
};

const formatTimeSliderToTimeRange = (values, allValues) => {
  const [start, end] = [allValues.timeRange?.[0] || allValues.date, allValues.timeRange?.[1] || allValues.date];
  const [minutesToStart, minutesToEnd] = values || [];

  return [
    minutesToStart ? start.clone().startOf('day').add(minutesToStart, 'minutes') : null,
    minutesToEnd ? end.clone().startOf('day').add(minutesToEnd, 'minutes') : null
  ];
};

const IntervalForm = (props) => {
  const { id, beginTimestamp, endTimestamp, timeTrackerUser, userId } = props;
  const { onIntervalUpdate, onIntervalCancel, onPendingRequestResolve } = useContext(timeTrackerContext);
  const [defaultDate] = useState(moment(beginTimestamp).startOf('day') || moment().startOf('day'));
  const [defaultTimeRange] = useState([
    beginTimestamp ? moment(beginTimestamp) : undefined,
    endTimestamp ? moment(endTimestamp) : undefined
  ]);
  const [deleteConfirmationVisible, { setTrue: showDeleteConfirmation, setFalse: hideDeleteConfirmation }] = useBoolean(
    false
  );
  const userInfo = useRequest(() => timetracker.getEmployeeInfo(timeTrackerUser || { userId }), {
    onError: onTimeTrackerError
  });

  const handleIntervalChange = () => {
    onIntervalUpdate();
    onPendingRequestResolve(timeTrackerUser || { userId });
  };
  const changeInterval = useRequest(timetracker.changeInterval, {
    manual: true,
    onSuccess: handleIntervalChange,
    onError: onTimeTrackerError
  });
  const deleteInterval = useRequest(() => timetracker.deleteInterval({ id, userId }), {
    manual: true,
    onSuccess: handleIntervalChange,
    onError: onTimeTrackerError
  });
  const loading = userInfo.loading || changeInterval.loading || deleteInterval.loading;
  const isHourlyPaid = timetracker.isHourlyPaidEmployee(timeTrackerUser);

  const [form] = Form.useForm();

  const { run: handleFormValuesChange } = useDebounceFn(
    (changedValues, allValues) => {
      if (changedValues.absence) {
        form.setFieldsValue({
          workType: null
        });
      } else if (changedValues.workType) {
        form.setFieldsValue({
          absence: null
        });
      } else if (changedValues.timeRange) {
        const [start, end] = changedValues.timeRange;

        if (Math.abs(start.diff(end, 'hours')) > 24) {
          end.set({ year: start.year(), month: start.month(), date: start.date() });

          form.setFieldsValue({
            timeRange: [start, end]
          });
        }

        form.setFieldsValue({
          timeSlider: formatTimeRangeToTimeSlider(changedValues.timeRange)
        });
      } else if (changedValues.timeSlider) {
        form.setFieldsValue({
          timeRange: formatTimeSliderToTimeRange(changedValues.timeSlider, allValues)
        });
      }
    },
    { wait: 100 }
  );

  const handleFinish = (formValues) => {
    let workType = formValues.absence ? WORKTYPE.ABSENCE : formValues.workType;
    const isRatedWorktype = RATED_WORKTYPES.includes(workType);
    const approved = isRatedWorktype ? formValues.approved : null;
    let payload = null;
    let rate = null;

    if (workType === FAKE_WORKTYPE.USE_COMPENSATION_TIME) {
      workType = WORKTYPE.COMPENSATION_TIME;
      rate = -BASIC_RATE;
    } else if (workType === FAKE_WORKTYPE.TRAVEL_TIME_WEEKDAY) {
      workType = WORKTYPE.ABSENCE;
      payload = { wageCode: WAGE_CODE[2274] };
    } else if (workType === FAKE_WORKTYPE.TRAVEL_TIME_WEEKEND) {
      workType = WORKTYPE.ABSENCE;
      payload = { wageCode: WAGE_CODE[2275] };
    } else if (workType === WORKTYPE.ABSENCE) {
      payload = { code: formValues.absence };
    }

    if (!rate) {
      rate = (timeTrackerUser.user.country === 'SE' ? RATED_WORKTYPES : RATED_WORKTYPES_NO).includes(workType)
        ? formValues.rate
        : BASIC_RATE;
    }

    const data = {
      id,
      userId: userId || timeTrackerUser.userId,
      ...(form.isFieldTouched('date') || form.isFieldTouched('timeRange')
        ? {
            beginTimestamp: formValues.date
              .clone()
              .add(formValues.timeRange[0].diff(formValues.timeRange[0].clone().startOf('day')))
              .utc()
              .format(),
            endTimestamp: formValues.date
              .clone()
              .add(formValues.timeRange[1].diff(formValues.timeRange[1].clone().startOf('day')))
              .utc()
              .format()
          }
        : {}),
      workType,
      rate,
      approved,
      payload
    };

    if (Number.isNaN(rate)) {
      delete data.rate;
    }

    if (!id) {
      delete data.id;
    }

    changeInterval.run(data);
  };

  const formatSliderTooltip = (value) => `${`0${Math.floor(value / 60)}`.slice(-2)}:${`0${value % 60}`.slice(-2)}`;

  const setIntervalUsingPreset = (preset) => () => {
    const day = form.getFieldValue('date');
    const start = 8 * 60;
    const end = start + timetracker.getSchemaMinutes(timeTrackerUser, true, preset);

    form.setFieldsValue({
      timeRange: [day.clone().startOf('day').add(start, 'minutes'), day.clone().startOf('day').add(end, 'minutes')],
      timeSlider: [start, end]
    });
  };

  if (!timeTrackerUser?.user?.country) {
    return null;
  }

  return (
    <Form
      form={form}
      initialValues={{
        approved: props.approved,
        absence: props?.payload?.code,
        date: defaultDate,
        timeRange: defaultTimeRange,
        timeSlider: formatTimeRangeToTimeSlider(defaultTimeRange),
        workType: getWorkTypeFromProps(props),
        rate: props.rate ?? BASIC_RATE
      }}
      disabled={loading}
      scrollToFirstError
      onValuesChange={handleFormValuesChange}
      onFinish={handleFinish}
    >
      <Form.Item label="Date and time range">
        <Space>
          <Form.Item name="date" noStyle>
            <DatePicker allowClear={false} showToday={false} />
          </Form.Item>
          <Form.Item
            label="Time range"
            name="timeRange"
            noStyle
            rules={[{ required: true, message: 'Select time range' }]}
            dependencies={['timeSlider']}
          >
            <TimePicker.RangePicker
              className="ant-picker-hhmm"
              allowClear={false}
              ranges={{
                Reset: defaultTimeRange
              }}
              format="HH:mm"
              order={false}
            />
          </Form.Item>
          <Form.Item
            shouldUpdate={(prevValues, curValues) =>
              prevValues.timeRange?.[0]?.format() !== curValues.timeRange?.[0]?.format() ||
              prevValues.timeRange?.[1]?.format() !== curValues.timeRange?.[1]?.format()
            }
            noStyle
          >
            {({ getFieldValue }) => {
              const [start, end] = getFieldValue('timeRange') || [];

              if (!start || !end) {
                return null;
              }

              const diff =
                end.diff(end.clone().startOf('day'), 'minutes') - start.diff(start.clone().startOf('day'), 'minutes');

              return (
                <span>
                  {diff} minutes / {Math.ceil(((diff + Number.EPSILON) * 100) / 60) / 100} hours
                </span>
              );
            }}
          </Form.Item>
        </Space>
      </Form.Item>
      <Form.Item>
        <Space>
          <span>Presets:</span>
          <Button onClick={setIntervalUsingPreset(100)}>100% (lunch is included)</Button>
          <Button onClick={setIntervalUsingPreset(75)}>75% (lunch is included)</Button>
          <Button onClick={setIntervalUsingPreset(50)}>50%</Button>
          <Button onClick={setIntervalUsingPreset(25)}>25%</Button>
        </Space>
      </Form.Item>
      <Form.Item name="timeSlider">
        <Slider
          max={1439}
          marks={{ 0: '00:00', 1439: '23:59' }}
          range={{ draggableTrack: true }}
          tipFormatter={formatSliderTooltip}
        />
      </Form.Item>
      <Form.Item
        name="workType"
        label="Work type"
        rules={[
          ({ getFieldValue }) => ({
            validator() {
              if (!getFieldValue('workType') && !getFieldValue('absence')) {
                return Promise.reject(new Error('Please select either work type or absence'));
              }

              return Promise.resolve();
            }
          })
        ]}
      >
        <Radio.Group options={getWorktypeOptions(timeTrackerUser)} optionType="button" buttonStyle="solid" />
      </Form.Item>
      <Form.Item shouldUpdate={(prevValues, curValues) => prevValues.workType !== curValues.workType} noStyle>
        {({ getFieldValue }) => {
          if (!RATED_WORKTYPES.includes(getFieldValue('workType'))) {
            return null;
          }

          return (
            <Form.Item
              name="approved"
              rules={[{ required: true, message: 'This field is required for paid overtime or compensation time' }]}
            >
              <Radio.Group options={APPROVED_OPTIONS} optionType="button" buttonStyle="solid" />
            </Form.Item>
          );
        }}
      </Form.Item>
      <Form.Item
        shouldUpdate={(prevValues, curValues) =>
          prevValues.absence !== curValues.absence || prevValues.workType !== curValues.workType
        }
        noStyle
      >
        {({ getFieldValue }) => {
          if (timeTrackerUser.user.country === 'NO' && getFieldValue('workType') === 'flexTime') {
            return (
              <Form.Item
                name="rate"
                label="Rate"
                rules={[
                  () => ({
                    validator() {
                      if (![0, 100].includes(getFieldValue('rate'))) {
                        return Promise.reject(new Error('Please select a valid rate option'));
                      }

                      return Promise.resolve();
                    }
                  })
                ]}
              >
                <Radio.Group options={FLEXTIME_RATE_OPTIONS_NO} optionType="button" buttonStyle="solid" />
              </Form.Item>
            );
          }

          if (!RATED_WORKTYPES.includes(getFieldValue('workType'))) {
            return null;
          }

          return (
            <Form.Item
              name="rate"
              label="Rate"
              rules={[
                () => ({
                  validator() {
                    if (![150, 200].includes(getFieldValue('rate'))) {
                      return Promise.reject(new Error('Please select a valid rate option'));
                    }

                    return Promise.resolve();
                  }
                })
              ]}
            >
              <Radio.Group
                options={timeTrackerUser.user.country === 'NO' ? RATE_OPTIONS_NO : RATE_OPTIONS}
                optionType="button"
                buttonStyle="solid"
                disabled={getFieldValue('absence') || !RATED_WORKTYPES.includes(getFieldValue('workType'))}
              />
            </Form.Item>
          );
        }}
      </Form.Item>
      <Divider dashed>or</Divider>{' '}
      <Form.Item label="Part day absence" name="absence">
        <Select options={ABSENCE_OPTIONS[timeTrackerUser.user.country]} />
      </Form.Item>
      {timeTrackerUser.user.country === 'SE' && (
        <>
          <Divider dashed>or</Divider>
          <Form.Item
            name="workType"
            rules={[
              ({ getFieldValue }) => ({
                validator() {
                  const [begin, end] = getFieldValue('timeRange');

                  if (!begin || !end) {
                    return Promise.resolve();
                  }

                  const compensationTimeMinutes = end.diff(begin, 'minutes');
                  const { compensationTimeSaldo } = userInfo?.data;

                  if (
                    getFieldValue('workType') === FAKE_WORKTYPE.USE_COMPENSATION_TIME &&
                    compensationTimeMinutes > compensationTimeSaldo
                  ) {
                    return Promise.reject(
                      new Error(
                        `You want to charge ${compensationTimeMinutes} minutes, but this employee has only ${compensationTimeSaldo} minutes of compensation time saldo`
                      )
                    );
                  }

                  return Promise.resolve();
                }
              })
            ]}
          >
            <Radio.Group
              options={isHourlyPaid ? OTHER_HOURLY_PAID_ABSENCE_OPTIONS : OTHER_ABSENCE_OPTIONS}
              optionType="button"
              buttonStyle="solid"
            />
          </Form.Item>
        </>
      )}
      <Divider plain />
      <Space>
        <Button type="primary" htmlType="submit" loading={loading} icon={id ? <SaveOutlined /> : <PlusOutlined />}>
          {id ? 'Save' : 'Add'}
        </Button>
        {id && (
          <Popconfirm
            title="Are you sure you want to delete this interval?"
            visible={deleteConfirmationVisible}
            onConfirm={deleteInterval.run}
            okButtonProps={{ loading }}
            onCancel={hideDeleteConfirmation}
          >
            <Button type="primary" loading={loading} icon={<DeleteOutlined />} danger onClick={showDeleteConfirmation}>
              Delete
            </Button>
          </Popconfirm>
        )}
        {onIntervalCancel && (
          <Button onClick={onIntervalCancel} loading={loading}>
            Close
          </Button>
        )}
      </Space>
    </Form>
  );
};

export { TimeTrackerIntervalEditForm };
