import { LockOutlined, UserOutlined, WindowsOutlined } from '@ant-design/icons';
import { useMount, useRequest } from 'ahooks';
import { Alert, Button, Col, Divider, Form, Input, Layout, Row, Space } from 'antd';
import { useContext } from 'react';
import { Link } from 'react-router-dom';

import logo from '@/assets/logo_black.svg';
import { showError } from '@/helpers/message';
import userContext from '@/helpers/userContext';
import { request } from '@/models';

const LoginPage = () => {
  const [form] = Form.useForm();
  const { login } = useContext(userContext);
  const { error, loading, run: handleLogin } = useRequest(login, {
    manual: true
  });
  const { goBack } = request.parseUrlQuery(window.location.search);

  useMount(() => {
    if (!goBack) {
      return;
    }

    showError('Logged out', { displayTime: 2 });
  });

  const handleFinish = (credentials) => handleLogin(credentials, goBack);

  return (
    <Layout>
      <Layout.Content>
        <Row className="ant-row-flex-vertical-align" style={{ top: 0, height: '300px' }}>
          <Col offset={8} span={8}>
            <Form className="login" form={form} onFinish={handleFinish}>
              <Link to="/">
                <img className="login-logo" src={logo} alt="Elcare Nordic" />
              </Link>
              <Form.Item>
                <Button
                  block
                  href={`${window._GLOBALS.backendUrl}/azure_login`}
                  type="primary"
                  icon={<WindowsOutlined />}
                  size="large"
                >
                  Cloud login for employees
                </Button>
              </Form.Item>
              <Divider>Login for others</Divider>
              {!loading && error && <Alert type="error" message={error?.message || 'Unknown auth error'} />}
              <Form.Item name="login" rules={[{ required: true, message: 'Please input your login!' }]}>
                <Input prefix={<UserOutlined />} placeholder="Login" size="large" />
              </Form.Item>
              <Form.Item name="password" rules={[{ required: true, message: 'Please input your password!' }]}>
                <Input prefix={<LockOutlined />} type="password" placeholder="Password" size="large" />
              </Form.Item>
              <Form.Item>
                <Space wrap>
                  <Button type="primary" htmlType="submit" className="login-form-button" loading={loading}>
                    Log in
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Layout.Content>
    </Layout>
  );
};

export { LoginPage };
