import { LeftOutlined, PlusOutlined, RightOutlined } from '@ant-design/icons';
import { Card, List } from 'antd';
import { Link } from 'react-router-dom';

import { DateDisplay, LinkButton } from '@/components';
import { PERMISSION, ROUTE } from '@/configs/general';
import { Loadable } from '@/elements';
import { request } from '@/models';

const NewsFeedCard = (props) => {
  const {
    canEdit,
    data,
    onChangeQueryParams,
    queryParams: { page }
  } = props;

  const goToPrevPage = () => onChangeQueryParams({ page: page - 1 });

  const goToNextPage = () => onChangeQueryParams({ page: page + 1 });

  const extra = canEdit ? (
    <LinkButton to={`${ROUTE.NEWS_PAGE}/new`} icon={<PlusOutlined />} size="small" type="primary">
      Add news
    </LinkButton>
  ) : null;

  return (
    <Card
      title="Newsfeed"
      extra={extra}
      actions={
        data && [
          page > 1 && <LeftOutlined onClick={goToPrevPage} />,
          data.pagination.hasMore && <RightOutlined onClick={goToNextPage} />
        ]
      }
    >
      <List>
        {data?.data?.map((x) => (
          <List.Item key={x._id}>
            <List.Item.Meta
              avatar={<DateDisplay date={x.date} background={x.background} />}
              description={
                <Link to={`${ROUTE.NEWS_PAGE}/${x._id}`} key={x._id}>
                  {x.title}
                </Link>
              }
            />
          </List.Item>
        ))}
      </List>
    </Card>
  );
};

export default () =>
  Loadable(NewsFeedCard, {
    queryParams: { page: 1 },
    endpoints: {
      data: ({ page }) => request.getJson('/api/v1/dashboard/newsfeed', { query: { page } })
    },
    permissionToEdit: PERMISSION.EDIT_NEWS
  });
