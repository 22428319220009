import { PageHeader, Tabs } from 'antd';
import { useContext } from 'react';

import { Block } from '@/components';
import { PERMISSION } from '@/configs/general';
import userContext from '@/helpers/userContext';

import Departments from './Departments';
import Printers from './Printers';
import Teams from './Teams';
import Users from './Users';

const { TabPane } = Tabs;

const SettingsPage = () => {
  const { hasPermission } = useContext(userContext);

  return (
    <>
      <PageHeader title="Settings" />
      <Tabs className="tabs-with-block settings-tabs" defaultActiveKey="users" type="card">
        {hasPermission(PERMISSION.EDIT_USERS) && (
          <TabPane tab="Users" key="users">
            <Block>
              <Users canEditPermissions={hasPermission(PERMISSION.AUTH_TOOL_SUPERVISOR)} />
            </Block>
          </TabPane>
        )}
        {hasPermission(PERMISSION.EDIT_DEPARTMENTS) && (
          <TabPane tab="Departments" key="departments">
            <Block>
              <Departments />
            </Block>
          </TabPane>
        )}
        {hasPermission(PERMISSION.EDIT_PRINTERS) && (
          <TabPane tab="Printers" key="printers">
            <Block>
              <Printers />
            </Block>
          </TabPane>
        )}
        {hasPermission(PERMISSION.EDIT_TEAMS) && (
          <TabPane tab="Teams" key="teams">
            <Block>
              <Teams />
            </Block>
          </TabPane>
        )}
      </Tabs>
    </>
  );
};

export { SettingsPage };
