import { debounce, zipObject } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// TODO: Refactor

export default class DebouncedInput extends React.Component {
  constructor() {
    super();
    this.debouncedOnChange = null;
  }

  state = {
    value: null
  };

  componentDidMount() {
    this.setState({ value: this.props.value });
    this.updateDebouncedHandler();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.onChange !== this.props.onChange || !this.debouncedOnChange) {
      this.updateDebouncedHandler();
    }

    if (prevProps.value !== this.props.value && this.props.value !== this.state.value) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ value: this.props.value });
    }
  }

  onChange = (e) => {
    e.persist();
    this.setState({ value: e.target.value });

    if (this.debouncedOnChange) {
      this.debouncedOnChange(e);
    }
  };

  flushDebounce() {
    console.log('flush!');

    if (this.debouncedOnChange) {
      this.debouncedOnChange.flush();
    }
  }

  updateDebouncedHandler() {
    if (this.props.onChange) {
      const fn = this.props.onChange;

      this.debouncedOnChange = debounce((...args) => {
        fn(...args);
      }, this.props.waitTime);
    } else {
      this.debouncedOnChange = null;
    }
  }

  render() {
    const { component: Component, onChange, waitTime, value, flushEvents, ...componentProps } = this.props;
    const flushProps = flushEvents
      ? zipObject(
          flushEvents,
          flushEvents.map(() => () => this.flushDebounce())
        )
      : {};

    return <Component value={this.state.value} onChange={this.onChange} {...flushProps} {...componentProps} />;
  }
}

DebouncedInput.propTypes = {
  value: PropTypes.any,
  waitTime: PropTypes.number.isRequired,
  onChange: PropTypes.func
};
