import moment from 'moment';

const CheckInInline = (props) => {
  const { asString, showSeconds, value } = props;
  const formattedValue = moment(value).format(`YYYY-MM-DD [at] HH:mm${showSeconds ? ':ss' : ''}`);

  if (asString) {
    return formattedValue;
  }

  return <>{formattedValue}</>;
};

export default CheckInInline;
