import { CheckCircleTwoTone, EditOutlined, StopTwoTone } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { Button, DatePicker, List, Space, TimePicker } from 'antd';
import moment from 'moment';
import { useContext, useState } from 'react';

import { COLOR } from '@/configs/general';
import { onTimeTrackerError } from '@/helpers/message';
import { timetracker } from '@/models';

import { RATE_LABEL, RATE_LABEL_NO, WORKTYPE_LABEL } from '../configs';
import { timeTrackerContext } from '../timeTrackerContext';

const TimeTrackerPendingRequests = (props) => {
  if (!props) {
    return null;
  }

  const { data, loading, user } = props;

  return (
    <List
      loading={loading}
      itemLayout="horizontal"
      dataSource={data}
      renderItem={(request) => (
        <List.Item>
          <Interval {...request} user={user} />
        </List.Item>
      )}
    />
  );
};

const Interval = (props) => {
  if (!props) {
    return;
  }

  const { id, rate, beginTimestamp, endTimestamp, workType, user, userId } = props;
  const { onIntervalSelect, onIntervalUpdate, onPendingRequestResolve } = useContext(timeTrackerContext);
  const [date] = useState(moment(beginTimestamp).startOf('day'));
  const [timeRange] = useState([moment(beginTimestamp), moment(endTimestamp)]);
  const { run, loading } = useRequest(timetracker.changeInterval, {
    manual: true,
    onSuccess: () => {
      onIntervalUpdate();
      onPendingRequestResolve({ userId });
    },
    onError: onTimeTrackerError
  });

  const handleSelectInterval = () => onIntervalSelect(props);

  const handleRequest = (approved) => () => {
    run({
      id,
      userId,
      approved
    });
  };

  return (
    <Space size="middle" className="timetracker-editable-interval">
      <DatePicker allowClear={false} value={date} showToday={false} style={{ pointerEvents: 'none' }} />
      <TimePicker.RangePicker
        className="ant-picker-hhmm"
        allowClear={false}
        format="HH:mm"
        order={false}
        value={timeRange}
        style={{ pointerEvents: 'none' }}
      />
      <Button style={{ pointerEvents: 'none' }}>
        {(user.country === 'NO' ? RATE_LABEL_NO : RATE_LABEL)[rate] || rate}
      </Button>
      <Button style={{ pointerEvents: 'none' }}>{WORKTYPE_LABEL[workType]}</Button>
      <Button.Group>
        <Button
          icon={<CheckCircleTwoTone twoToneColor={COLOR.GREEN} />}
          loading={loading}
          onClick={handleRequest(true)}
        >
          Approve
        </Button>
        <Button icon={<StopTwoTone twoToneColor={COLOR.RED} />} loading={loading} onClick={handleRequest(false)} danger>
          Reject
        </Button>
      </Button.Group>
      <Button icon={<EditOutlined />} ghost type="primary" onClick={handleSelectInterval}>
        Edit
      </Button>
    </Space>
  );
};

export { TimeTrackerPendingRequests };
