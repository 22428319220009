// prettier-ignore
import { CommentOutlined, DropboxOutlined, LoadingOutlined, PlayCircleOutlined, PrinterOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
// prettier-ignore
import { Affix, Alert, Button, Card, Checkbox, Col, DatePicker, Divider, Dropdown, Form, Input, InputNumber, Menu, Modal, Popover, Result, Row, Select, Spin, Timeline, Typography } from 'antd';
import { get, intersection, uniq } from 'lodash';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';

import warrantyCheck from '@/common/warrantyCheck';
import { DateInline, LinkButton, SelectStatus } from '@/components';
import { ENTITY, PRINTER_TYPE } from '@/configs/entities';
import { ROUTE } from '@/configs/general';
// prettier-ignore
import { ACTION, ACTION_LABEL, ACTIVITY_STATUS, FIELD, FIELD_SETTINGS, LOAD, LOGISTIC_FIELD, OTHER } from '@/configs/inbound';
import { LAYOUT, LAYOUT_ONLY_WRAPPER } from '@/configs/layout';
import { MODAL } from '@/configs/modal';
import { SHIPMENTS_TYPE } from '@/configs/shipments';
import { ModalManager } from '@/containers';
import { ApiWrapper, DebouncedInput, SelectAutocomplete } from '@/elements';
import defer from '@/helpers/defer';
import { useGoTo } from '@/helpers/hooks';
import { showError } from '@/helpers/message';
import userContext from '@/helpers/userContext';
import { entities, inbound, request } from '@/models';

import ServiceComments from './ServiceComments';

const CODES_NOT_BE_FORWARDED = ['i999', 'wgf2', 'c1bk', 'whe6', 'whe2', 'cgf1', 'cgf2', 'cod2'];
const CODES_TO_SERVICE_TYPES = {
  r999: [5893, 5723, 5724],
  w999: [5297, 5258, 5465],
  c999: [5298, 5259, 5466],
  i999: [5299, 5260, 5467],
  '500': [5300, 5261],
  '5999': [5300, 5261]
};

const getSuggestedAction = ({ logisticStatus }, canForwardActivities) => {
  const status = (logisticStatus || '').toLowerCase();

  if (status === 'ass') {
    return ACTION.ARRIVE;
  }

  if (status === 'wext' && canForwardActivities) {
    return ACTION.FORWARD;
  }

  if (status === 'sentno' || status === 'sentse') {
    return ACTION.EXPORT;
  }

  if (status === 'ret') {
    return ACTION.RETURN;
  }

  return ACTION.SET_STATUS;
};

const CustomerConsumerRow = ({ label, customerData, consumerData }) => (
  <Col span={23} className="customer-consumer-col">
    <Row gutter={8}>
      <Col span={4} className="ant-form-item-label">
        <label>{label}</label>
      </Col>
      <Col span={10} className="break-overflowed-words">
        <span>{customerData}</span>
      </Col>
      <Col span={10} className="break-overflowed-words">
        <span>{consumerData}</span>
      </Col>
    </Row>
  </Col>
);

const TeamSelect = (props) => {
  const { data, loading } = useRequest(
    () =>
      entities.getEntityValues(ENTITY.TEAM, {
        order: ['code']
      }),
    {
      formatResult: (result) => ({
        ...result,
        options: result?.data?.map(({ code, name }) => ({ label: `${code} (${name})`, value: code }))
      }),
      initialData: null
    }
  );

  return <Select {...props} loading={loading} options={data?.options || []} />;
};

const InboundActivityPage = (props) => {
  const {
    data: activityData,
    isLoading: isOuterLoading,
    manufacturers,
    onTriggerClear,
    onTriggerLoad,
    productTypes,
    routing,
    serviceTypes,
    shipmentsContainingActivity
  } = props;

  const { user } = useContext(userContext);
  const { goBack } = request.parseUrlQuery(window.location.search);
  const goToShipment = useGoTo(goBack);
  const { canForwardActivities, canPrintLabels, printers } = user || {};

  const [isInnerLoading, setIsInnerLoading] = useState(false);
  const [productTypeRequiredFields, setProductTypeRequiredFields] = useState({});
  const [currentModal, setModal] = useState({});
  const [competenceData, setCompetenceData] = useState(null);
  const [formInitialValues, setFormInitialValues] = useState(null);
  const [mappedOptions, setMappedOptions] = useState({});

  const [form] = Form.useForm();
  const formValues = form.getFieldsValue();
  const isLoading = isOuterLoading || isInnerLoading;
  const suggestedAction = getSuggestedAction(formValues, canForwardActivities);

  const loadActivityData = () => {
    if (user) {
      onTriggerLoad(LOAD.MANUFACTURERS);
      onTriggerLoad(LOAD.STATUSES_LIST);
    }
  };

  const executeAction = async (action, extraParams = {}) => {
    const { labelsCount, ...activityValues } = form.getFieldsValue();

    setIsInnerLoading(true);

    if (moment.isMoment(activityValues?.[FIELD.SALES_DATE])) {
      activityValues[FIELD.SALES_DATE] = activityValues[FIELD.SALES_DATE].format();
    }

    try {
      const saved = await inbound.doActionWithActivity(action, activityData.id, activityValues, {
        serviceProviderName: get(routing, 'serviceProvider.name'),
        serviceProviderId: get(routing, 'serviceProvider.id'),
        departmentId: get(routing, 'serviceProviderCompetence.departmentId'), // Optional
        initialLogisticStatus: activityData.logisticStatus,
        ...extraParams,
        labelsCount
      });

      if (saved.status !== ACTIVITY_STATUS.PROCESSED) {
        loadActivityData();
      }
    } catch (e) {
      showError(e.message);
    }

    setIsInnerLoading(false);
  };

  const submitWithSelectedAction = (event) => {
    form.setFieldsValue({ action: event.key || suggestedAction });
    form.submit();
  };

  const toggleModal = (modal = null, modalProps) => {
    setModal(
      currentModal.key === modal
        ? {}
        : {
            id: modal,
            ...modalProps
          }
    );
  };

  const handleToggleModal = (modal = null, modalProps) => () => {
    toggleModal(modal, modalProps);
  };

  const onFinish = async (values) => {
    const { action } = values;

    if (!action) {
      throw new Error('Unknown action');
    }

    const canForwardByCode = defer();

    if (
      action === ACTION.FORWARD &&
      (CODES_NOT_BE_FORWARDED.includes(activityData.logisticServiceCode.toLowerCase()) || activityData.spec !== '')
    ) {
      Modal.confirm({
        title: 'Warning',
        content: (
          <div>
            This activity looks like an INSURANCE repair which should NOT be forwarded to external workshops.
            <br />
            <br />
            By proceeding you take personal responsibility for the consequences. If you’re sure that it’s a valid repair
            for forwarding consider to change the service code to the correct one (which is not black-listed)
          </div>
        ),
        okText: 'Forward now',
        cancelText: "No, don't forward",
        centered: true,
        onOk: () => {
          canForwardByCode.resolve();
        }
      });
    } else {
      canForwardByCode.resolve();
    }

    await canForwardByCode;

    const activityAlreadyForwarded = defer();

    if (
      action === ACTION.FORWARD &&
      shipmentsContainingActivity.data?.length > 0 &&
      activityData.logisticStatus.toLowerCase() === 'wext'
    ) {
      const providers = uniq(shipmentsContainingActivity.data.map(({ customData }) => customData?.serviceProviderName));

      Modal.confirm({
        title: 'Warning',
        content: (
          <div>
            This activity have been already forwarded to following providers: <strong>{providers.join(', ')}</strong>
            <br />
            <br />
            Check the product and IMEI in case if the store sent wrong paper and decide if you want to forward the
            product to <strong>{routing?.serviceProvider?.name}</strong>?
          </div>
        ),
        okText: 'Forward now',
        cancelText: "No, don't forward",
        centered: true,
        onOk: () => {
          activityAlreadyForwarded.resolve();
        }
      });
    } else {
      activityAlreadyForwarded.resolve();
    }

    await activityAlreadyForwarded;

    const warrantyConfirmed = defer();
    const warrantyMessage = warrantyCheck({ ...activityData, ...values });

    if (warrantyMessage !== true) {
      const serviceType = serviceTypes.filter((type) => type.id === Number(values[FIELD.SERVICE_TYPE]))?.[0];

      Modal.confirm({
        title: 'Warning',
        content: (
          <div>
            {warrantyMessage}
            <br />
            <br />
            Do you want to keep <strong>{serviceType?.name || values[FIELD.SERVICE_TYPE]}</strong>?
          </div>
        ),
        okText: 'Yes',
        cancelText: 'No',
        centered: true,
        onOk: () => {
          warrantyConfirmed.resolve();
        }
      });
    } else {
      warrantyConfirmed.resolve();
    }

    await warrantyConfirmed;

    const actionConfirmed = defer();

    if (action !== suggestedAction) {
      Modal.confirm({
        title: 'Warning',
        content: `It seems that you're going to perform non-suggested action. Proceed anyway?`,
        okText: 'Yes',
        cancelText: 'No',
        centered: true,
        onOk: () => {
          actionConfirmed.resolve();
        }
      });
    } else {
      actionConfirmed.resolve();
    }

    await actionConfirmed;

    if (action !== ACTION.EXPORT && !values[FIELD.TEAM]) {
      Modal.warning({
        title: 'Unable to process',
        content: 'Please fill "Group" field',
        centered: true
      });

      return;
    }

    let extraParams;

    if (action === ACTION.EXPORT) {
      const paramsFromModal = defer();

      toggleModal(MODAL.EXPORT, {
        data: values,
        onOk: (modalValues) => {
          paramsFromModal.resolve(modalValues);
        },
        onClose: () => {
          toggleModal(null);
        }
      });

      extraParams = await paramsFromModal;
    }

    if (action === ACTION.RETURN || action === ACTION.FORWARD) {
      const paramsFromModal = defer();

      toggleModal(MODAL.NEW_SHIPMENT, {
        data: { ...activityData, ...values },
        logisticAction: action,
        routingData: routing,
        onOk: (modalValues) => {
          paramsFromModal.resolve(modalValues);
        },
        onClose: () => {
          toggleModal(null);
        }
      });

      extraParams = await paramsFromModal;
    }

    await executeAction(action, extraParams);

    if (goBack) {
      goToShipment();

      return;
    }

    props.onRefresh();
  };

  const onFinishFailed = ({ errorFields }) => {
    form.scrollToField(errorFields[0].name, { behavior: 'smooth', block: 'center' });
  };

  const printLabel = async () => {
    const labelsCount = form.getFieldValue(FIELD.LABELS_COUNT);

    if (Number(labelsCount) <= 0) {
      Modal.warning({
        title: 'Unable to process',
        content: 'Please select at least 1 label'
      });

      return;
    }

    setIsInnerLoading(true);

    try {
      await inbound.printLabel(activityData.id, labelsCount);
    } catch (e) {
      if (e.message === 'Incorrect printer settings') {
        Modal.warning({
          title: 'Unable to process',
          content: 'Please configure printer settings'
        });
      } else {
        showError(e.message);
      }
    }

    setIsInnerLoading(false);
  };

  const markAsMissed = async (logisticNote) => {
    try {
      await inbound.setStatus(activityData.shipmentId, {
        itemId: activityData.shipmentItemId,
        status: 'Missing',
        options: { logisticNote }
      });
      loadActivityData();
    } catch (e) {
      showError(e.message);
    }
  };

  const selectLogisticInput = (fieldId, options, disabled) => {
    if (fieldId === FIELD.TEAM) {
      return <TeamSelect disabled={disabled} />;
    }

    const { statusesList } = props;

    if (fieldId === FIELD.STATUS && statusesList) {
      return (
        <SelectStatus
          initialValue={activityData.logisticStatus}
          options={options || []}
          possibleOptions={statusesList}
          disabled={disabled}
        />
      );
    }

    if (Array.isArray(options)) {
      return <Select options={options} disabled={disabled} />;
    }

    return <Input disabled={disabled} />;
  };

  const finalizeAdditionalShipment = async () => {
    const {
      additionalShipment: { guid }
    } = props;

    setIsInnerLoading(true);

    await inbound.finalizeShipment(guid);

    setIsInnerLoading(false);
    onTriggerLoad(LOAD.ADDITIONAL_SHIPMENT, { guid: activityData.returnShipmentId });
  };

  // Returns a key-value object from the form or activityData object
  const getKeyValue = (key) => ({
    [key]: form.getFieldValue(key) || activityData?.[key]
  });

  // Set require state for IMEI and Serial fields based on a product type and clean errors if a field no longer required
  const setRequiredFieldsOfProductType = (productType) => {
    const isImeiRequired = Boolean(productType?.properties?.requireImei);
    const isSerialRequired = Boolean(productType?.properties?.requireSerial);

    if (!isImeiRequired) {
      form.setFields([
        {
          name: [FIELD.IMEI],
          errors: []
        }
      ]);
    }

    if (!isSerialRequired) {
      form.setFields([
        {
          name: [FIELD.SERIAL],
          errors: []
        }
      ]);
    }

    setProductTypeRequiredFields({
      [FIELD.IMEI]: isImeiRequired,
      [FIELD.SERIAL]: isSerialRequired
    });
  };

  const loadServiceTypes = async () => {
    await onTriggerLoad(LOAD.SERVICE_TYPES, getKeyValue(FIELD.MANUFACTURER), true);
  };

  const loadProductTypes = async () => {
    await onTriggerLoad(
      LOAD.PRODUCT_TYPES,
      {
        ...getKeyValue(FIELD.MANUFACTURER),
        ...getKeyValue(FIELD.SERVICE_TYPE)
      },
      true
    );
  };

  const loadRoutes = async () => {
    setCompetenceData(null);
    const { customerPostalCode: clientPostalCode } = props.data;

    await onTriggerLoad(
      LOAD.ROUTING,
      {
        ...getKeyValue(FIELD.MANUFACTURER),
        ...getKeyValue(FIELD.SERVICE_TYPE),
        ...getKeyValue(FIELD.PRODUCT_TYPE),
        ...(clientPostalCode ? { clientPostalCode } : {})
      },
      true
    );
  };

  const loadModel = async () => {
    await onTriggerLoad(
      LOAD.MATCHED_MODEL,
      {
        ...getKeyValue(FIELD.MANUFACTURER),
        ...getKeyValue(FIELD.MODEL)
      },
      true
    );
  };

  const setServiceType = () => {
    const possibleServiceTypeToPreSelect =
      CODES_TO_SERVICE_TYPES[String(activityData?.logisticServiceCode || '').toLowerCase()];

    if (!possibleServiceTypeToPreSelect) {
      return;
    }

    const serviceCodesToPreSelect = intersection(
      possibleServiceTypeToPreSelect,
      serviceTypes.map(({ id }) => id)
    );

    if (!serviceCodesToPreSelect.length) {
      return;
    }

    form.setFieldsValue({ [FIELD.SERVICE_TYPE]: String(serviceCodesToPreSelect[0]) });

    return true;
  };

  const setProductType = (type) => {
    const currentProductType = type ?? form.getFieldValue(FIELD.PRODUCT_TYPE);

    if (currentProductType === OTHER.ID) {
      setRequiredFieldsOfProductType();

      return;
    }

    const productTypeDetails = productTypes.find((x) => x.name.toLowerCase() === currentProductType.toLowerCase());
    let value = productTypeDetails ? currentProductType : OTHER.ID;

    if (productTypes.length === 1 && currentProductType !== productTypes[0].name) {
      value = productTypes[0].name;
      setRequiredFieldsOfProductType(productTypes[0]);
    } else if (!productTypeDetails && currentProductType) {
      value = null;
    } else {
      setRequiredFieldsOfProductType(productTypeDetails);
    }

    form.setFieldsValue({ [FIELD.PRODUCT_TYPE]: value });
  };

  const setLogisticFields = () => {
    if (!routing) {
      return;
    }

    const newCompetenceData = {};
    const logisticData = {};

    for (const fieldId of LOGISTIC_FIELD) {
      const cmpVal = routing.outboundCompetence[fieldId];
      newCompetenceData[fieldId] = cmpVal;

      if (Array.isArray(cmpVal)) {
        // Skip mapping statuses, because SelectStatus component make it under the hood
        if (fieldId !== FIELD.STATUS) {
          newCompetenceData[fieldId] = newCompetenceData[fieldId].map((x) => ({ value: x }));
        }

        logisticData[fieldId] = cmpVal.length === 1 ? cmpVal[0] : formValues[fieldId];

        if (fieldId === FIELD.SERVICE_CODE && cmpVal.indexOf(logisticData[fieldId]) === -1) {
          logisticData[fieldId] = null;
        }
      } else {
        logisticData[fieldId] = cmpVal ?? formValues[fieldId];
      }
    }

    logisticData.action = getSuggestedAction(logisticData);

    form.setFieldsValue(logisticData);
    setCompetenceData(newCompetenceData);
  };

  const onFormValuesUpdate = async (changedValues, newValues) => {
    if (changedValues[FIELD.MANUFACTURER]) {
      await loadServiceTypes();
      await loadProductTypes();
    }

    if (changedValues[FIELD.SERVICE_TYPE]) {
      await loadProductTypes();
    }

    if (changedValues[FIELD.PRODUCT_TYPE]) {
      setProductType();
      await loadRoutes();
    }

    if (changedValues?.[FIELD.MODEL]?.length >= 3) {
      await loadModel();
    }

    if (changedValues[FIELD.NO_ACCESSORIES] && newValues[FIELD.UNKNOWN_ACCESSORIES]) {
      form.setFieldsValue({ [FIELD.UNKNOWN_ACCESSORIES]: false });
    }

    if (changedValues[FIELD.UNKNOWN_ACCESSORIES] && newValues[FIELD.NO_ACCESSORIES]) {
      form.setFieldsValue({ [FIELD.NO_ACCESSORIES]: false });
    }
  };

  // Reset data and load activity data on id had changed
  useEffect(() => {
    setProductTypeRequiredFields({});
    setCompetenceData(null);
    setFormInitialValues(null);
    onTriggerClear(LOAD.ROUTING);
    onTriggerClear(LOAD.PRODUCT_TYPES);

    loadActivityData();
  }, [activityData.id]);

  // Set initial form values and load data based on activity when activity data had loaded
  useEffect(() => {
    if (!activityData) {
      return;
    }

    const initialValues = {};

    for (const key of Object.values(FIELD)) {
      initialValues[key] = activityData?.[key] ?? null;
    }

    if (initialValues[FIELD.SALES_DATE]) {
      initialValues[FIELD.SALES_DATE] = moment(initialValues[FIELD.SALES_DATE]);
    }

    if (!initialValues[FIELD.PRODUCT_TYPE]) {
      initialValues[FIELD.PRODUCT_TYPE] = OTHER.ID;
    }

    initialValues[FIELD.LABELS_COUNT] = canPrintLabels
      ? printers.find(({ settings }) => settings.type === PRINTER_TYPE.SMALL_LABEL).settings.quantity
      : 0;

    if (activityData.returnShipmentId) {
      onTriggerLoad(LOAD.ADDITIONAL_SHIPMENT, { guid: activityData.returnShipmentId });
    }

    form.setFieldsValue(initialValues);

    if (user) {
      console.log('Load service and product types');
      loadServiceTypes();
      loadProductTypes();
    }

    setFormInitialValues(initialValues);

    onTriggerLoad(
      LOAD.SHIPMENTS_CONTAINING_ACTIVITY,
      {
        search: activityData.activityNumber
      },
      true
    );
  }, [activityData]);

  // Pre-select some service types depending on a service code
  useEffect(() => {
    if (!serviceTypes?.length) {
      return;
    }

    const preSelectedType = setServiceType();

    if (!preSelectedType) {
      return;
    }

    loadProductTypes();
  }, [serviceTypes]);

  // Set product type dropdown and load routes when product types had changed
  useEffect(() => {
    if (!productTypes?.length) {
      return;
    }

    setProductType();
    loadRoutes();
  }, [productTypes]);

  // Set product type dropdown and load routes based on the matched model
  useEffect(() => {
    const matchedModelName = props?.matchedModel?.productType?.name;

    if (matchedModelName && form.getFieldValue(FIELD.PRODUCT_TYPE) !== matchedModelName) {
      setProductType(matchedModelName);
      loadRoutes();
    }
  }, [props.matchedModel]);

  // Set logistic fields based when routes had changed
  useEffect(setLogisticFields, [routing]);

  useEffect(() => {
    setMappedOptions({
      [FIELD.MANUFACTURER]: manufacturers.map((x) => ({ value: x.name })),
      [FIELD.SERVICE_TYPE]: serviceTypes.map((x) => ({ label: x.name, value: String(x.id) })),
      [FIELD.PRODUCT_TYPE]: [...productTypes.map((x) => ({ value: x.name })), { label: OTHER.LABEL, value: OTHER.ID }]
    });
  }, [manufacturers, serviceTypes, productTypes]);

  const { additionalShipment } = props;

  if (!activityData || (user && !formInitialValues)) {
    return <Result icon={<LoadingOutlined />} title="Loading activity data" />;
  }

  const logisticNotes = [get(routing, 'serviceProviderCompetence.logisticNote'), activityData.logisticNote].filter(
    Boolean
  );
  const notes = [
    ...logisticNotes.map((x) => (
      <div className="note" key={`logistic_note_${x}`}>
        {x}
      </div>
    ))
  ].filter(Boolean);
  const canContinueAction = Boolean(routing?.serviceProviderCompetence);
  const activityEvents = Object.entries(activityData?.events || {});
  const availableActions = [];

  for (const action of Object.values(ACTION)) {
    if (!canForwardActivities && action === ACTION.FORWARD) {
      continue;
    }

    availableActions.push(action);
  }

  return (
    <>
      <Spin spinning={isInnerLoading}>
        <Row gutter={16}>
          <ModalManager {...currentModal} onClose={handleToggleModal(null)} />
          {!canForwardActivities && (
            <Col span={24}>
              <Alert
                message="You can't forward activities"
                description={
                  <>
                    The department settings is incorrect. Ask your supervisor to fix them, or do it yourself on{' '}
                    <LinkButton to={ROUTE.MY_PAGE}>My page</LinkButton>
                  </>
                }
                type="error"
              />
            </Col>
          )}
          <Col span={18}>
            {activityEvents.length > 0 && (
              <Popover
                trigger="click"
                placement="bottomLeft"
                title="Results"
                content={
                  <Timeline>
                    {Object.entries(activityData.events).map(([k, v]) => (
                      // eslint-disable-next-line no-nested-ternary
                      <Timeline.Item key={k} color={v === 'ok' ? 'green' : v === 'skip' ? 'orange' : 'red'}>
                        <strong>{k}:</strong> {v}
                      </Timeline.Item>
                    ))}
                  </Timeline>
                }
              >
                <Alert
                  type={activityData.status === ACTIVITY_STATUS.PROCESSED ? 'success' : 'error'}
                  message={
                    activityData.status === ACTIVITY_STATUS.FAILED
                      ? 'Some actions failed. Please click on this message to see details'
                      : 'The item was processed successfully'
                  }
                />
              </Popover>
            )}
            <Form
              form={form}
              initialValues={formInitialValues}
              layout="horizontal"
              name="item-details"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              onValuesChange={onFormValuesUpdate}
            >
              <Row>
                <Col span={16}>
                  <Row>
                    <Col span={16}>
                      <Form.Item {...FIELD_SETTINGS[FIELD.MANUFACTURER]} {...LAYOUT.L8_W15} trigger="onSelect">
                        <SelectAutocomplete
                          options={mappedOptions[FIELD.MANUFACTURER]}
                          skipMapping
                          disabled={isOuterLoading}
                        />
                      </Form.Item>
                      <Form.Item {...FIELD_SETTINGS[FIELD.SERVICE_TYPE]} {...LAYOUT.L8_W15}>
                        <Select options={mappedOptions[FIELD.SERVICE_TYPE]} disabled={isOuterLoading} />
                      </Form.Item>
                      <Form.Item {...FIELD_SETTINGS[FIELD.PRODUCT_TYPE]} {...LAYOUT.L8_W15}>
                        <Select options={mappedOptions[FIELD.PRODUCT_TYPE]} disabled={isOuterLoading} />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item label="Activity number" {...LAYOUT.L14_W9}>
                        <span>
                          <strong>{activityData.activityNumber}</strong>
                        </span>
                      </Form.Item>
                      <Form.Item label="Security code" {...LAYOUT.L14_W9}>
                        <span>
                          <strong>{activityData.securityCode || '–'}</strong>
                        </span>
                      </Form.Item>
                      <Form.Item label="Originator" {...LAYOUT.L14_W9}>
                        <span>
                          <strong>{activityData.originator || '–'}</strong>
                        </span>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <Form.Item label="Customer number" {...LAYOUT.L11_W12}>
                        <span>{activityData.customerNumber || '–'}</span>
                      </Form.Item>
                      <Form.Item label="Ref No" {...LAYOUT.L11_W12}>
                        <span>{activityData.refNo || '–'}</span>
                      </Form.Item>
                      <Form.Item label="Comment" {...LAYOUT.L11_W12}>
                        <span>{activityData.comment || '–'}</span>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item {...FIELD_SETTINGS[FIELD.GUAR_REF]} {...LAYOUT.L6_W17}>
                        <Input disabled={isOuterLoading} />
                      </Form.Item>
                      <Form.Item label="Spec" {...LAYOUT.L6_W17}>
                        <span>{activityData.spec || '–'}</span>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={23}>
                      <Row gutter={[8, 8]}>
                        <Col span={10} offset={4}>
                          <Typography.Title level={4}>Customer</Typography.Title>
                        </Col>
                        <Col span={10}>
                          <Typography.Title level={4}>Consumer</Typography.Title>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <CustomerConsumerRow
                    label="Name"
                    customerData={activityData.customerName || '-'}
                    consumerData={activityData.consumerName || '-'}
                  />
                  <CustomerConsumerRow label="AddName" customerData={activityData.customerAddName || '-'} />
                  <CustomerConsumerRow
                    label="Address"
                    customerData={activityData.customerAddress || '-'}
                    consumerData={activityData.consumerAddress || '-'}
                  />
                  <CustomerConsumerRow
                    label="Phone"
                    customerData={activityData.customerPhone || '-'}
                    consumerData={activityData.consumerPhone || '-'}
                  />
                  <CustomerConsumerRow
                    label="Mobile"
                    customerData={activityData.customerMobile || '-'}
                    consumerData={activityData.consumerMobile || '-'}
                  />
                  <CustomerConsumerRow
                    label="Email"
                    customerData={activityData.customerEmail || '-'}
                    consumerData={activityData.consumerEmail || '-'}
                  />
                </Col>
                <Col span={8}>
                  {LOGISTIC_FIELD.map((fieldId) => (
                    <Form.Item key={fieldId} {...LAYOUT.L9_W15} {...FIELD_SETTINGS[fieldId]}>
                      {selectLogisticInput(fieldId, competenceData?.[fieldId], isLoading)}
                    </Form.Item>
                  ))}
                </Col>
                <Divider />
              </Row>
              <Row>
                <Col span={12}>
                  <Form.Item
                    {...LAYOUT.L8_W15}
                    {...FIELD_SETTINGS[FIELD.SERIAL]}
                    rules={productTypeRequiredFields[FIELD.SERIAL] ? FIELD_SETTINGS[FIELD.SERIAL].rules : []}
                  >
                    <Input disabled={isOuterLoading} />
                  </Form.Item>
                  <Form.Item {...LAYOUT.L8_W15} {...FIELD_SETTINGS[FIELD.MODEL]}>
                    <DebouncedInput component={Input} waitTime={1000} disabled={isOuterLoading} />
                  </Form.Item>
                  <Form.Item {...LAYOUT.L8_W15} {...FIELD_SETTINGS[FIELD.PRODUCT_DESCRIPTION]}>
                    <Input.TextArea autosize="true" maxLength="50" disabled={isOuterLoading} />
                  </Form.Item>
                  {(activityData.imei || productTypeRequiredFields[FIELD.IMEI]) && (
                    <Form.Item
                      {...LAYOUT.L8_W15}
                      {...FIELD_SETTINGS[FIELD.IMEI]}
                      rules={productTypeRequiredFields[FIELD.IMEI] ? FIELD_SETTINGS[FIELD.IMEI].rules : []}
                    >
                      <Input disabled={isOuterLoading} />
                    </Form.Item>
                  )}
                  <Form.Item {...LAYOUT.L8_W15} {...FIELD_SETTINGS[FIELD.PRODUCT_LOCATION]}>
                    <Input disabled={isOuterLoading} />
                  </Form.Item>
                  <Form.Item
                    className="checkbox-group"
                    {...LAYOUT_ONLY_WRAPPER.W15_O8}
                    {...FIELD_SETTINGS[FIELD.NO_ACCESSORIES]}
                  >
                    <Checkbox disabled={isOuterLoading}>No accessories</Checkbox>
                  </Form.Item>
                  <Form.Item
                    className="checkbox-group"
                    {...LAYOUT_ONLY_WRAPPER.W15_O8}
                    {...FIELD_SETTINGS[FIELD.UNKNOWN_ACCESSORIES]}
                  >
                    <Checkbox disabled={isOuterLoading}>Accessories unknown</Checkbox>
                  </Form.Item>
                  <Form.Item
                    className="reset-input-minheight"
                    shouldUpdate={(prevValues, currentValues) =>
                      prevValues[FIELD.NO_ACCESSORIES] !== currentValues[FIELD.NO_ACCESSORIES] ||
                      prevValues[FIELD.UNKNOWN_ACCESSORIES] !== currentValues[FIELD.UNKNOWN_ACCESSORIES]
                    }
                  >
                    {() => {
                      if (!form.getFieldValue(FIELD.NO_ACCESSORIES) && !form.getFieldValue(FIELD.UNKNOWN_ACCESSORIES)) {
                        return (
                          <Form.Item {...LAYOUT.L8_W15} {...FIELD_SETTINGS[FIELD.ACCESSORIES]}>
                            <Input.TextArea autosize="true" maxLength="50" disabled={isOuterLoading} />
                          </Form.Item>
                        );
                      }

                      return null;
                    }}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Problem" {...LAYOUT.L6_W18}>
                    <span>{activityData.problem || '–'}</span>
                  </Form.Item>
                  <Form.Item {...LAYOUT.L6_W18} {...FIELD_SETTINGS[FIELD.SOLUTION]}>
                    <Input.TextArea autosize="true" maxLength="50" disabled={isOuterLoading} />
                  </Form.Item>
                  <Form.Item {...LAYOUT.L6_W18} {...FIELD_SETTINGS[FIELD.SALES_DATE]}>
                    <DatePicker disabled={isOuterLoading} />
                  </Form.Item>
                  <Form.Item {...LAYOUT.L6_W18} {...FIELD_SETTINGS[FIELD.PACKAGE_MATERIALS]}>
                    <Input disabled={isOuterLoading} />
                  </Form.Item>
                  <Form.Item label="RWCode" {...LAYOUT.L6_W18}>
                    <span>{activityData.rWCode || '–'}</span>
                  </Form.Item>
                  {canPrintLabels && (
                    <Form.Item {...LAYOUT.L6_W18} {...FIELD_SETTINGS[FIELD.LABELS_COUNT]}>
                      <InputNumber min={1} max={99} disabled={isOuterLoading} />
                    </Form.Item>
                  )}
                  <Form.Item name="action" hidden>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
          <Col span={6} className="vertical-left-divider">
            {notes?.length > 0 && <Alert type="warning" message="Note" description={<>{notes}</>} />}
            {additionalShipment && (
              <Alert
                type="info"
                message="Part of external shipment"
                description={
                  <>
                    {!additionalShipment.finalized && <Button onClick={finalizeAdditionalShipment}>Finalize</Button>}
                    {additionalShipment.finalized && (
                      <>
                        <a
                          href={`https://senderella.io/v1/shipment/${additionalShipment.guid}/label`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Label
                        </a>
                        <Divider type="vertical" />
                        <a
                          href={`https://senderella.io/v1/shipment/${additionalShipment.guid}/picking-list`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Picking list
                        </a>
                      </>
                    )}
                  </>
                }
              />
            )}
            <Form.Item label="ActType" {...LAYOUT.L9_W15}>
              <span>{activityData.actType}</span>
            </Form.Item>
            <Form.Item label="Date Booked" {...LAYOUT.L9_W15}>
              <span>
                <DateInline value={activityData.dateBooked} />
              </span>
            </Form.Item>
            <Form.Item label="Date Received" {...LAYOUT.L9_W15}>
              <span>
                <DateInline value={activityData.dateReceived} />
              </span>
            </Form.Item>
            <Form.Item label="Date Started" {...LAYOUT.L9_W15}>
              <span>
                <DateInline value={activityData.dateStarted} />
              </span>
            </Form.Item>
            <Form.Item label="Date Closed" {...LAYOUT.L9_W15}>
              <span>
                <DateInline value={activityData.dateClosed} />
              </span>
            </Form.Item>
            <Form.Item label="Date Returned" {...LAYOUT.L9_W15}>
              <span>
                <DateInline value={activityData.dateReturned} />
              </span>
            </Form.Item>
            <ServiceComments data={activityData} />
          </Col>
        </Row>
      </Spin>
      <Affix offsetBottom={0} className="activity-actions-panel">
        <Card className="ant-card-full-width ant-card-gutter">
          {activityData.shipmentId && (
            <LinkButton to={`${ROUTE.INBOUND_SHIPMENT}/${activityData.shipmentId}`} type="button">
              <DropboxOutlined />
              Shipment info
            </LinkButton>
          )}
          {goBack && (
            <Button onClick={goToShipment} disabled={isLoading}>
              <DropboxOutlined /> Shipment info
            </Button>
          )}
          {activityData.shipmentId && (
            <Button onClick={handleToggleModal(MODAL.MISSED_ITEM, { onConfirm: markAsMissed })} disabled={isLoading}>
              <QuestionCircleOutlined /> Mark as missed
            </Button>
          )}
          <Dropdown.Button
            type="primary"
            onClick={submitWithSelectedAction}
            placement="topCenter"
            disabled={isLoading || !canContinueAction}
            overlay={
              <Menu onClick={submitWithSelectedAction}>
                {availableActions.map((action) => (
                  <Menu.Item key={action}>{ACTION_LABEL[action]}</Menu.Item>
                ))}
              </Menu>
            }
          >
            <PlayCircleOutlined /> {ACTION_LABEL[suggestedAction]}
          </Dropdown.Button>
          <Button onClick={printLabel} disabled={isLoading || !canPrintLabels}>
            <PrinterOutlined /> Print label
          </Button>
          <Button onClick={handleToggleModal(MODAL.ADD_SERVICE_COMMENT, { data: activityData })} disabled={isLoading}>
            <CommentOutlined /> Add service comment
          </Button>
        </Card>
      </Affix>
    </>
  );
};

const AsyncActivity = (props) =>
  ApiWrapper(InboundActivityPage, {
    requests: {
      [LOAD.ADDITIONAL_SHIPMENT]: {
        request: ({ guid }) => request.getJsonData(`/api/v1/shipment/${guid}`),
        defaultValue: null
      },
      [LOAD.STATUSES_LIST]: {
        request: () => request.getJsonData('/api/v1/activity/possible_statuses'),
        cache: true,
        defaultValue: []
      },
      [LOAD.MANUFACTURERS]: {
        request: () => request.getJsonData('/api/v1/inbound/manufacturers'),
        cache: true,
        defaultValue: []
      },
      [LOAD.SERVICE_TYPES]: {
        request: ({ manufacturer }) =>
          request.getJsonData('/api/v1/inbound/service_types', { query: { manufacturer } }),
        cache: true,
        defaultValue: []
      },
      [LOAD.PRODUCT_TYPES]: {
        request: ({ manufacturer, serviceType }) =>
          request.getJsonData('/api/v1/inbound/product_types', { query: { manufacturer, serviceType } }),
        cache: true,
        defaultValue: []
      },
      [LOAD.ROUTING]: {
        request: ({ manufacturer, serviceType, productType, clientPostalCode }) =>
          request.getJsonData('/api/v1/inbound/routing', {
            query: { manufacturer, productType, serviceType, clientPostalCode }
          }),
        cache: true
      },
      [LOAD.MATCHED_MODEL]: {
        request: ({ manufacturer, model }) =>
          request.getJsonData('/api/v1/inbound/model', { query: { manufacturer, model } }),
        cache: true
      },
      [LOAD.SHIPMENTS_CONTAINING_ACTIVITY]: {
        request: ({ search = '' }) => {
          const query = { search };

          return request.getJson(`/api/v1/shipment/${SHIPMENTS_TYPE.FORWARDED}`, {
            query
          });
        },
        defaultValue: { data: [] }
      }
    },
    clearOnReload: true,
    withSpinner: false,
    ...props
  });

export default AsyncActivity;
