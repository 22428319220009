import request from './request';

const AuthToolModel = {
  confirmUserPermission: async (userId) => await request.postJson(`/auth/confirm-user-permissions/${userId}`),

  getExternalPermissions: async (userId) => await request.getJsonData(`/auth/external-permissions/${userId}`),

  deletePermission: async (userId, permissionId) =>
    await request.deleteJson('/auth/user-permission', {
      body: { permissionId, userId }
    }),

  deleteRole: async (userId, roleId) =>
    await request.deleteJson('/auth/user-role', {
      body: { roleId, userId }
    }),

  setPermission: async (userId, permissionId, comment) =>
    await request.postJson('/auth/user-permission', {
      body: { comment, permissionId, userId }
    }),

  setRole: async (userId, roleId, comment) =>
    await request.postJson('/auth/user-role', {
      body: { comment, roleId, userId }
    })
};

export default AuthToolModel;
