import { FIELD } from '@/configs/inbound';

const ACTIVITY_FIELDS = {
  [FIELD.SESAM_DB]: 'Sesam31',
  [FIELD.CUSTOMER_NUMBER]: '123456',
  [FIELD.PROD_ID]: 'test',
  [FIELD.PROD_DESC]: 'test',
  [FIELD.SERIAL]: '4234234432',
  [FIELD.IMEI]: '',
  [FIELD.REF_NO]: 'test1',
  [FIELD.INSURANCE_COMPANY]: '',
  [FIELD.INSURANCE_NUMBER]: '',
  [FIELD.PROBLEM]: 'This is a test',
  [FIELD.COMMENT1]: 'comment #1',
  [FIELD.COMMENT3]: 'comment #3',
  [FIELD.SERVICE_CODE]: 'c999',
  [FIELD.INITIAL_GROUP]: 'WLLF',
  [FIELD.INITIAL_DISTRICT]: '3116',
  [FIELD.SPEC]: 'Test',
  [FIELD.NAME]: 'Test',
  [FIELD.ADD_NAME]: '',
  [FIELD.ADDRESS]: 'Teststreet, 123',
  [FIELD.POSTAL_CODE]: '1234',
  [FIELD.CITY]: 'Testburg',
  [FIELD.COUNTRY]: 'SE',
  [FIELD.PHONE]: '1234567890',
  [FIELD.MOBILE]: '1234567890',
  [FIELD.EMAIL]: 'test@example.com',
  [FIELD.AFI_OWNER]: 'test',
  [FIELD.AFI_ADDRESS]: 'Teststreet, 123',
  [FIELD.AFI_POSTAL_CODE]: '1234',
  [FIELD.AFI_POSTAL_DISTRICT]: 'Testburg',
  [FIELD.AFI_COUNTRY]: 'SE',
  [FIELD.AFI_PHONE]: '1234567890',
  [FIELD.AFI_MOBILE]: '1234567890',
  [FIELD.AFI_EMAIL]: 'test@example.com'
};

export { ACTIVITY_FIELDS };
