import { camelCase } from 'lodash';

import request from './request';

const EntitiesModel = {
  getEntitySchema: async (entity, withRelations = true) =>
    await request.getJsonData(`/common/${entity}/schema`, {
      query: { ...(withRelations ? { with_relations: true } : {}) }
    }),

  getEntityValueById: async (entity, id, withRelations = true, relations = []) => {
    return await request.getJson(`/common/${entity}/${id}`, {
      query: {
        ...(withRelations ? { with_relations: true } : {}),
        relations: relations?.length ? relations.join(',') : null
      }
    });
  },

  getEntityValues: async (entity, params = {}, keepRelationsCase = false) => {
    if (params?.relations && !keepRelationsCase) {
      params.relations = params.relations.map((relation) => camelCase(relation));
    }

    return await request.postJson(`/common/${entity}/find`, { body: params });
  },

  changeEntityValues: async (entity, values) => await request.postJson(`/common/${entity}`, { body: values }),

  deleteEntityInstance: async (entity, id) => await request.deleteJson(`/common/${entity}/${id}`)
};

export default EntitiesModel;
