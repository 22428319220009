import { Descriptions } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

export default class ActivityPreview extends React.Component {
  async componentDidMount() {
    await this.loadActivityData();
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id) {
      await this.loadActivityData();
    }
  }

  async loadActivityData() {
    if (this.props.onTriggerLoadData) {
      this.props.onTriggerLoadData({ id: this.props.id });
    }
  }

  render() {
    const { data } = this.props;

    if (!data) {
      return null;
    }

    return (
      <Descriptions>
        <Descriptions.Item label="Activity Number">{data.ActivityNumber || '–'}</Descriptions.Item>
        <Descriptions.Item label="Ref No">{data.RefNo || '–'}</Descriptions.Item>
        <Descriptions.Item label="Destination">{data.Destination || '–'}</Descriptions.Item>
        <Descriptions.Item label="Brand">{data.Brand || '–'}</Descriptions.Item>
        <Descriptions.Item label="Model" span={2}>
          {data.ProdId || '–'}
        </Descriptions.Item>
        {data.SerialNo && (
          <Descriptions.Item label="Serial" span={3}>
            {data.SerialNo}
          </Descriptions.Item>
        )}
        {data.ImeiNumber && (
          <Descriptions.Item label="IMEI" span={3}>
            {data.ImeiNumber}
          </Descriptions.Item>
        )}
        <Descriptions.Item label="Customer">{data.CustomerName || '–'}</Descriptions.Item>
        <Descriptions.Item label="Email" span={2}>
          {data.Email || '–'}
        </Descriptions.Item>
        <Descriptions.Item label="Address" span={3}>
          {data.CustomerAddress || '–'} {data.PostalCode || '–'} {data.PostalDistrict || '–'}
        </Descriptions.Item>
      </Descriptions>
    );
  }
}

ActivityPreview.propTypes = {
  id: PropTypes.string,
  data: PropTypes.object,
  onTriggerLoadData: PropTypes.func
};
