// prettier-ignore
import {  ClockCircleOutlined, DeleteOutlined, EditOutlined, LockOutlined, PlusOutlined, RedoOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { Button, Drawer, Input, Skeleton, Space, Spin, Table } from 'antd';
import _ from 'lodash';
import { useState } from 'react';

import { LinkButton } from '@/components';
import { ENTITY, LOGISTIC_USER_SETTINGS } from '@/configs/entities';
import { COMMON_LOGISTIC_PERMISSIONS, COMMON_TIMETRACKER_PERMISSIONS, ROUTE } from '@/configs/general';
import { SchemaForm } from '@/containers';
import { entities } from '@/models';

import { TimeTrackerEmployeeEditForm } from '../timeTracker/components/EmployeeEditForm';

const RESULTS_LIMIT = 20;

const getUserPermissions = (user) =>
  user
    ? _.flatMapDeep([...(user?.roles || [])], (entity) => {
        if (_.isArray(entity)) {
          return entity.map((permission) => permission.name);
        }

        return entity.permissions.map((permission) => permission.name);
      })
    : [];

const setHiddenKeys = (user) => {
  const hiddenKeys = ['directPermissions', 'roles', 'logisticUser.userId', 'timeTrackerUser', 'team'];

  if (_.intersection(COMMON_LOGISTIC_PERMISSIONS, getUserPermissions(user)).length === 0) {
    hiddenKeys.push(_.camelCase(ENTITY.LOGISTIC_USER), 'printers');
  }

  return hiddenKeys;
};

const Users = (props) => {
  const { canEditPermissions } = props;
  const [userToEditIndex, setUserToEditIndex] = useState(null);
  const [timeTrackerUserToEdit, setTimeTrackerUserToEdit] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(RESULTS_LIMIT);
  const [searchValue, setSearchValue] = useState('');
  const { data, loading, refresh, run } = useRequest(
    (currentPage = 1, limit = RESULTS_LIMIT, search) =>
      entities.getEntityValues(
        ENTITY.USER,
        {
          ...(search || searchValue ? { commonSearch: search ?? searchValue } : {}),
          relations: [
            _.camelCase(ENTITY.LOGISTIC_USER),
            _.camelCase(ENTITY.TIMETRACKER_USER),
            'directPermissions',
            'roles',
            'roles.permissions'
          ],
          pagination: { offset: (currentPage - 1) * limit, limit },
          order: ['firstName', 'lastName']
        },
        true
      ),
    { initialData: null }
  );

  const deleteEntityInstance = useRequest((id) => entities.deleteEntityInstance(ENTITY.USER, id), {
    manual: true,
    onSuccess: refresh
  });

  const handleEditClick = (userIndex) => () => setUserToEditIndex(userIndex);
  const handleDeleteClick = (id) => () => deleteEntityInstance.run(id);

  const handleTimeTrackerUserEditClick = (userIndex) => () => {
    const userData = data?.data?.[userIndex];

    if (!userData) {
      setTimeTrackerUserToEdit(null);

      return;
    }

    const { logisticUser, timeTrackerUser, ...user } = userData;
    setTimeTrackerUserToEdit({ ...timeTrackerUser, user });
  };

  const handleUserChanges = () => {
    setUserToEditIndex(null);
    refresh();
  };

  const handleTimeTrackerUserChanges = () => {
    setTimeTrackerUserToEdit(null);
    refresh();
  };

  const handleSearchChanged = (e) => {
    const value = _.trim(e.target.value);
    setSearchValue(value);
    setPage(1);
    run(1, pageSize, value);
  };

  const handlePaginationChange = (currentPage, limit) => {
    setPage(currentPage);
    setPageSize(limit);
    run(currentPage, limit);
  };

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id'
    },
    {
      title: 'Name',
      render: (__, rowData) => `${rowData.firstName} ${rowData.lastName}`
    },
    {
      title: 'Email',
      dataIndex: 'email'
    },
    {
      title: 'Actions',
      width: 200,
      render: (__, rowData, index) => (
        <Actions
          data={rowData}
          index={index}
          canEditPermissions={canEditPermissions}
          onEditClick={handleEditClick}
          onDeleteClick={handleDeleteClick}
          onTimeTrackerUserEditClick={handleTimeTrackerUserEditClick}
          disabled={deleteEntityInstance.loading}
        />
      )
    }
  ];

  const paginationProps = {
    disable: loading,
    page,
    pageSize,
    position: ['bottomLeft'],
    onChange: handlePaginationChange,
    total: data?.pagination?.count || 0
  };

  return (
    <Skeleton active loading={!data?.data}>
      <Spin spinning={loading}>
        <Space className="tab-actions">
          <Button type="primary" onClick={handleEditClick('new')} icon={<PlusOutlined />}>
            Add new user
          </Button>
          <Button onClick={refresh} icon={<RedoOutlined />}>
            Refresh
          </Button>
        </Space>
        <Space direction="vertical">
          <Input.Search
            placeholder="Search value"
            size="large"
            onSearch={handleSearchChanged}
            onPressEnter={handleSearchChanged}
          />
          <Table dataSource={data?.data} columns={columns} rowKey="id" pagination={paginationProps} />
        </Space>
      </Spin>
      <Drawer
        title={
          userToEditIndex === 'new'
            ? 'Add new user'
            : `Edit user: ${data?.data?.[userToEditIndex]?.firstName || ''} ${
                data?.data?.[userToEditIndex]?.lastName || ''
              }`
        }
        visible={userToEditIndex !== null}
        onClose={handleEditClick(null)}
        width={720}
        destroyOnClose
      >
        <SchemaForm
          entity={ENTITY.USER}
          id={data?.data?.[userToEditIndex]?.id}
          onSuccess={handleUserChanges}
          hiddenFieldsKeys={setHiddenKeys}
          predefinedObjects={{
            newPassword: { type: 'string', maxLength: 255, required: false },
            dob: { required: false, label: 'Date of birthday', merge: true },
            teamId: { label: 'Team', merge: true },
            'logisticUser.settings': { ...LOGISTIC_USER_SETTINGS }
          }}
          customFormFieldsProps={{
            teamId: {
              componentProps: {
                customDataToOptions: (componentData = []) =>
                  componentData.map(({ code, id, name }) => ({ label: `${code} (${name})`, value: id }))
              }
            }
          }}
          withRelations={false}
          relations={[_.camelCase(ENTITY.LOGISTIC_USER), 'roles', 'roles.permissions']}
          reorderKeys={[
            { key: 'newPassword', placeAfter: 'lastName' },
            { key: 'logisticUser', placeBefore: 'timeTrackerUser' },
            { key: 'printers', placeAfter: 'logisticUser' }
          ]}
        />
      </Drawer>
      <Drawer
        title={`Edit: ${timeTrackerUserToEdit?.user?.firstName} ${timeTrackerUserToEdit?.user?.lastName}`}
        visible={timeTrackerUserToEdit}
        width="450"
        onClose={handleTimeTrackerUserEditClick()}
        destroyOnClose
      >
        <TimeTrackerEmployeeEditForm user={timeTrackerUserToEdit} onSuccess={handleTimeTrackerUserChanges} />
      </Drawer>
    </Skeleton>
  );
};

const Actions = (props) => {
  const { canEditPermissions, data, disabled, index, onEditClick, onDeleteClick, onTimeTrackerUserEditClick } = props;
  const isTimeTrackerUser = _.intersection(COMMON_TIMETRACKER_PERMISSIONS, getUserPermissions(data)).length !== 0;

  return (
    <Space>
      <Button type="primary" onClick={onEditClick(index)} icon={<EditOutlined />} disabled={disabled}>
        Edit
      </Button>
      {isTimeTrackerUser && (
        <Button
          type="primary"
          onClick={onTimeTrackerUserEditClick(index)}
          icon={<ClockCircleOutlined />}
          disabled={disabled}
        >
          Time tracker user
        </Button>
      )}
      {canEditPermissions && (
        <LinkButton
          type="primary"
          ghost
          icon={<LockOutlined />}
          disabled={disabled}
          to={`${ROUTE.ACCESS_CONTROL}/#${data.id}`}
        >
          Permissions
        </LinkButton>
      )}
      <Button type="primary" danger onClick={onDeleteClick(data.id)} icon={<DeleteOutlined />} disabled={disabled}>
        Delete
      </Button>
    </Space>
  );
};

export default Users;
