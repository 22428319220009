import { MODAL } from '@/configs/modal';

import AddServiceComment from './AddServiceComment';
import AddShipmentData from './AddShipmentData';
import Export from './Export';
import MissedItem from './MissedItem';
import NewItem from './NewItem';
import NewShipment from './NewShipment';

const MODAL_COMPONENT = {
  [MODAL.EXPORT]: Export,
  [MODAL.NEW_SHIPMENT]: NewShipment,
  [MODAL.ADD_SERVICE_COMMENT]: AddServiceComment,
  [MODAL.MISSED_ITEM]: MissedItem,
  [MODAL.NEW_ITEM]: NewItem,
  [MODAL.ADD_SHIPMENT_DATA]: AddShipmentData
};

const ModalManager = (props) => {
  const { id, ...rest } = props;

  const ModalComponent = MODAL_COMPONENT?.[id];

  if (!ModalComponent) {
    return null;
  }

  return <ModalComponent centered visible {...rest} />;
};

export default ModalManager;
