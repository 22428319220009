import { Drawer } from 'antd';

import { ENTITY } from '@/configs/entities';
import { SchemaForm } from '@/containers';

const TransportContainerEditor = (props) => {
  const { id, visible, onClose, onSuccess = undefined } = props;

  return (
    <Drawer
      title={id ? 'Edit container' : 'Add new container'}
      visible={visible}
      onClose={onClose}
      width={720}
      destroyOnClose
    >
      <SchemaForm
        entity={ENTITY.TRANSPORT_CONTAINER}
        id={id || null}
        onSuccess={onSuccess || onClose}
        withRelations={false}
      />
    </Drawer>
  );
};

export default TransportContainerEditor;
