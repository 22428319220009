import { PlusOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { Button, Drawer, Input, PageHeader, Table } from 'antd';
import { trim } from 'lodash';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Block, DateInline, NotFound } from '@/components';
import { ROUTE } from '@/configs/general';
import { inbound } from '@/models';

import AddNewActivity from './AddNewActivity';

const ToolboxActivityPage = () => {
  const [searchValue, setSearchValue] = useState('');
  const [addNewActivityOpened, setAddNewActivityState] = useState(false);
  const history = useHistory();

  const { data, loading, run: search } = useRequest(() => inbound.search(searchValue), {
    manual: true
  });

  const handleSearchChanged = (e) => setSearchValue(trim(e.target.value));
  const handleAddNewActivityStateChange = (value) => () => setAddNewActivityState(value);

  const goToActivity = (activityNumber) => history.push(`${ROUTE.INBOUND_ACTIVITY}/${activityNumber}`);

  const cols = [
    {
      title: 'Activity',
      dataIndex: 'activityNumber',
      key: 'activityNumber'
    },
    {
      title: 'PersDep',
      dataIndex: 'persDep',
      key: 'persDep'
    },
    {
      title: 'Act type',
      dataIndex: 'actType',
      key: 'actType'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status'
    },
    {
      title: 'Date',
      dataIndex: 'timestamp',
      key: 'timestamp',
      render: (text) => <DateInline value={text} />
    },
    {
      title: 'Model',
      dataIndex: 'prodId',
      key: 'prodId'
    },
    {
      title: 'Name',
      dataIndex: 'customerName',
      key: 'customerName'
    },
    {
      title: 'Address',
      dataIndex: 'customerAddress',
      key: 'customerAddress'
    }
  ];

  return (
    <>
      <PageHeader
        title="Toolbox activity"
        extra={
          <Button icon={<PlusOutlined />} type="primary" onClick={handleAddNewActivityStateChange(true)}>
            Add new activity
          </Button>
        }
      />
      <Block flexVerticalAlign={!data} style={{ height: '40px' }}>
        <Input.Search
          placeholder="Search for an activity"
          size="large"
          value={searchValue}
          onChange={handleSearchChanged}
          onSearch={search}
          onPressEnter={search}
          loading={loading}
        />
      </Block>
      <Block>
        {data?.length > 0 && (
          <Table
            className="ant-table-clickable-rows"
            columns={cols}
            dataSource={data}
            rowKey="activityNumber"
            loading={loading}
            pagination={false}
            onRow={(row) => {
              return {
                onClick: () => goToActivity(row.activityNumber)
              };
            }}
          />
        )}
        {data?.length === 0 && <NotFound onReload={search} />}
      </Block>
      <Drawer
        title="Add new activity"
        visible={addNewActivityOpened}
        onClose={handleAddNewActivityStateChange(false)}
        width="80%"
        destroyOnClose
      >
        <AddNewActivity />
      </Drawer>
    </>
  );
};

export { ToolboxActivityPage };
