import { Form, Input, Modal } from 'antd';
import PropTypes from 'prop-types';

const AddShipmentData = (props) => {
  const { onOk, onClose, ...modalProps } = props;

  const [form] = Form.useForm();

  const handleCloseModal = (saveData) => () => {
    if (!saveData) {
      onClose();

      return;
    }

    form.submit();
  };

  const onFinish = (values) => {
    onOk(values);
    onClose();
  };

  return (
    <Modal
      title="Add package number"
      {...modalProps}
      maskClosable={false}
      onOk={handleCloseModal(true)}
      onCancel={handleCloseModal(false)}
    >
      <Form form={form} onFinish={onFinish} scrollToFirstError>
        <Form.Item
          label="Package number"
          name="packageNumber"
          rules={[{ required: true, message: 'Please input Package number' }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

AddShipmentData.propTypes = {
  onClose: PropTypes.func,
  onOk: PropTypes.func
};

export default AddShipmentData;
