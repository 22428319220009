import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlParse from 'html-parse-stringify';
import htmlToDraft from 'html-to-draftjs';
import invariant from 'invariant';
import { trim } from 'lodash';
import React from 'react';
import { Editor } from 'react-draft-wysiwyg';

import Uploader from '@/helpers/uploader';

export default class TextEditor extends React.Component {
  constructor(props) {
    super(props);

    invariant(
      !this.props.enableImages || (this.props.awsSettings && this.props.uploadFolder),
      'Image storage is not configured'
    );
    let text = trim(props.value);

    if (text.length) {
      const ast = htmlParse.parse(text);

      if (ast?.[0]?.name === 'img') {
        ast[0] = {
          type: 'tag',
          name: 'p',
          children: [ast[0]],
          voidElement: false
        };

        text = htmlParse.stringify(ast);
      }

      const contentBlock = htmlToDraft(text);

      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        this.state = { editorState };
      }
    } else {
      this.state = { editorState: EditorState.createEmpty() };
    }
  }

  uploadImage = async (file) => {
    const link = await Uploader.upload(file, {
      awsSettings: this.props.awsSettings,
      uploadFolder: this.props.uploadFolder
    });

    return { data: { link } };
  };

  onEditorStateChange = (editorState) => {
    const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    this.props.onChange(html);
    this.setState({ editorState });
  };

  render() {
    const { editorState } = this.state;

    return (
      <Editor
        editorState={editorState}
        wrapperClassName="text-editor-wrapper"
        editorClassName="text-editor"
        onEditorStateChange={this.onEditorStateChange}
        toolbar={{
          options: ['inline', 'blockType', 'list', 'textAlign', 'link', 'emoji', 'image', 'remove', 'history'],
          image: this.props.enableImages && {
            urlEnabled: true,
            uploadEnabled: true,
            uploadCallback: this.uploadImage
          }
        }}
      />
    );
  }
}
