import { useRequest } from 'ahooks';
import { Divider, Input, Modal } from 'antd';
import { trim } from 'lodash';
import { useState } from 'react';

import { ActivityPreview } from '@/components';
import { onError } from '@/helpers/message';
import { isActivityNumber } from '@/helpers/validations';
import { request } from '@/models';

const NewItemModal = (props) => {
  const { onClose, onConfirm, ...modalProps } = props;
  const [activityNumber, setActivityNumber] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { data, loading, mutate, run } = useRequest(
    (activity) => request.getJsonData(`/api/v1/activity/by_activity_number/${activity}`),
    {
      manual: true,
      onError
    }
  );

  const handleActivity = ({ activity }) =>
    isActivityNumber(activity || activityNumber) ? run(activity || activityNumber) : mutate(null);

  const handleInput = (e) => {
    const value = trim(e.target.value);
    setActivityNumber(value);
    handleActivity({ activity: value });
  };

  const handleCloseModal = (addNewItem) => async () => {
    if (addNewItem) {
      setIsLoading(true);

      await onConfirm({ activityNumber });

      setIsLoading(false);
    }

    onClose();
  };

  return (
    <Modal
      title="Add new item to the shipment"
      {...modalProps}
      maskClosable={false}
      onClose={onClose}
      onOk={handleCloseModal(true)}
      onCancel={handleCloseModal(false)}
      okButtonProps={{ loading: loading || isLoading }}
    >
      <Input.Search
        placeholder="Activity number"
        loading={isLoading}
        value={activityNumber}
        onChange={handleInput}
        onSearch={handleActivity}
        onPressEnter={handleActivity}
      />
      {data && (
        <>
          <Divider plain />
          <ActivityPreview data={data} />
        </>
      )}
    </Modal>
  );
};

export default NewItemModal;
