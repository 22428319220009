import { EditOutlined } from '@ant-design/icons';
import { Button, PageHeader, Typography } from 'antd';
import createDOMPurify from 'dompurify';

import { Block, DateDisplay, WithUser } from '@/components';
import { PERMISSION } from '@/configs/general';
import { Editable } from '@/elements';
import { useGoTo } from '@/helpers/hooks';

const DOMPurify = createDOMPurify(window);

const Page = (props) => {
  const {
    canEdit,
    data: { title, date, content },
    onStartEdit
  } = props;

  const goBack = useGoTo('/');

  const extra = canEdit ? (
    <Button type="primary" icon={<EditOutlined />} onClick={onStartEdit}>
      Edit news
    </Button>
  ) : null;

  return (
    <>
      <PageHeader
        title={`News: ${title}`}
        onBack={goBack}
        subTitle={<DateDisplay date={date} inline />}
        extra={extra}
      />
      <Block>
        <Typography>
          <div className="ant-typography clearfix" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }} />
        </Typography>
      </Block>
    </>
  );
};

const NewsPage = WithUser(({ loginData, ...props }) =>
  Editable(Page, {
    titleAppendix: 'news',
    parentPath: '/',
    endpoint: '/api/v1/dashboard/newsfeed/:newsId',
    permissionToEdit: PERMISSION.EDIT_NEWS,
    fields: [
      { id: 'title', name: 'Title' },
      { id: 'date', name: 'Date', type: 'DatePicker' },
      {
        id: 'content',
        name: 'Content',
        type: 'TextEditor',
        props: {
          enableImages: true,
          awsSettings: window._GLOBALS.aws,
          uploadFolder: 'news'
        }
      }
    ],
    ...props
  })
);

export { NewsPage };
