import { DeleteOutlined, EditOutlined, GlobalOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Divider, Drawer, List, Modal, Space, Tag } from 'antd';
import { useState } from 'react';

import { ENTITY } from '@/configs/entities';
import { SchemaForm } from '@/containers';

import { GDPRTooltip } from './GdprTooltip';

const PERMISSION_ENTITY = {
  PERMISSION: 'permission',
  ROLE: 'role'
};

const ENTITY_BY_ENTITY_KEY = {
  [PERMISSION_ENTITY.PERMISSION]: ENTITY.AUTH_PERMISSION,
  [PERMISSION_ENTITY.ROLE]: ENTITY.AUTH_ROLE
};

const RolePermissionsDetails = (props) => {
  const { permissions } = props;

  if (!permissions?.length) {
    return <div>Permissions included: no permissions assigned yet</div>;
  }

  return (
    <div>
      Permissions included:{' '}
      {permissions.map(({ id, name, gdprImportant }) => (
        <Tag key={id} color={gdprImportant ? 'error' : 'default'}>
          {name}
        </Tag>
      ))}
    </div>
  );
};

const EntitiesList = (props) => {
  const { entities = [], itemExtra, onDelete, onEdit } = props;

  return (
    <List
      bordered
      itemLayout="horizontal"
      dataSource={entities}
      size="small"
      renderItem={(entity, index) =>
        entity.isChanged ? null : (
          <List.Item
            className={`${entity.isAssigned ? '' : 'un'}assigned-entity-item`}
            actions={[
              <Button onClick={onEdit(index)} icon={<EditOutlined />} size="small">
                Edit
              </Button>,
              <Button danger onClick={onDelete} icon={<DeleteOutlined />} size="small">
                Delete
              </Button>
            ]}
          >
            <List.Item.Meta
              title={
                <Space>
                  <span>{entity.name}</span>
                  {entity.gdprImportant && <GDPRTooltip />}
                </Space>
              }
              description={
                <>
                  {entity.description}
                  {itemExtra?.(entity)}
                </>
              }
            />
          </List.Item>
        )
      }
    />
  );
};

const ProjectEntitiesList = (props) => {
  const { onChange, permissions, roles } = props;
  const [entityToEditIndex, setEntityToEditIndex] = useState(null);
  const [entityToEditType, setEntityToEditType] = useState(null);

  const handleEditClick = (type) => (index) => () => {
    setEntityToEditIndex(index);
    setEntityToEditType(type);
  };

  const handleDeleteClick = () =>
    Modal.warning({
      title: 'To remove this we have a manual fixit-routine',
      content: (
        <div>
          Please create a ticket{' '}
          <Button
            icon={<GlobalOutlined />}
            type="link"
            href="https://jira.elkjop.com/servicedesk/customer/portal/101/create/2974"
            target="_blank"
          >
            here
          </Button>
        </div>
      ),
      centered: true
    });

  const handleSuccessChanges = () => {
    setEntityToEditIndex(null);
    setEntityToEditType(null);
    onChange();
  };

  const transformValuesBeforeChange = (values) => {
    const newValues = {
      ...values,
      projectId: props.id
    };

    if (!newValues.id.startsWith(`${props.id}-`)) {
      newValues.id = `${props.id}-${newValues.id}`;
    }

    return newValues;
  };

  return (
    <>
      <Divider>Roles</Divider>
      <Space direction="vertical">
        <Button type="primary" onClick={handleEditClick(PERMISSION_ENTITY.ROLE)('new')} icon={<PlusOutlined />}>
          Add new role
        </Button>
        <EntitiesList
          entities={roles}
          itemExtra={RolePermissionsDetails}
          onEdit={handleEditClick(PERMISSION_ENTITY.ROLE)}
          onDelete={handleDeleteClick}
        />
      </Space>
      <Divider>Direct permissions</Divider>
      <Space direction="vertical">
        <Button type="primary" onClick={handleEditClick(PERMISSION_ENTITY.PERMISSION)('new')} icon={<PlusOutlined />}>
          Add new permission
        </Button>
        <EntitiesList
          entities={permissions}
          onEdit={handleEditClick(PERMISSION_ENTITY.PERMISSION)}
          onDelete={handleDeleteClick}
        />
      </Space>
      <Drawer
        title={
          entityToEditIndex === 'new'
            ? `Add new ${entityToEditType}`
            : `Edit ${entityToEditType}: ${props[`${entityToEditType}s`]?.[entityToEditIndex]?.name || []}`
        }
        visible={entityToEditIndex !== null && entityToEditType !== null}
        onClose={handleEditClick(null)(null)}
        width={720}
        destroyOnClose
      >
        <SchemaForm
          entity={ENTITY_BY_ENTITY_KEY[entityToEditType]}
          id={props[`${entityToEditType}s`]?.[entityToEditIndex]?.id}
          onSuccess={handleSuccessChanges}
          hiddenFieldsKeys={['project']}
          saveButtonText={`Save ${entityToEditType}`}
          transformValuesBeforeChange={transformValuesBeforeChange}
          extraFiltersByFieldKey={{ permissions: { projectId: props.id } }}
        />
      </Drawer>
    </>
  );
};

export { ProjectEntitiesList };
