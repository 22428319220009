import { Card, Row } from 'antd';

import { Block, EmployeeStatistic } from '@/components';
import { COLOR } from '@/configs/general';
import { timetracker } from '@/models';

const COL_PROPS = { span: 6 };

const BalanceInfo = (props) => {
  const { employeeInfo, hideFlexTime, loading, user, workDay, workPeriod } = props;
  const { timeTrackerUser } = user || {};

  const isHourlyPaidEmployee = timetracker.isHourlyPaidEmployee(timeTrackerUser);

  if (user?.country === 'SE') {
    return (
      <Block>
        <Card title="Time tracking balance">
          <Row>
            {!isHourlyPaidEmployee && !user?.flexTimeBypassed && !hideFlexTime && (
              <EmployeeStatistic
                title="Flex saldo before today"
                value={workPeriod?.flexTimeSaldo - (workDay?.flexTime ?? 0)}
                isSaldo
                loading={loading}
                colProps={COL_PROPS}
              />
            )}
            {isHourlyPaidEmployee && (
              <EmployeeStatistic
                title="Paid time"
                value={workPeriod?.totalRatedMinutes}
                isSaldo
                loading={loading}
                colProps={COL_PROPS}
              />
            )}
            {!isHourlyPaidEmployee && !user?.flexTimeBypassed && (
              <EmployeeStatistic
                title="Flex Δ by the end of the day"
                color={(employeeInfo?.flexTime ?? 0) >= 0 ? COLOR.GREEN : COLOR.RED}
                value={employeeInfo?.flexTime}
                isSaldo
                loading={loading}
                colProps={COL_PROPS}
              />
            )}
            <EmployeeStatistic
              title="Compensation time saldo"
              value={workPeriod?.compensationTimeSaldo}
              isSaldo
              loading={loading}
              colProps={COL_PROPS}
            />
            <EmployeeStatistic
              title="Paid overtime"
              value={workPeriod?.paidOvertime}
              isSaldo
              loading={loading}
              colProps={COL_PROPS}
            />
          </Row>
        </Card>
      </Block>
    );
  }

  if (user?.country === 'NO') {
    const flexTimeBeforeToday = workPeriod?.flexTimeSaldo + workDay?.expectedMinutes ?? 0 - workDay?.ratedMinutes ?? 0;

    return (
      <Block>
        <Card title="Time tracking balance">
          <Row>
            {!hideFlexTime && (
              <EmployeeStatistic
                title="Work time deficit"
                value={flexTimeBeforeToday < 0 ? flexTimeBeforeToday : 0}
                isSaldo
                loading={loading}
                colProps={COL_PROPS}
              />
            )}
            <EmployeeStatistic
              title="Paid overtime"
              value={workPeriod?.paidOvertime}
              isSaldo
              loading={loading}
              colProps={COL_PROPS}
            />
          </Row>
        </Card>
      </Block>
    );
  }

  return null;
};

export { BalanceInfo };
