import { CloseCircleOutlined, DeleteOutlined, EditOutlined, PlayCircleOutlined, RedoOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { Alert, Button, DatePicker, Space, TimePicker } from 'antd';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';

import { CheckInInline } from '@/components';
import { onTimeTrackerError } from '@/helpers/message';
import { timetracker } from '@/models';

import { timeTrackerContext } from '../timeTrackerContext';

const TimeTrackerCheckInOutEdit = (props) => {
  const { user } = props;

  const [defaultDate, setDefaultDate] = useState(null);
  const [defaultTime, setDefaultTime] = useState(null);
  const [date, setDate] = useState(defaultDate);
  const [time, setTime] = useState(defaultTime);
  const { onIntervalUpdate } = useContext(timeTrackerContext);
  const userInfo = useRequest(() => timetracker.getEmployeeInfo(user), {
    onSuccess: ({ checkedInAt }) => {
      setDate(checkedInAt ? moment(checkedInAt).startOf('day') : defaultDate);
      setTime(checkedInAt ? moment(checkedInAt).add(1, 'minute') : defaultTime);
    },
    onError: onTimeTrackerError,
    initialData: {},
    manual: true
  });
  const changeUser = useRequest(timetracker.changeUser, {
    onSuccess: () => {
      onIntervalUpdate();
      userInfo.run();
    },
    onError: onTimeTrackerError,
    manual: true
  });
  const check = useRequest(
    (timestamp, prevTimestamp) => {
      return prevTimestamp ? timetracker.checkOut(user.userId, timestamp) : timetracker.checkIn(user.userId, timestamp);
    },
    {
      onSuccess: () => {
        onIntervalUpdate();
        userInfo.run();
      },
      onError: onTimeTrackerError,
      manual: true
    }
  );

  useEffect(() => {
    if (!user) {
      return;
    }

    const checkInTimestamp = user.activeCheckInTimestamp;
    const dDate = checkInTimestamp ? moment(checkInTimestamp).startOf('day') : moment().startOf('day');
    const dTime = checkInTimestamp ? moment(checkInTimestamp).add(1, 'minute') : moment();

    setDefaultDate(dDate);
    setDefaultTime(dTime);
    setDate(checkInTimestamp ? moment(checkInTimestamp).startOf('day') : dDate);
    setTime(checkInTimestamp ? moment(checkInTimestamp).add(1, 'minute') : dTime);
  }, [user?.userId]);

  const handleCheck = (prevTimestamp) => async () => {
    const timestamp = date
      .clone()
      .add(time.diff(time.clone().startOf('day')))
      .utc()
      .format();

    await check.run(timestamp, prevTimestamp);
  };

  const handleChangeTimestamp = (deleteCheckin) => async () => {
    await changeUser.run({
      id: user.userId,
      timeTrackerUser: {
        activeCheckInTimestamp: deleteCheckin
          ? null
          : date
              .clone()
              .add(time.diff(time.clone().startOf('day')))
              .utc()
              .format()
      }
    });
  };

  const checkedInAt = userInfo.data ? userInfo.data.checkedInAt : user?.activeCheckInTimestamp;
  const isCheckedIn = Boolean(checkedInAt);
  const loading = props.loading || check.loading || userInfo.loading || changeUser.loading;

  if (!user) {
    return null;
  }

  return (
    <>
      {isCheckedIn && (
        <Alert
          message={`Last check-in was ${CheckInInline({ asString: true, showSeconds: true, value: checkedInAt })}`}
          type="info"
          showIcon
        />
      )}
      <Space size="middle">
        <DatePicker
          allowClear={false}
          defaultValue={defaultDate}
          onChange={setDate}
          disabled={loading}
          value={date}
          showToday={false}
        />
        <TimePicker
          className="ant-picker-hhmm"
          allowClear={false}
          defaultValue={defaultTime}
          onChange={setTime}
          disabled={loading}
          value={time}
          format="HH:mm"
        />
        <Space>
          <Button
            icon={isCheckedIn ? <CloseCircleOutlined /> : <PlayCircleOutlined />}
            loading={loading}
            type="primary"
            onClick={handleCheck(checkedInAt)}
          >{`Add ${isCheckedIn ? 'check-out' : 'check-in'}`}</Button>
          {isCheckedIn && (
            <>
              <Button icon={<EditOutlined />} disabled={loading} onClick={handleChangeTimestamp(false)}>
                Set new check-in time
              </Button>
              <Button icon={<DeleteOutlined />} disabled={loading} onClick={handleChangeTimestamp(true)} danger ghost>
                Delete check-in
              </Button>
            </>
          )}
          <Button icon={<RedoOutlined />} onClick={userInfo.run} disabled={loading}>
            Refresh
          </Button>
        </Space>
      </Space>
    </>
  );
};

export { TimeTrackerCheckInOutEdit };
