import Request from './request';

export default {
  async login(login, password) {
    const result = await Request.postJsonData('/api/v1/login', {
      body: {
        login,
        password
      }
    });

    return result;
  },

  async logout() {
    await Request.postJson('/api/v1/logout');
  },

  async loadCurrentUser() {
    try {
      const result = await Request.getJsonData('/api/v1/current_user');

      return result;
    } catch (e) {
      if (e instanceof Request.ForbiddenError) {
        return null;
      }

      throw e;
    }
  }
};
