import { useRequest } from 'ahooks';
import { Card, Spin } from 'antd';
import _ from 'lodash';
import { useContext } from 'react';

import { Block } from '@/components';
import { ENTITY, LOGISTIC_USER_SETTINGS } from '@/configs/entities';
import { SchemaForm } from '@/containers';
import userContext from '@/helpers/userContext';

const LogisticSettings = () => {
  const { syncUserSettings, user } = useContext(userContext);

  const { loading, run: syncSettings } = useRequest(syncUserSettings, {
    manual: true
  });

  return (
    <Block>
      <Card title="Logistic settings">
        <Spin spinning={loading}>
          <SchemaForm
            entity={ENTITY.USER}
            id={user?.id}
            hiddenFieldsKeys={[
              _.camelCase(ENTITY.TIMETRACKER_USER),
              'departmentId',
              'email',
              'password',
              'firstName',
              'lastName',
              'country',
              'authId',
              'directPermissions',
              'roles',
              'dob',
              'team'
            ]}
            predefinedObjects={{ 'logisticUser.settings': { ...LOGISTIC_USER_SETTINGS } }}
            saveButtonText="Save settings"
            showTitles={false}
            onSuccess={syncSettings}
          />
        </Spin>
      </Card>
    </Block>
  );
};

export { LogisticSettings };
