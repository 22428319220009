import { PageHeader } from 'antd';

import { SHIPMENTS_TYPE } from '@/configs/shipments';
import { ShipmentsTable } from '@/containers';

const ReturnedShipmentsPage = () => {
  return (
    <>
      <PageHeader title="Returned shipments" />
      <ShipmentsTable type={SHIPMENTS_TYPE.RETURNED} />
    </>
  );
};

export { ReturnedShipmentsPage };
