import { useRequest } from 'ahooks';
import { Button, Input, PageHeader, Radio, Space } from 'antd';
import { trim } from 'lodash';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Block } from '@/components';
import { ROUTE } from '@/configs/general';
import { SHIPMENTS_TYPE } from '@/configs/shipments';
import { ShipmentsTable } from '@/containers';
import { useTimeout } from '@/helpers/hooks';
import { onError } from '@/helpers/message';
import { isActivityNumber, isGuid } from '@/helpers/validations';
import { request } from '@/models';

const INPUT_ID = 'search';

const SEARCH_TYPE = {
  INBOUND: 'inbound',
  INFO: 'info'
};

const SearchPage = () => {
  const [searchType, setSearchType] = useState(SEARCH_TYPE.INBOUND);
  const [searchValue, setSearchValue] = useState('');
  const history = useHistory();

  const setFocus = () => document.querySelector(`#${INPUT_ID}`).focus();

  useTimeout(setFocus, 500);

  const { data, run, loading } = useRequest(
    () => request.getJsonData(`/api/v1/shipment/search?search=${searchValue}`),
    {
      manual: true,
      onSuccess: (result) => {
        if (result?.length === 1) {
          history.push(`${ROUTE.INBOUND}/shipment/${result[0].guid}`);
        }
      },
      onError
    }
  );

  const search = () => {
    // Inbound items handles automatically on input value change
    if (searchType === SEARCH_TYPE.INBOUND || !searchValue) {
      return;
    }

    run();
  };

  const handleSearchTypeChange = (e) => {
    setSearchType(e.target.value);
    setFocus();
  };

  const handleSearchChange = (e) => {
    const value = trim(e.target.value);
    setSearchValue(value);

    if (searchType !== SEARCH_TYPE.INBOUND) {
      return;
    }

    if (isActivityNumber(value)) {
      history.push(`${ROUTE.INBOUND}/activity/${value}`);
    } else if (isGuid(value)) {
      history.push(`${ROUTE.INBOUND}/shipment/${value}`);
    }
  };

  const placeholder =
    searchType === SEARCH_TYPE.INBOUND
      ? 'Activity number or shipment GUID'
      : 'Shipment GUID, package number, shipment number, activity number, hub case GUID, ref no, serial';

  const resultsFound = data?.length;

  return (
    <>
      <PageHeader title="Search for an activity or a shipment" />
      <Block fitContent={!resultsFound} flexVerticalAlign={!resultsFound} padded={resultsFound} transparent>
        <Space direction="vertical" size="middle">
          <Space className="ant-space-first-item-flex">
            <Input.Search
              loading={loading}
              allowClear
              placeholder={placeholder}
              id={INPUT_ID}
              onChange={handleSearchChange}
              onPressEnter={search}
              size="large"
              value={searchValue}
            />
            <Button type="primary" size="large" onClick={search} disabled={loading}>
              Search
            </Button>
          </Space>
          <Radio.Group onChange={handleSearchTypeChange} value={searchType}>
            <Radio value={SEARCH_TYPE.INBOUND}>Activity or shipment to process</Radio>
            <Radio value={SEARCH_TYPE.INFO}>Shipment info by extended data</Radio>
          </Radio.Group>
          {data?.length > 1 && (
            <ShipmentsTable
              customData={{ data, pagination: { offset: 0, count: 1 } }}
              customProviders={[]}
              hideHeader
              type={SHIPMENTS_TYPE.SHOW_INFO}
            />
          )}
        </Space>
      </Block>
    </>
  );
};

export { SearchPage };
