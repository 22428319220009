'use strict';

const moment = require('moment');

const WARRANTY_TIME = { FI: 24, DK: 24, SE: 36, NO: 60 };
const IN_WARRANTY_IDS = [5258, 5297, 5465, 5583, 5582];

module.exports = function warrantyCheck(activity) {
  if (!activity.salesDate || !IN_WARRANTY_IDS.includes(Number(activity.serviceType))) {
    return true;
  }

  const salesDate = moment(activity.salesDate);
  const dateBooked = moment(activity.dateBooked);
  const months = dateBooked.diff(salesDate, 'months');
  const maxMonths = WARRANTY_TIME[activity.country];

  if (months > maxMonths) {
    return `Warranty violation! The product purchased ${salesDate.format(
      'L'
    )} and registered for service ${dateBooked.format('L')}
which is longer than ${maxMonths} months according to the law of ${activity.country}.`;
  }

  return true;
};
