// prettier-ignore
import { CheckCircleTwoTone, ExportOutlined, QuestionCircleTwoTone, SearchOutlined, TagOutlined, UnorderedListOutlined, WarningFilled } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { Button, Checkbox, Col, DatePicker, Input, Popover, Row, Space, Table } from 'antd';
import { keyBy, trim } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { DateInline, LinkButton, PrintButton, ShipmentPreview } from '@/components';
import { PRINTER_TYPE } from '@/configs/entities';
import { ROUTE } from '@/configs/general';
import { MODAL } from '@/configs/modal';
import { SHIPMENTS_TYPE } from '@/configs/shipments';
import { SelectAutocomplete } from '@/elements';
import defer from '@/helpers/defer';
import { useDidMount } from '@/helpers/hooks';
import { onError, showError } from '@/helpers/message';
import { inbound, request } from '@/models';

import ModalManager from './modalManager/ModalManager';

const DEFAULT_PAGE_SIZE = 50;

const getMappedProviders = (providers = [], mapProviders = true) => [
  { label: 'Any providers', value: null },
  ...(mapProviders ? providers.map((x) => ({ label: x.name, value: x.id })) : providers)
];

const ShipmentsTable = (props) => {
  const { customProviders, hideHeader, type } = props;

  const [searchValue, setSearchValue] = useState('');
  const [dateRange, setDateRange] = useState([null, null]);
  const [providerId, setProviderId] = useState(null);
  const [currentModal, setModal] = useState(null);
  const [currentPageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [mappedProviders, setMappedProviders] = useState(getMappedProviders(customProviders, false));
  const [failedOnly, setFailedOnly] = useState(false);
  const customProviderIdToName = customProviders ? keyBy(customProviders, 'value') : {};

  const shipmentsRequest = useRequest(
    ({ page, pageSize }) => {
      const query = { offset: page * pageSize, limit: pageSize, search: searchValue, providerId };
      query.failedOnly = failedOnly;

      if (dateRange && dateRange[0]) {
        query.startDate = moment(dateRange[0]).startOf('day').format();
      }

      if (dateRange && dateRange[1]) {
        query.endDate = moment(dateRange[1]).endOf('day').format();
      }

      return request.getJson(`/api/v1/shipment/${type}`, {
        query
      });
    },
    {
      manual: true,
      initialData: { data: [], pagination: { offset: 0, count: 0 } },
      onError
    }
  );

  const providersRequest = useRequest(() => request.getJsonData('/api/v1/shipment/providers'), {
    manual: customProviders,
    onSuccess: (result) => {
      setMappedProviders(getMappedProviders(result));
    },
    onError
  });

  const search = (page = 1, pageSize = currentPageSize) => {
    if (pageSize !== currentPageSize) {
      setPageSize(pageSize);
    }

    if (!props.customData) {
      shipmentsRequest.run({ page: page - 1, pageSize });
    }
  };

  const handleSearch = () => {
    search();
  };

  useDidMount(() => {
    search();
  });

  useEffect(search, [dateRange, providerId, failedOnly]);

  const handleSearchChanged = (e) => setSearchValue(trim(e.target.value));

  const handleDateRangeChanged = (dates) => {
    setDateRange(dates);
  };

  const handleFailedOnlyChange = (e) => setFailedOnly(e.target.checked);

  const toggleModal = (modal = null, modalProps) => setModal({ id: modal, ...modalProps });

  const finalize = (shipment) => async () => {
    let packageNumber;

    if (shipment.shipmentData.pending) {
      packageNumber = defer();

      toggleModal(MODAL.ADD_SHIPMENT_DATA, {
        onClose: () => {
          toggleModal(null);
        },
        onOk: (values) => {
          packageNumber.resolve(values?.packageNumber);
        }
      });

      packageNumber = await packageNumber;
    }

    try {
      await inbound.finalizeShipment(
        shipment.guid,
        packageNumber
          ? {
              packages: [{ packageId: 1, packageNumber }]
            }
          : {}
      );
      search();
    } catch (e) {
      showError(e.message);
    }
  };

  const removeItem = (shipment, item) => async () => {
    try {
      await inbound.deleteItem(shipment.guid, item.guid);
      search();
    } catch (e) {
      showError(e.message);
    }
  };

  const cols = [
    {
      title: 'Service provider',
      dataIndex: 'customData',
      key: 'customData',
      render: (customData) =>
        customProviders
          ? customProviderIdToName?.[customData?.serviceProviderId]?.label
          : customData?.serviceProviderName
    },
    type !== SHIPMENTS_TYPE.PUSHED
      ? {
          title: 'Shipment',
          dataIndex: 'shipmentType',
          key: 'shipmentType',
          render: (shipmentType) => shipmentType.name
        }
      : null,
    type === SHIPMENTS_TYPE.RETURNED
      ? {
          title: 'Container',
          dataIndex: 'container',
          key: 'container',
          width: 100,
          render: (_, row) => {
            const container = (row.customData || {}).containerId;

            if (container) {
              return <Link to={`${ROUTE.TRANSPORT_CONTAINER}/${container}`}>{container}</Link>;
            }

            return '–';
          }
        }
      : null,
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => <DateInline value={text} />
    },
    {
      title: 'Items',
      dataIndex: 'packages',
      key: 'packages',
      render: (packages) => {
        const items = packages.flatMap((x) => x.items);
        const withFailedCase = items.some(({ customData }) => customData?.caseCreated === 'fail');

        return (
          <Space>
            {withFailedCase && (
              <Popover content="This shipment contains items with errors">
                <WarningFilled style={{ color: '#f5222d' }} />
              </Popover>
            )}
            <span>{items.length}</span>
          </Space>
        );
      }
    },
    type === SHIPMENTS_TYPE.RETURNED
      ? {
          title: 'Receiver',
          dataIndex: 'receiver',
          key: 'receiver',
          render: (_, row) =>
            [
              row.receiver.name,
              row.receiver.address,
              row.receiver.postalCode,
              row.receiver.city,
              row.receiver.countryCode
            ].join(' ')
        }
      : null,
    type === SHIPMENTS_TYPE.SHOW_INFO
      ? {
          title: 'Matched by',
          dataIndex: 'matchedBy',
          key: 'matchedBy'
        }
      : null,
    [SHIPMENTS_TYPE.FORWARDED, SHIPMENTS_TYPE.PUSHED].includes(type)
      ? {
          title: 'Pre-alert',
          key: 'pre-alert',
          render: (_, row) =>
            row?.customData?.preAlertSent ? (
              <CheckCircleTwoTone twoToneColor="#52c41a" />
            ) : (
              <QuestionCircleTwoTone twoToneColor="#faad14" />
            )
        }
      : null,
    type !== SHIPMENTS_TYPE.PUSHED
      ? {
          title: 'Actions',
          key: 'actions',
          width: 150,
          render: (_, row) => (
            <Space direction="vertical">
              <LinkButton to={`${ROUTE.INBOUND_SHIPMENT}/${row.guid}`} block type="button">
                <SearchOutlined />
                Details
              </LinkButton>
              {row.finalized && (
                <>
                  <Space className="ant-space-first-item-flex">
                    <Button href={inbound.getLabelUrl(row.guid)} target="_blank" rel="noopener noreferrer">
                      <TagOutlined />
                      Label
                    </Button>
                    <PrintButton
                      pdfUrl={`https://senderella.io/v1/shipment/${row.guid}/label?format=pdf`}
                      type={PRINTER_TYPE.BIG_LABEL}
                    />
                  </Space>
                  <Space>
                    <Button
                      href={`https://senderella.io/v1/shipment/${row.guid}/picking-list`}
                      target="_blank"
                      rel="noopener noreferrer"
                      block
                    >
                      <UnorderedListOutlined />
                      Picking list
                    </Button>
                    <PrintButton
                      pdfUrl={`https://senderella.io/v1/shipment/${row.guid}/picking-list?format=pdf`}
                      type={PRINTER_TYPE.A4}
                    />
                  </Space>
                </>
              )}
              {!row.finalized && (
                <Button onClick={finalize(row)} type="primary" block>
                  <ExportOutlined />
                  Finalize
                </Button>
              )}
            </Space>
          )
        }
      : null,
    type === SHIPMENTS_TYPE.PUSHED
      ? {
          title: 'Pushed by',
          dataIndex: 'customData',
          key: 'customData',
          render: (_, row) => row.customData?.pushedBy ?? '-'
        }
      : null
  ].filter(Boolean);

  const data = props.customData || shipmentsRequest.data;
  const useFailedOnlyCheckbox = [SHIPMENTS_TYPE.FORWARDED, SHIPMENTS_TYPE.PUSHED].includes(type);

  const paginationProps = {
    current: Math.ceil(data.pagination.offset / currentPageSize) + 1,
    pageSize: currentPageSize,
    total: data.pagination.count,
    onChange: search
  };

  return (
    <>
      <ModalManager {...currentModal} />
      <Table
        columns={cols}
        dataSource={data?.data}
        rowKey="guid"
        pagination={paginationProps}
        loading={shipmentsRequest.loading || providersRequest.loading}
        title={
          hideHeader
            ? null
            : () => (
                <Input.Group size="large">
                  <Row gutter={8} align="middle">
                    <Col flex="auto">
                      <Input.Search
                        placeholder="Search for activity"
                        size="large"
                        value={searchValue}
                        onChange={handleSearchChanged}
                        onSearch={handleSearch}
                        onPressEnter={handleSearch}
                      />
                    </Col>
                    <Col span={6}>
                      <DatePicker.RangePicker
                        value={dateRange}
                        onCalendarChange={handleDateRangeChanged}
                        allowEmpty={[true, true]}
                        className="full-width"
                        size="large"
                      />
                    </Col>
                    <Col span={6}>
                      <SelectAutocomplete
                        options={mappedProviders}
                        value={providerId}
                        onSelect={setProviderId}
                        className="full-width"
                        size="large"
                        skipMapping
                      />
                    </Col>
                    {useFailedOnlyCheckbox && (
                      <Col flex="150px">
                        <Checkbox checked={failedOnly} onChange={handleFailedOnlyChange}>
                          Show failed only
                        </Checkbox>
                      </Col>
                    )}
                  </Row>
                </Input.Group>
              )
        }
        expandable={{
          expandedRowRender: (row) => (
            <ShipmentPreview
              data={row}
              onRemove={type !== SHIPMENTS_TYPE.PUSHED ? removeItem : null}
              showCaseCreatedStatus={type !== SHIPMENTS_TYPE.RETURNED}
            />
          )
        }}
      />
    </>
  );
};

export default ShipmentsTable;
