import { Divider, Select } from 'antd';
import { isString } from 'lodash';

const { OptGroup, Option } = Select;

const SelectStatus = (props) => {
  const { options, initialValue, possibleOptions, value, ...selectProps } = props;

  let optionsToShow = options;

  if (isString(optionsToShow)) {
    optionsToShow = [options];
  }

  const recommended = possibleOptions
    .filter((x) => optionsToShow.some((v) => v.toLowerCase() === x.key.toLowerCase()))
    .filter((x) => x.key.toLowerCase() !== initialValue?.toLowerCase());
  const possible = possibleOptions
    .filter((x) => !optionsToShow.some((v) => v.toLowerCase() === x.key.toLowerCase()))
    .filter((x) => x.key.toLowerCase() !== initialValue?.toLowerCase());
  const initial = possibleOptions.filter((x) => x.key.toLowerCase() === initialValue?.toLowerCase());

  return (
    <>
      <strong className="select-status">{initialValue}</strong>
      <Divider plain>Change status to</Divider>
      <Select showSearch onSearch={selectProps.onChange} {...selectProps} value={value}>
        <OptGroup label="Current">
          {initial.map((x) => (
            <Option key={x.key}>
              <strong>{x.key}</strong> – {x.name}
            </Option>
          ))}
        </OptGroup>
        <OptGroup label="Recommended">
          {recommended.map((x) => (
            <Option key={x.key}>
              <strong>{x.key}</strong> – {x.name}
            </Option>
          ))}
        </OptGroup>
        <OptGroup label="All">
          {possible.map((x) => (
            <Option key={x.key}>
              <strong>{x.key}</strong> – {x.name}
            </Option>
          ))}
        </OptGroup>
      </Select>
    </>
  );
};

export default SelectStatus;
