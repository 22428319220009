import { Alert, Card, Divider } from 'antd';
import { groupBy } from 'lodash';
import moment from 'moment';

import { PayrollCountdown } from '@/components';
import { Loadable } from '@/elements';
import { request } from '@/models';

const nextPayrollDate = (startDate, overrides, currentDate = null) => {
  // Salary day occurs every 25th day of month
  const date = moment(startDate).startOf('month').add(24, 'days');
  const month = date.format('MM');

  if (overrides[month]) {
    date.date(overrides[month]); // Special days
  } else {
    const day = date.day();

    // Change weekends to previous friday
    if (day === 0) {
      date.subtract(2, 'days');
    } else if (day === 6) {
      date.subtract(1, 'days');
    }
  }

  if (date.isBefore(currentDate || startDate)) {
    date.add(1, 'months'); // Past day, try again with next month

    return nextPayrollDate(date, overrides, startDate);
  }

  return date;
};

const DatesCard = ({ data }) => {
  if (!data) {
    return null;
  }

  const today = moment();
  const countries = [
    { code: 'SE', name: 'Sweden', date: null },
    { code: 'NO', name: 'Norway', date: null },
    { code: 'FI', name: 'Finland', date: null }
  ];

  for (const obj of countries) {
    obj.date = nextPayrollDate(today, data[obj.code] || {});
  }

  const indexedDates = groupBy(countries, (x) => x.date.toString());
  const differentDates = Object.keys(indexedDates);
  let datesBlock;

  if (differentDates.length === 0) {
    datesBlock = null;
  }

  if (differentDates.length === 1) {
    // One day for all countries, show one block
    datesBlock = <PayrollCountdown date={countries[0].date} />;
  } else {
    // Different days, show as many blocks as different days we have
    datesBlock = differentDates.map((key) => (
      <PayrollCountdown
        key={key}
        countries={indexedDates[key].map((x) => x.name).join(', ')}
        date={indexedDates[key][0].date}
      />
    ));
  }

  return (
    <Card className="dates-card" title="Dates to remember">
      {datesBlock}
      <Divider />
      <Alert
        message="Norway"
        description={`Remember to approve overtime and hours before the 18th of ${countries[1].date
          .toDate()
          .toLocaleString('en-US', { month: 'long' })}`}
        type="warning"
      />
      <Alert
        message="Sweden"
        description="Remember to approve overtime and hours before the 2nd workday in new month"
        type="warning"
      />
      {/* <List
        dataSource={[
          {
            title: 'Norway',
            text: `Remember to approve overtime and hours before the 18th of ${countries[1].date
              .toDate()
              .toLocaleString('en-US', { month: 'long' })}`
          },
          { title: 'Sweden', text: 'Remember to approve overtime and hours before the 2nd workday in new month' }
        ]}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta title={item.title} description={item.text} />
          </List.Item>
        )}

      /> */}
    </Card>
  );
};

export default () =>
  Loadable(DatesCard, {
    endpoints: {
      data: () => request.getJsonData('/api/v1/dashboard/salary/dates')
    }
  });
