import { CalendarTwoTone, ClockCircleTwoTone } from '@ant-design/icons';
import { Divider, Statistic } from 'antd';
import moment from 'moment';

const PayrollCountdown = (props) => {
  const { countries, date } = props;
  const payrollDay = moment(date);

  return (
    <>
      {countries && <Divider>{countries}</Divider>}
      <Statistic title="Days to go" value={payrollDay.diff(moment(), 'days') + 1} prefix={<ClockCircleTwoTone />} />
      <Statistic title="Next payment is on" value={payrollDay.format('L')} prefix={<CalendarTwoTone />} />
    </>
  );
};

export default PayrollCountdown;
