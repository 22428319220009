import { useRequest } from 'ahooks';
import { Empty, List, Spin, Table } from 'antd';
import { flatMap } from 'lodash';
import PropTypes from 'prop-types';

import { DateInline, LinkButton } from '@/components';
import { ROUTE } from '@/configs/general';
import { request } from '@/models';

const TransportContainerPreview = (props) => {
  const { data } = props;

  return (
    <List
      size="small"
      bordered
      dataSource={flatMap(data.packages, (x) => x.items)}
      renderItem={(x) => (
        <List.Item>
          <LinkButton to={`${ROUTE.INBOUND_ACTIVITY}/${x.customData.activityNumber}`}>
            {x.customData.activityNumber}
          </LinkButton>{' '}
          {x.brand} {x.model} {x.senderRef}{' '}
        </List.Item>
      )}
    />
  );
};

const TransportContainerItems = (props) => {
  const { containerId } = props;
  const { data } = useRequest(() => request.getJson(`/api/v1/containers/${containerId}/shipments`), {
    initialData: null
  });

  if (!data) {
    return (
      <Spin>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      </Spin>
    );
  }

  const cols = [
    {
      title: 'Sender',
      dataIndex: 'sender',
      key: 'sender',
      render: (_, row) =>
        [row.sender.name, row.sender.address, row.sender.postalCode, row.sender.city, row.sender.countryCode].join(' ')
    },
    {
      title: 'Receiver',
      dataIndex: 'receiver',
      key: 'receiver',
      render: (_, row) =>
        [
          row.receiver.name,
          row.receiver.address,
          row.receiver.postalCode,
          row.receiver.city,
          row.receiver.countryCode
        ].join(' ')
    },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, row) => <DateInline value={row.createdAt} />
    }
  ];

  return (
    <Table
      columns={cols}
      dataSource={data.data}
      rowKey="guid"
      expandable={{
        expandedRowRender: (row) => <TransportContainerPreview data={row} />
      }}
    />
  );
};

TransportContainerItems.propTypes = {
  containerId: PropTypes.string.isRequired
};

export default TransportContainerItems;
