import { CheckCircleOutlined } from '@ant-design/icons';
import { useBoolean, useRequest, useUpdateEffect } from 'ahooks';
import { Button, Collapse, Skeleton, Spin } from 'antd';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';

import { ENTITY } from '@/configs/entities';
import { authTool, entities } from '@/models';

import { mapAssignedRolesAndPermissionsOfUser, sortEntitiesByName } from './helpers';
import { SESAM_PROJECT } from './sesamProjectConfig';
import { UserPermissionsList } from './UserPermissionsList';

const { Panel } = Collapse;

const OUTDATED_PERMISSIONS_MILLISECONDS = 6 * 30 * 24 * 60 * 60 * 1000;
const USER_KEY_PREFIX = 'user_';

const idToUserKey = (id) => (id ? `${USER_KEY_PREFIX}${String(id).startsWith('#') ? id.slice(1) : id}` : '');
const userKeyToId = (userKey) => Number(userKey.replace(USER_KEY_PREFIX, ''));

let lastCollapseMenuState = [];
let usersRequest;

const UsersList = () => {
  const location = useLocation();
  const [scrolledToUser, { setTrue: setScrolledToUser }] = useBoolean(!location?.hash);

  const projectsRequest = useRequest(
    () =>
      entities.getEntityValues(
        ENTITY.AUTH_PROJECT,
        {
          relations: ['permissions', 'roles', 'roles.permissions']
        },
        true
      ),
    {
      formatResult: (result) => sortEntitiesByName([...(result.data || []), SESAM_PROJECT])
    }
  );

  const externalPermissionsRequest = useRequest((userId) => authTool.getExternalPermissions(userId), {
    onSuccess: (result, [userId]) => {
      usersRequest.mutate((oldData) => {
        const mappedResult = [];

        for (const role of result) {
          let gdprImportant = false;

          role.id = role.name;

          role.permissions = role.permissions.map((permission) => {
            if (permission.gdprImportant) {
              gdprImportant = true;
            }

            return { ...permission, id: permission.name };
          });

          role.gdprImportant = gdprImportant;

          mappedResult.push(role);
        }

        const newData = { ...oldData, syncedAt: new Date().getTime() };

        for (let i = 0; i < newData.users.length; i++) {
          const user = newData.users[i];

          if (user.id !== userId) {
            continue;
          }

          if (!user.projects) {
            user.projects = [];
          }

          user.projects.push({ ...SESAM_PROJECT, assignedRoles: mappedResult, roles: mappedResult });
        }

        return newData;
      });
    },
    manual: true
  });

  usersRequest = useRequest(
    () =>
      entities.getEntityValues(
        ENTITY.USER,
        {
          relations: ['directPermissions', 'roles', 'roles.permissions'],
          order: ['firstName', 'lastName']
        },
        true
      ),
    {
      formatResult: (result) => ({
        users: mapAssignedRolesAndPermissionsOfUser(result?.data, projectsRequest.data),
        syncedAt: new Date().getTime()
      }),
      onSuccess: async () => {
        const userKey = idToUserKey(location.hash);
        const element = document.getElementById(userKey);

        if (element && !scrolledToUser) {
          lastCollapseMenuState = [userKey];
          await externalPermissionsRequest.run(userKeyToId(userKey));

          window.scrollTo(0, element.getBoundingClientRect().top + document.documentElement.scrollTop);
          setScrolledToUser();

          return;
        }

        // We have to re-fetch external permissions of all opened users every time, otherwise external permission disappear (they are mutated into the original permissions)
        // eslint-disable-next-line guard-for-in
        for (const userKeyOfOpenedPanels of lastCollapseMenuState) {
          const userId = userKeyToId(userKeyOfOpenedPanels);

          if (!userId) {
            continue;
          }

          await externalPermissionsRequest.run(userId);
        }
      },
      manual: true
    }
  );

  const confirmPermissionsRequest = useRequest((userId) => authTool.confirmUserPermission(userId), {
    onSuccess: () => usersRequest.refresh(),
    manual: true
  });

  useUpdateEffect(() => {
    usersRequest.run();
  }, [projectsRequest.data?.length]);

  const handleUserPermissionsChange = async () => {
    await projectsRequest.run();
    await usersRequest.run();
  };

  const handleUserPermissionsConfirm = (userId) => () => confirmPermissionsRequest.run(userId);

  const handleCollapseStateChange = (keys) => {
    if (keys.length < lastCollapseMenuState.length) {
      lastCollapseMenuState = keys;

      return;
    }

    lastCollapseMenuState = keys;
    const openedId = userKeyToId(keys[keys.length - 1]);
    externalPermissionsRequest.run(openedId);
  };

  const loading =
    projectsRequest.loading ||
    usersRequest.loading ||
    confirmPermissionsRequest.loading ||
    externalPermissionsRequest.loading;

  return (
    <Skeleton active loading={!projectsRequest?.data || !usersRequest?.data}>
      <Spin spinning={loading}>
        <Collapse
          bordered={false}
          collapsible="header"
          defaultActiveKey={[idToUserKey(location.hash)]}
          onChange={handleCollapseStateChange}
        >
          {usersRequest.data?.users?.map((user) => {
            const { email, firstName, id, lastName, permissionsCheckedLastTime, projects } = user;
            const header = `${firstName} ${lastName} | ${email} | ${id}`;
            const outdatedConfirmation =
              !permissionsCheckedLastTime ||
              new Date().valueOf() - new Date(permissionsCheckedLastTime).valueOf() > OUTDATED_PERMISSIONS_MILLISECONDS;

            return (
              <Panel
                key={idToUserKey(id)}
                id={idToUserKey(id)}
                header={header}
                className={classNames('auth-collapse-item', {
                  'selected-item': location?.hash?.slice(1) === String(id),
                  'highlight-header': outdatedConfirmation
                })}
                extra={
                  outdatedConfirmation && (
                    <Button
                      icon={<CheckCircleOutlined />}
                      type="primary"
                      size="small"
                      onClick={handleUserPermissionsConfirm(id)}
                    >
                      Confirm permissions
                    </Button>
                  )
                }
              >
                <UserPermissionsList
                  projects={projects}
                  projectsList={projectsRequest?.data}
                  user={user}
                  syncedAt={usersRequest.data.syncedAt}
                  onChange={handleUserPermissionsChange}
                />
              </Panel>
            );
          })}
        </Collapse>
      </Spin>
    </Skeleton>
  );
};

export { UsersList };
