import { useMount } from 'ahooks';
import { Spin } from 'antd';
import _ from 'lodash';
import { createContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { PRINTER_TYPE } from '@/configs/entities';
import { PERMISSION, ROUTE } from '@/configs/general';
import { user as userModel } from '@/models';

import { useRedirectTo } from './hooks';

const userContext = createContext(null);
const { Provider } = userContext;

export const UserProvider = (props) => {
  const { children } = props;
  const [data, setData] = useState(null);
  const goLogin = useRedirectTo(ROUTE.LOGIN);
  const history = useHistory();

  const getLocalSettings = () => JSON.parse(window.localStorage.getItem('localSettings')) || {};

  const changeLocalSettings = (changes) => {
    const newSettings = {
      ...getLocalSettings(),
      ...changes
    };

    window.localStorage.setItem('localSettings', JSON.stringify(newSettings));

    setData({
      ...data,
      localSettings: newSettings
    });
  };

  const handleResponse = async (response) => {
    const isAnonymous = response.login === 'anonymous';
    const { user, ...loginData } = response;

    const contactDataPath = 'logisticUser.department.contactData';

    if (
      [
        _.get(user, `${contactDataPath}.name`),
        _.get(user, `${contactDataPath}.address`),
        _.get(user, `${contactDataPath}.postalCode`),
        _.get(user, `${contactDataPath}.city`),
        _.get(user, `${contactDataPath}.countryCode`),
        _.get(user, `${contactDataPath}.email`)
      ].every(Boolean)
    ) {
      user.canForwardActivities = true;
    }

    if (user?.printers?.some(({ settings }) => settings && settings.type === PRINTER_TYPE.SMALL_LABEL)) {
      user.canPrintLabels = true;
    }

    setData({
      isAnonymous,
      isRegistered: !isAnonymous,
      loginData,
      user,
      localSettings: getLocalSettings()
    });
  };

  const getUserSettings = async () => {
    const currentUserData = await userModel.loadCurrentUser();

    if (!currentUserData) {
      setData({
        isAnonymous: true,
        localSettings: getLocalSettings()
      });

      goLogin();

      return;
    }

    await handleResponse(currentUserData);
  };

  useMount(async () => {
    await getUserSettings();
  });

  const hasPermission = (permission) => {
    const userPermissions = data.loginData?.permissions || [];

    if (userPermissions.includes(PERMISSION.WILDCARD)) {
      return true;
    }

    if (_.isString(permission)) {
      return userPermissions.includes(permission);
    }

    if (_.isArray(permission)) {
      return _.intersection(userPermissions, permission).length > 0;
    }

    return false;
  };

  if (!data) {
    return <Spin className="ant-spin-preloader" tip="Loading" />;
  }

  const login = async (credentials, redirectUrl) => {
    const auth = await userModel.login(credentials.login, credentials.password);

    if (!auth) {
      return false;
    }

    await handleResponse(auth);

    history.replace(redirectUrl || '/');

    return true;
  };

  const logout = async () => {
    await userModel.logout();

    goLogin();

    setData({
      isAnonymous: true,
      localSettings: getLocalSettings()
    });
  };

  return (
    <Provider value={{ changeLocalSettings, hasPermission, login, logout, syncUserSettings: getUserSettings, ...data }}>
      {children}
    </Provider>
  );
};

export default userContext;
