import { SaveOutlined } from '@ant-design/icons';
import { Button, DatePicker, Form, Input, PageHeader, Typography } from 'antd';
import moment from 'moment';
import { useState } from 'react';

import { Block } from '@/components';
import { useGoBack } from '@/helpers/hooks';

import TextEditor from './TextEditor';

const MODE = {
  ADD: 'Add',
  EDIT: 'Edit'
};

const getComponentFromField = (field) => {
  if (!field.type) {
    return Input;
  }

  return { TextArea: Input.TextArea, DatePicker, TextEditor }[field.type];
};

const getInitialValues = (data, fields) => {
  const initialValues = {};

  for (const { id } of fields) {
    if (data?.[id]) {
      initialValues[id] = id === 'date' ? moment(data[id]) : data[id];
    }
  }

  return initialValues;
};

const submitButtonIsDisabled = (form) => {
  return !form.isFieldsTouched() || form.getFieldsError().filter(({ errors }) => errors.length).length;
};

const formLayout = {
  labelCol: { span: 4, offset: 1 },
  wrapperCol: { span: 14 }
};

const buttonLayout = {
  wrapperCol: { offset: 5 }
};

const DynamicForm = (props) => {
  const { data, fields, onStopEdit, onSubmit, parentPath, titleAppendix } = props;
  const [form] = Form.useForm();
  const [initialValues] = useState(getInitialValues(data, fields));

  const mode = initialValues.id || initialValues.title ? MODE.EDIT : MODE.ADD;

  const goBack = useGoBack(parentPath);

  const title = `${mode} ${titleAppendix}${mode === MODE.EDIT ? `: ${initialValues.id || initialValues.title}` : ''}`;

  const onFinish = (values) => onSubmit(values);

  return (
    <>
      <PageHeader title={title} onBack={mode === MODE.ADD ? goBack : onStopEdit} />
      <Block>
        <Typography>
          <Form className="dynamic-form" form={form} initialValues={initialValues} onFinish={onFinish} {...formLayout}>
            {fields.map((field) => {
              const { id, name, rules = [{ required: true, message: `Please fill ${name}!` }] } = field;
              const Component = getComponentFromField(field);

              return (
                <Form.Item key={id} label={name} name={id} rules={rules}>
                  <Component placeholder={name} {...(field.props || {})} />
                </Form.Item>
              );
            })}
            <Form.Item shouldUpdate {...buttonLayout}>
              {() => (
                <Button
                  type="primary"
                  htmlType="submit"
                  icon={<SaveOutlined />}
                  disabled={submitButtonIsDisabled(form)}
                >
                  Save
                </Button>
              )}
            </Form.Item>
          </Form>
        </Typography>
      </Block>
    </>
  );
};

export default DynamicForm;
