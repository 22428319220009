import { useRequest } from 'ahooks';
import { List, Spin } from 'antd';
import { reverse } from 'lodash';

import { inbound } from '@/models';

const ServiceComments = (props) => {
  const { data } = props;

  const comments = useRequest(() => inbound.getServiceComments(data?.activityNumber), {
    formatResult: (results) => reverse(results || []),
    refreshDeps: [data.activityNumber],
    initialData: []
  });

  return (
    <Spin spinning={comments.loading}>
      <List
        itemLayout="horizontal"
        dataSource={comments.data}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta
              title={item.Comment}
              description={`${new Date(item.Created).toLocaleDateString('sv-SE')}, ${item.UserName}`}
            />
          </List.Item>
        )}
      />
    </Spin>
  );
};

export default ServiceComments;
