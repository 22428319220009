import { DeleteOutlined, EditOutlined, PlusOutlined, RedoOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { Button, Card, Drawer, Skeleton, Space, Spin } from 'antd';
import { useState } from 'react';

import { ENTITY, PRINTER_SETTINGS, PRINTER_TYPE_NAME } from '@/configs/entities';
import { SchemaForm } from '@/containers';
import { entities } from '@/models';

const Printers = () => {
  const [printerToEditIndex, setPrinterToEditIndex] = useState(null);
  const { data, loading, refresh } = useRequest(() => entities.getEntityValues(ENTITY.PRINTER), {
    initialData: null
  });

  const deleteEntityInstance = useRequest((id) => entities.deleteEntityInstance(ENTITY.PRINTER, id), {
    manual: true,
    onSuccess: refresh
  });

  const handleEditClick = (printerIndex) => () => setPrinterToEditIndex(printerIndex);
  const handleDeleteClick = (id) => () => deleteEntityInstance.run(id);

  const handleSuccessChanges = () => {
    setPrinterToEditIndex(null);
    refresh();
  };

  return (
    <Skeleton active loading={!data?.data}>
      <Spin spinning={loading}>
        <Space className="tab-actions">
          <Button type="primary" onClick={handleEditClick('new')} icon={<PlusOutlined />}>
            Add new printer
          </Button>
          <Button onClick={refresh} icon={<RedoOutlined />}>
            Refresh
          </Button>
        </Space>
        <div className="cards-wrapper">
          {(data?.data || []).map((printer, index) => (
            <PrinterCard
              key={printer.id}
              data={printer}
              index={index}
              onDeleteClick={handleDeleteClick}
              onEditClick={handleEditClick}
              disabled={loading || deleteEntityInstance.loading}
            />
          ))}
          <i area-hidden="true" />
          <i area-hidden="true" />
        </div>
      </Spin>
      <Drawer
        title={
          printerToEditIndex === 'new'
            ? 'Add new printer'
            : `Edit printer: ${data?.data?.[printerToEditIndex]?.name || ''}`
        }
        visible={printerToEditIndex !== null}
        onClose={handleEditClick(null)}
        width={720}
        destroyOnClose
      >
        <SchemaForm
          entity={ENTITY.PRINTER}
          id={data?.data?.[printerToEditIndex]?.id}
          onSuccess={handleSuccessChanges}
          predefinedObjects={{ settings: { ...PRINTER_SETTINGS } }}
        />
      </Drawer>
    </Skeleton>
  );
};

const PrinterCard = (props) => {
  const { data, disabled, index, onDeleteClick, onEditClick } = props;
  const { quantity, type } = data.settings;

  return (
    <Card
      title={data.name}
      actions={[
        <Button type="primary" onClick={onEditClick(index)} icon={<EditOutlined />} disabled={disabled}>
          Edit
        </Button>,
        <Button type="primary" danger onClick={onDeleteClick(data.id)} icon={<DeleteOutlined />} disabled={disabled}>
          Delete
        </Button>
      ]}
    >
      <p>Type: {PRINTER_TYPE_NAME[type]}</p>
      <p>Quantity: {quantity}</p>
    </Card>
  );
};

export default Printers;
