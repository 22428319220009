import { useRequest } from 'ahooks';
import { Card, Space } from 'antd';
import { useContext } from 'react';

import { Block } from '@/components';
import { BalanceInfo } from '@/containers/timeTracker/BalanceInfo';
import { GeneralEmployeeInfo } from '@/containers/timeTracker/GeneralEmployeeInfo';
import { GeneralInfo } from '@/containers/timeTracker/GeneralInfo';
import userContext from '@/helpers/userContext';
import { request, timetracker } from '@/models';

import { TimeTrackerCalendar, VIEW_TYPE } from '../timeTracker/components/Calendar';
import { TimeTrackerProvider } from '../timeTracker/timeTrackerContext';

const Statistics = () => {
  const { user } = useContext(userContext);
  const { timeTrackerUser } = user;

  if (!timeTrackerUser) {
    return null;
  }

  const addWorkDay = useRequest(timetracker.changeWorkday, {
    manual: true
  });

  const employeeWorkPeriod = useRequest(() => timetracker.getCurrentWorkPeriod(timeTrackerUser), {
    onError: (error) => {
      if (error instanceof request.NotFoundError) {
        addWorkDay.run(
          {
            userId: timeTrackerUser.userId,
            schema: timeTrackerUser.schema
          },
          true
        );
      }
    },
    refreshDeps: [addWorkDay.data]
  });
  const employeeWorkDay = useRequest(() => timetracker.getCurrentWorkDay(timeTrackerUser));
  const employeeInfo = useRequest(() => timetracker.getEmployeeInfo(timeTrackerUser));

  const hideFlexTime = user.country === 'NO' || timetracker.isHourlyPaidEmployee(timeTrackerUser);
  const loading = employeeWorkPeriod.loading || employeeWorkDay.loading || addWorkDay.loading || employeeInfo.loading;

  return (
    <>
      <GeneralEmployeeInfo title="My info" user={user} />
      <GeneralInfo user={user} employeeInfo={employeeInfo?.data} loading={loading} />
      <BalanceInfo
        user={user}
        employeeInfo={employeeInfo?.data}
        workPeriod={employeeWorkPeriod?.data}
        workDay={employeeWorkDay?.data}
        loading={loading}
        hideFlexTime={hideFlexTime}
      />
      <Block>
        <Card title="Overview">
          <Space direction="vertical" size="large">
            <TimeTrackerCalendar user={user} editable={false} hideFlexTime={hideFlexTime} />
            <TimeTrackerCalendar user={user} editable={false} type={VIEW_TYPE.SCHEMAS} />
          </Space>
        </Card>
      </Block>
    </>
  );
};

const TimeTrackerStatistics = () => (
  <TimeTrackerProvider>
    <Statistics />
  </TimeTrackerProvider>
);

export { TimeTrackerStatistics };
