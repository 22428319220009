import moment from 'moment';

import { WORKTYPE } from '@/pages/timeTracker/configs';

import request from './request';

const DEFAULT_SCHEMA = 100;

const FULL_WORKDAY_MINUTES_BY_COUNTRY = {
  SE: 456,
  NO: 450
};

const getNextDayString = (timestamp, useExactStamp = false) =>
  moment(timestamp)
    .add(useExactStamp ? 0 : 1, 'day')
    .format('YYYY-MM-DD');

const TimeTrackerModel = {
  holidays: [],
  getFullDayWorkdayMinutes: (timeTrackerUser) => FULL_WORKDAY_MINUTES_BY_COUNTRY[timeTrackerUser.user.country],
  getSchemaMinutes: (timeTrackerUser, withLunch, customSchema) => {
    const schema = customSchema || timeTrackerUser.schema;
    const workTimeMinutes = TimeTrackerModel.getFullDayWorkdayMinutes(timeTrackerUser) * schema * 0.01;
    const lunchMinutes = withLunch && schema > 50 ? 30 : 0;

    return Math.floor(workTimeMinutes) + lunchMinutes;
  },
  isHourlyPaidEmployee: (timeTrackerUser) => Boolean(timeTrackerUser?.isHourlyPaid),
  isEmployeeWithReducedSchema: (timeTrackerUser) =>
    !TimeTrackerModel.isHourlyPaidEmployee(timeTrackerUser) && timeTrackerUser?.schema !== DEFAULT_SCHEMA,
  getLastEditableDayForPrevMonth: () => moment().startOf('month').addWorkdays(4, TimeTrackerModel.holidays).format(),
  canEditByDate: (date) => {
    const startOfCurrentMonth = moment().startOf('month');
    const momentDate = moment(date);

    return (
      momentDate.isAfter(startOfCurrentMonth) ||
      (momentDate.isAfter(startOfCurrentMonth.subtract(1, 'month')) && TimeTrackerModel.canEditPrevMonth())
    );
  },
  canEditPrevMonth: (startDate) => {
    return moment().isBefore(TimeTrackerModel.getLastEditableDayForPrevMonth(startDate));
  },
  checkCheckedIn: (timestamp) => Boolean(timestamp),
  checkOvernight: (timestamp) => {
    if (!timestamp) {
      return false;
    }

    return moment(timestamp).format('L') !== moment().format('L');
  },
  durationToText: (duration) => {
    const abs = Math.abs(duration);
    const hours = Math.floor(abs / 60);
    const minutes = abs % 60;

    return `${duration < 0 ? '-' : ''}${hours ? `${hours} h ` : ''}${minutes} m`;
  },
  toIsoString: (data) => moment(data).toISOString(),
  checkIn: async (userId, timestamp) =>
    await request.postJsonData(`/timetracker/check-in`, { body: { userId, timestamp } }),
  checkOut: async (userId, timestamp, data = { workType: WORKTYPE.REGULAR }) =>
    await request.postJsonData(`/timetracker/check-out`, { body: { userId, timestamp, ...data } }),
  getEmployee: async ({ userId }) => await request.getJsonData(`/timetracker/employee/${userId}`),
  getEmployeeInfo: async ({ userId }, date) =>
    date
      ? await request.getJsonData(`/timetracker/employee/${userId}/info/${date}`)
      : await request.getJsonData(`/timetracker/employee/${userId}/info`),
  getEmployees: async (includeCurrentWorkday) =>
    await request.getJsonData('/timetracker/employees/', { query: { includeCurrentWorkday } }),
  getEmployeesByLocation: async ({ locationId }, includeCurrentWorkday) =>
    await request.getJsonData('/timetracker/employees/', { query: { includeCurrentWorkday, locationId } }),
  getEmployeesOfSupervisor: async ({ userId }, includeCurrentWorkday) =>
    await request.getJsonData('/timetracker/employees/', {
      query: { includeCurrentWorkday, supervisorUserId: userId }
    }),
  getPendingRequests: async ({ userId }) =>
    await request.getJsonData(
      `/timetracker/employee/${userId}/intervals/2020-01-01/${getNextDayString()}?approved=null`
    ),
  getIntervals: async ({ userId, beginTimestamp, endTimestamp, useExactEndStamp }) =>
    await request.getJsonData(
      `/timetracker/employee/${userId}/intervals/${moment(beginTimestamp).format('YYYY-MM-DD')}/${getNextDayString(
        endTimestamp,
        useExactEndStamp
      )}`
    ),
  getWorkdays: async ({ userId, beginTimestamp, endTimestamp, useExactEndStamp }) =>
    await request.getJsonData(
      `/timetracker/employee/${userId}/workdays/${moment(beginTimestamp).format('YYYY-MM-DD')}/${getNextDayString(
        endTimestamp,
        useExactEndStamp
      )}`
    ),
  getWorkPeriods: async ({ userId }) => await request.getJsonData(`/timetracker/employee/${userId}/work-periods`),
  getCurrentWorkPeriod: async ({ userId }) =>
    await request.getJsonData(
      `/timetracker/employee/${userId}/work-period/${moment().startOf('month').format('YYYY-MM-DD')}`
    ),
  getCurrentWorkDay: async ({ userId }) =>
    await request.getJsonData(`/timetracker/employee/${userId}/workdays/${moment().format('YYYY-MM-DD')}`),
  getLocations: async () => await request.getJsonData(`/timetracker/locations`),
  changeInterval: async (data) => await request.postJsonData('/timetracker/intervals', { body: [data] }),
  deleteInterval: async (data) => await request.deleteJsonData('/timetracker/intervals', { body: [data] }),
  changeWorkday: async (data, createWorkPeriod = false) =>
    await request.postJsonData('/timetracker/workday', {
      query: { createWorkPeriod },
      body: {
        ...data
      }
    }),
  changeUser: async (data) => await request.postJsonData('/common/User', { body: data }),
  getUser: async (id) =>
    (
      await request.postJsonData('/common/User/find', {
        body: {
          filters: { id },
          relations: ['timeTrackerUser', 'timeTrackerUser.supervisorUser', 'timeTrackerUser.location']
        }
      })
    )?.[0],
  getUsers: async () => await request.postJsonData('/common/User/find'),
  getHolidays: async () =>
    await request.getJsonData(
      `/timetracker/calendar/holidays/SE/${moment().format('YYYY-MM-DD')}/${moment()
        // .add(2, 'weeks')
        .add(4, 'months')
        .format('YYYY-MM-DD')}`
    ),
  getFireAlarmReportData: async ({ locationId }) =>
    await request.getJsonData(`/timetracker/checked-in-report/${locationId}`),
  getReportsData: async (data) => await request.postJsonData('/timetracker/report/find', { body: data }),
  editReport: async (id, data) => await request.postJsonData(`/timetracker/report/${id}`, { body: data }),
  deleteReport: async (id) => await request.deleteJsonData(`/timetracker/report/${id}`),
  generatePlannedWorkingTimeReport: async ({ id, month, userId }) =>
    await request.getJsonData(
      `/timetracker/planned-working-time-report/${id || month}/${userId ? `?forUserId=${userId}` : ''}`
    ),
  generateWageTypesReport: async ({ id, month, userId }) =>
    await request.getJsonData(`/timetracker/wage-types-report/${id || month}/${userId ? `?forUserId=${userId}` : ''}`),
  generateAbsencesReport: async ({ id, month, userId }) =>
    await request.getJsonData(`/timetracker/absences-report/${id || month}/${userId ? `?forUserId=${userId}` : ''}`),
  generateZalarisReports: async ({ month, userId }) =>
    await request.getJsonData(`/timetracker/generate-zalaris-reports/${month}/${userId ? `?forUserId=${userId}` : ''}`),
  getUsersWithoutReports: async (userIds) =>
    await request.getJsonData(
      `/timetracker/get-users-without-reports/${moment()
        .startOf('month')
        .subtract(1, 'month')
        .format('YYYY-MM-DD[T]HH:mm:[00]')}`,
      { query: { userIds } }
    ),
  generateAccountingReports: async ({ start, end }) =>
    await request.getJsonData(
      `/timetracker/generate-accounting-reports/${moment(start).format('YYYY-MM-DD')}/${moment(end).format(
        'YYYY-MM-DD'
      )}`
    )
};

export default TimeTrackerModel;
