import { message, Spin } from 'antd';
import { mapValues } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import Request from '../models/request';

// TODO: Refactor

class ApiRequest extends React.Component {
  state = {
    loading: 0,
    isNotFound: false,
    error: null,
    ...mapValues(this.props.requests, 'defaultValue')
  };

  inProgress = {};

  cache = {};

  doRequest = async (key, fn, params) => {
    let data = null;

    try {
      data = await fn(params);
    } catch (e) {
      if (e instanceof Request.NotFoundError) {
        this.setState({ isNotFound: true, [key]: null });
      } else {
        this.setState({ error: e });
        message.error(e.message);
      }
    }

    return data;
  };

  handleLoad = async (key, params, allParamsRequired) => {
    const reqId = `${key}:${JSON.stringify(params)}`;

    if (allParamsRequired) {
      for (const value of Object.values(params)) {
        if (value === null || value === undefined) {
          return;
        }
      }
    }

    if (this.inProgress[reqId] || this.state.error) {
      return;
    }

    this.inProgress[reqId] = true;
    const requestParams = this.props.requests[key];
    const fn = requestParams.request;

    let data = null;
    let withLoading = false;

    if (requestParams.cache && this.cache[reqId] !== undefined) {
      data = this.cache[reqId];
    }

    if (!data) {
      let state = {};

      if (this.props.clearOnReload) {
        state = { [key]: requestParams.defaultValue !== undefined ? requestParams.defaultValue : null };
      }

      withLoading = true;
      this.setState((st) => ({ loading: st.loading + 1, isNotFound: false, ...state }));
      data = await this.doRequest(key, fn, params);
    }

    if (requestParams.cache) {
      this.cache[reqId] = data;
    }

    let state = {};

    if (data !== this.state[key]) {
      state = { [key]: data };
    }

    this.setState((st) => ({ ...(withLoading ? { loading: st.loading - 1 } : {}), ...state }));
    delete this.inProgress[reqId];
  };

  handleClear = (key) => {
    this.setState({ [key]: this.props.requests?.[key]?.defaultValue ?? null });
  };

  handleUpdate = (key) => (data) => {
    this.setState({ [key]: data });
  };

  render() {
    const { loading, isNotFound, error } = this.state;
    const { component: Component, requests, withSpinner, ...otherProps } = this.props;
    const extraParams = {
      onTriggerLoad: this.handleLoad,
      onTriggerClear: this.handleClear,
      onTriggerUpdate: this.handleUpdate
    };

    for (const key of Object.keys(requests)) {
      extraParams[key] = this.state[key] || null;
    }

    if (withSpinner) {
      return (
        <Spin spinning={loading > 0} delay={500}>
          <Component isLoading={loading > 0} isNotFound={isNotFound} error={error} {...extraParams} {...otherProps} />
        </Spin>
      );
    }

    return <Component isLoading={loading > 0} isNotFound={isNotFound} error={error} {...extraParams} {...otherProps} />;
  }
}

ApiRequest.propTypes = {
  component: PropTypes.func.isRequired,
  requests: PropTypes.object.isRequired
};

/**
 * Used to sipmlify API requests and make components more stateless
 * @param  {React.Component|function} Component Component to display data
 * @param  {object} props Props
 * @param  {string} props.dataKey Name of the property with dat that will be passed to component
 * @param  {function} props.request Method for request generation
 * @param  {boolean} props.clearOnReload Set data to null when reloading
 * @return {React.Component} result component
 */
const apiWrapper = (Component, props) => <ApiRequest component={Component} {...props} />;

export default apiWrapper;
