import { useRequest } from 'ahooks';
import { Card, List } from 'antd';

import { DateDisplay } from '@/components';
import { request } from '@/models';

const BirthdaysCard = () => {
  const { data, loading } = useRequest(() => request.getJsonData('/api/v1/dashboard/birthdays'), {
    initialData: []
  });

  return (
    <Card title="Personal news" loading={loading}>
      <List
        dataSource={data || []}
        renderItem={(item) => {
          const { age, department, name } = item;
          const date = new Date(item.date);
          date.setFullYear(date.getFullYear() + item.age);

          return (
            <List.Item key={name}>
              <List.Item.Meta
                title={<DateDisplay date={date} inline />}
                description={`${name} working at ${department} celebrates ${age}`}
              />
            </List.Item>
          );
        }}
      />
    </Card>
  );
};

export default BirthdaysCard;
