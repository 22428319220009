import { useRequest } from 'ahooks';
import { Alert, Form, Input, InputNumber, Modal, Select, Spin } from 'antd';
import { useContext } from 'react';

import { ROUTE } from '@/configs/general';
import { EXPORT_FIELD, EXPORT_FIELD_SETTINGS } from '@/configs/inbound';
import { LAYOUT } from '@/configs/layout';
import userContext from '@/helpers/userContext';
import { inbound } from '@/models';

const Export = (props) => {
  const { data, onClose, onOk, ...modalProps } = props;
  const { user } = useContext(userContext);
  const persDep = user?.logisticUser?.settings?.persDep;

  const [form] = Form.useForm();

  const { data: tarics, loading: taricsLoading } = useRequest(() => inbound.getTaricList(persDep), {
    initialData: [],
    ready: Boolean(persDep)
  });
  const { data: exportData, loading: exportDataLoading } = useRequest(
    () => inbound.getExportData(persDep, data.model),
    {
      onSuccess: (newExportData) => {
        const fields = {};

        if (newExportData) {
          for (const key of Object.values(EXPORT_FIELD)) {
            fields[key] = newExportData[key];
          }
        }

        if (!tarics.some(({ id }) => id === Number(fields[EXPORT_FIELD.TARIC_ID]))) {
          fields[EXPORT_FIELD.TARIC_ID] = null;
        }

        form.setFieldsValue(fields);
      },
      ready: Boolean(tarics.length)
    }
  );

  const handleCloseModal = (saveSettings) => () => {
    if (!saveSettings) {
      onClose();

      return;
    }

    form.submit();
  };

  const onFinish = (values) => {
    values.taric = tarics.find((x) => x.id === values.taricId).code;
    props.onOk(values);
    onClose();
  };

  if (!persDep) {
    return (
      <Modal
        title="Export details"
        {...modalProps}
        onClose={onClose}
        maskClosable={false}
        onOk={handleCloseModal(false)}
        onCancel={handleCloseModal(false)}
      >
        <Alert
          message="PersDep is missing"
          description={
            <>
              You can set it <a href={ROUTE.MY_PAGE}>here</a>
            </>
          }
          type="error"
        />
      </Modal>
    );
  }

  const isLoading = taricsLoading || exportDataLoading;

  return (
    <Modal
      title="Export details"
      {...modalProps}
      onClose={onClose}
      maskClosable={false}
      onOk={handleCloseModal(true)}
      onCancel={handleCloseModal(false)}
    >
      <Spin spinning={isLoading}>
        <Form
          form={form}
          onFinish={onFinish}
          // eslint-disable-next-line no-template-curly-in-string
          validateMessages={{ required: 'Please input ${label}' }}
          scrollToFirstError
        >
          <Form.Item label="Model" {...LAYOUT.L5_W19}>
            <strong>{data.model}</strong>
          </Form.Item>
          <Form.Item label="Description" {...LAYOUT.L5_W19}>
            <strong>{exportData?.description || 'Unknown'}</strong>
          </Form.Item>
          <Form.Item {...EXPORT_FIELD_SETTINGS[EXPORT_FIELD.TARIC_ID]} {...LAYOUT.L5_W19}>
            <Select
              showSearch
              filterOption={(input, option) => option.props.search.includes(input.toLowerCase())}
              disabled={isLoading}
            >
              {tarics.map((x) => (
                <Select.Option
                  value={x.id}
                  key={x.id}
                  search={x.code + x.name.toLowerCase()}
                  style={{ whiteSpace: 'normal' }}
                >
                  {x.code} – {x.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item {...EXPORT_FIELD_SETTINGS[EXPORT_FIELD.ORIGIN]} {...LAYOUT.L5_W19}>
            <Input disabled={isLoading} />
          </Form.Item>
          <Form.Item {...EXPORT_FIELD_SETTINGS[EXPORT_FIELD.WEIGHT]} {...LAYOUT.L5_W19}>
            <InputNumber min={0} precision={2} disabled={isLoading} />
          </Form.Item>
          <Form.Item {...EXPORT_FIELD_SETTINGS[EXPORT_FIELD.VALUE]} {...LAYOUT.L5_W19}>
            <InputNumber min={0} precision={2} disabled={isLoading} />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default Export;
