import { Alert, Card, Form, Input, List, Modal, Radio, Select, Spin, Tabs, Typography } from 'antd';
import classNames from 'classnames';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { useContext, useState } from 'react';

import { DateInline } from '@/components';
import { ACTION, FIELD, NEW_SHIPMENT_FIELD, NEW_SHIPMENT_FIELD_SETTINGS, NEW_SHIPMENT_TAB } from '@/configs/inbound';
import { LAYOUT } from '@/configs/layout';
import { SelectAutocomplete } from '@/elements';
import { useDidMount } from '@/helpers/hooks';
import { showError } from '@/helpers/message';
import userContext from '@/helpers/userContext';
import { inbound } from '@/models';

const { Link, Text } = Typography;

const LOGISTIC_ACTION_SETTINGS = {
  [ACTION.RETURN]: {
    useContainerField: 'requireContainerForReturn',
    middleStatusField: 'logisticReturnMiddleStatus'
  },
  [ACTION.FORWARD]: {
    useContainerField: 'requireContainerForForwarding',
    middleStatusField: 'logisticMiddleStatus'
  }
};

const UNAVAILABLE_CUSTOMERS = [];

const ShipmentItemsCard = (props) => (shipment) => {
  const { selectShipment, shipmentForBundling } = props;
  const { createdAt, guid, receiver } = shipment;
  const items = shipment.packages.flatMap((shipmentPackage) => shipmentPackage.items);

  return (
    <Card
      key={guid}
      onClick={selectShipment(shipment)}
      onKeyPress={selectShipment(shipment)}
      role="button"
      tabIndex="0"
      className={classNames('ant-card-selectable', {
        'ant-card-selected': shipmentForBundling && shipmentForBundling.guid === guid
      })}
      size="small"
      title={<DateInline value={createdAt} />}
    >
      {receiver.name}, {receiver.address} {receiver.postalCode} ({receiver.countryCode})
      <br />
      Items: {items.length}
      <br />(
      {items.map(({ model, customData: { activityNumber } }, index) => (
        <span>
          <Text strong>
            <Link>{activityNumber}</Link>
          </Text>{' '}
          - {model}
          {index + 1 !== items.length && ', '}
        </span>
      ))}
      )
    </Card>
  );
};

const NewShipment = (props) => {
  const { data, onClose, onOk, routingData, logisticAction, ...modalProps } = props;
  const { user } = useContext(userContext);
  const departmentId = user?.logisticUser?.department?.serviceProviderDepartmentId;

  const [isLoading, setIsLoading] = useState(true);
  const [wrongRules, setWrongRules] = useState(false);
  const [possibleContactData, setPossibleContactData] = useState({});
  const [contactDataKey, setContactDataKey] = useState(null);
  const [allowedShipments, setAllowedShipments] = useState([]);
  const [allowedContainers, setAllowedContainers] = useState([]);
  const [shippingMethods, setShippingMethods] = useState([]);
  const [selectedShippingMethod, setSelectedShippingMethod] = useState(false);
  const [currentTab, setCurrentTab] = useState(NEW_SHIPMENT_TAB.BUNDLE);
  const [shipmentForBundling, setShipmentForBundling] = useState(null);

  const [form] = Form.useForm();

  const getAdditionalActionParams = (shippingMethod = selectedShippingMethod) => {
    if (!routingData || !shippingMethod) {
      return {};
    }

    const withContainer = routingData.outboundCompetence[LOGISTIC_ACTION_SETTINGS[logisticAction].useContainerField];
    const middleStatus = routingData.outboundCompetence[LOGISTIC_ACTION_SETTINGS[logisticAction].middleStatusField];

    return {
      withContainer,
      middleStatus,
      shipmentTypeId: shippingMethod.properties.serviceId
    };
  };

  const changeShippingMethod = async (shippingMethod) => {
    let contactData;

    if (logisticAction === ACTION.RETURN) {
      const possibleData = await inbound.getReturnAddresses(data.activityNumber);
      let dataKey = 'customer';

      if (possibleData.receiver) {
        dataKey = 'receiver';
      } else if (UNAVAILABLE_CUSTOMERS.includes(data.customerNumber)) {
        dataKey = 'consumer';
      }

      contactData = possibleData[dataKey];
      setPossibleContactData(possibleData);
      setContactDataKey(dataKey);
    } else if (logisticAction === ACTION.FORWARD && routingData.serviceProvider.contactData) {
      contactData = routingData.serviceProvider.contactData;

      if (!contactData.name) {
        contactData.name = contactData.organizationName;
      }
    } else {
      throw new Error('Incorrect state');
    }

    const shipments = await inbound.findPossibleShipmentsForBundling({
      customerNumber: data.customerNumber,
      serviceProviderId: routingData.serviceProvider.id,
      departmentId: get(routingData, 'serviceProviderCompetence.departmentId'), // Optional
      shipmentTypeId: shippingMethod.properties.serviceId,
      logisticAction,
      unlimitedBundling: routingData.outboundCompetence.unlimitedBundling
    });

    setAllowedShipments(shipments);
    setSelectedShippingMethod(shippingMethod);

    if (!shipments?.length) {
      setCurrentTab(NEW_SHIPMENT_TAB.NEW_SHIPMENT);
    }

    const { withContainer } = getAdditionalActionParams(shippingMethod);

    if (withContainer) {
      const containers = await inbound.getAllowedContainers();
      setAllowedContainers(containers);
    }

    form.setFieldsValue(contactData);
  };

  useDidMount(() => {
    const loadData = async () => {
      if (!routingData) {
        setWrongRules(true);
        setIsLoading(false);

        return;
      }

      try {
        const { customerPostalCode: clientPostalCode } = data;

        const loadedShippingMethods =
          (await inbound.getShippingMethods({
            serviceType: data[FIELD.SERVICE_TYPE],
            productType: data[FIELD.PRODUCT_TYPE],
            manufacturerName: data[FIELD.MANUFACTURER],
            modelName: data[FIELD.MODEL],
            actionType: logisticAction,
            departmentId,
            ...(clientPostalCode ? { clientPostalCode } : {})
          })) || [];

        setShippingMethods(loadedShippingMethods);

        const shippingMethod = loadedShippingMethods[0];

        if (!shippingMethod) {
          setWrongRules(true);
          setIsLoading(false);

          return;
        }

        await changeShippingMethod(shippingMethod);
      } catch (e) {
        console.log(e);
        showError(e.message);
      }

      setIsLoading(false);
    };

    loadData();
  });

  const selectShipment = (shipment) => () => setShipmentForBundling(shipment);

  const canFinish = () => {
    if (currentTab === NEW_SHIPMENT_TAB.BUNDLE && !shipmentForBundling) {
      return false;
    }

    return true;
  };

  const handleCloseModal = (saveSettings) => () => {
    if (!saveSettings) {
      onClose();

      return;
    }

    if (currentTab === NEW_SHIPMENT_TAB.BUNDLE) {
      onOk({ ...getAdditionalActionParams(), bundleToShipmentId: shipmentForBundling.guid });
      onClose();

      return;
    }

    form.submit();
  };

  const handleContactDataKey = (key) => () => {
    const contactData = possibleContactData[key];

    setContactDataKey(key);
    form.setFieldsValue(contactData);
  };

  const handleShippingMethod = async (id) => {
    const shippingMethod = shippingMethods.find((x) => x.id === id);
    setIsLoading(true);

    try {
      await changeShippingMethod(shippingMethod);
    } catch (e) {
      console.log(e);
      showError(e.message);
    }

    setIsLoading(false);
  };

  const onFinish = (values) => {
    const { containerId, ...contactData } = values;

    onOk({ ...getAdditionalActionParams(), containerId, ...contactData });
    onClose();
  };

  const { withContainer, middleStatus } = getAdditionalActionParams();

  return (
    <Modal
      title="Shipment details"
      {...modalProps}
      maskClosable={false}
      onClose={onClose}
      onOk={handleCloseModal(true)}
      onCancel={handleCloseModal(false)}
      okButtonProps={{ disabled: !canFinish() || isLoading }}
    >
      <Spin spinning={isLoading}>
        <Form
          form={form}
          onFinish={onFinish}
          // eslint-disable-next-line no-template-curly-in-string
          validateMessages={{ required: 'Please input ${label}' }}
          scrollToFirstError
        >
          {wrongRules && <Alert message="Business rules aren't correctly set" type="error" />}
          {middleStatus && (
            <Alert
              className="clear-margin-top"
              message={
                <>
                  <strong>{middleStatus}</strong> status will be assigned first
                </>
              }
              type="info"
              showIcon
            />
          )}
          <Form.Item label="Shipping method" {...LAYOUT.L6_W18}>
            <Select value={selectedShippingMethod && selectedShippingMethod.id} onChange={handleShippingMethod}>
              {shippingMethods.map((x) => (
                <Select.Option key={x.id} value={x.id}>
                  {x.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          {!wrongRules && (
            <Tabs activeKey={currentTab} onChange={setCurrentTab}>
              <Tabs.TabPane tab="Bundle to existing shipment" key={NEW_SHIPMENT_TAB.BUNDLE}>
                {allowedShipments.length === 0 && (
                  <Alert message="No existing shipments found" type="warning" showIcon />
                )}
                <List
                  dataSource={allowedShipments || []}
                  renderItem={ShipmentItemsCard({ selectShipment, shipmentForBundling })}
                />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Create a new shipment" key={NEW_SHIPMENT_TAB.NEW_SHIPMENT}>
                {Object.keys(possibleContactData).length > 1 && (
                  <Form.Item>
                    <Radio.Group defaultValue={contactDataKey}>
                      {Object.keys(possibleContactData).map((key) => (
                        <Radio.Button value={key} key={key} onClick={handleContactDataKey(key)}>
                          {key}
                        </Radio.Button>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                )}
                {withContainer && (
                  <Form.Item {...NEW_SHIPMENT_FIELD_SETTINGS[NEW_SHIPMENT_FIELD.CONTAINER_ID]} {...LAYOUT.L6_W18}>
                    <SelectAutocomplete
                      options={allowedContainers}
                      extractKey={(x) => x.id}
                      extractValue={(x) => `${x.id} – ${x.name}`}
                    />
                  </Form.Item>
                )}
                <Form.Item {...NEW_SHIPMENT_FIELD_SETTINGS[NEW_SHIPMENT_FIELD.NAME]} {...LAYOUT.L6_W18}>
                  <Input />
                </Form.Item>
                <Form.Item {...NEW_SHIPMENT_FIELD_SETTINGS[NEW_SHIPMENT_FIELD.ADDRESS]} {...LAYOUT.L6_W18}>
                  <Input />
                </Form.Item>
                <Form.Item {...NEW_SHIPMENT_FIELD_SETTINGS[NEW_SHIPMENT_FIELD.POSTAL_CODE]} {...LAYOUT.L6_W18}>
                  <Input />
                </Form.Item>
                <Form.Item {...NEW_SHIPMENT_FIELD_SETTINGS[NEW_SHIPMENT_FIELD.CITY]} {...LAYOUT.L6_W18}>
                  <Input />
                </Form.Item>
                <Form.Item {...NEW_SHIPMENT_FIELD_SETTINGS[NEW_SHIPMENT_FIELD.COUNTRY_CODE]} {...LAYOUT.L6_W18}>
                  <Input />
                </Form.Item>
                <Form.Item {...NEW_SHIPMENT_FIELD_SETTINGS[NEW_SHIPMENT_FIELD.EMAIL]} {...LAYOUT.L6_W18}>
                  <Input />
                </Form.Item>
              </Tabs.TabPane>
            </Tabs>
          )}
        </Form>
      </Spin>
    </Modal>
  );
};

NewShipment.propTypes = {
  data: PropTypes.object.isRequired,
  logisticAction: PropTypes.string.isRequired,
  routingData: PropTypes.object.isRequired,
  onOk: PropTypes.func
};

export default NewShipment;
