import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';

export const useDidMount = (callback) => useEffect(() => callback && callback(), []);

export const useGoTo = (url) => {
  const history = useHistory();

  return () => {
    history.push(url);
  };
};

export const useRedirectTo = (url) => {
  const history = useHistory();

  return () => {
    history.replace(url);
  };
};

export const useGoBack = (defaultUrl) => {
  const history = useHistory();
  const prevLocation = useLastLocation();

  return () => {
    if (prevLocation) {
      history.goBack();
    } else {
      history.push(defaultUrl);
    }
  };
};

export const usePreviousValue = (value) => {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
};

export const useTimeout = (callback, delay) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    if (delay !== null) {
      const id = setTimeout(tick, delay);

      return () => clearTimeout(id);
    }
  }, [delay]);
};
