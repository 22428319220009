import { PlusOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { Button, Input, PageHeader, Table, Tag } from 'antd';
import { trim } from 'lodash';
import { useContext, useState } from 'react';

import { DateInline, LinkButton } from '@/components';
import { ENTITY } from '@/configs/entities';
import { PERMISSION, ROUTE } from '@/configs/general';
import { showError } from '@/helpers/message';
import userContext from '@/helpers/userContext';
import { entities, inbound } from '@/models';

import TransportContainerEditor from './TransportContainerEditor';

const IN_STOCK_STATUS = 'inStock';
const CURRENT_PAGE_SIZE = 10;

const TransportContainersPage = () => {
  const { hasPermission } = useContext(userContext);
  const [searchValue, setSearchValue] = useState('');
  const canEdit = hasPermission(PERMISSION.OUTBOUND);

  const { data, loading, run } = useRequest(
    (commonSearch, offset) =>
      entities.getEntityValues(ENTITY.TRANSPORT_CONTAINER, {
        commonSearch,
        pagination: { offset, limit: CURRENT_PAGE_SIZE }
      }),
    {
      initialData: null
    }
  );

  const [editorVisible, setEditorVisible] = useState(false);

  const search = (page = 1, pageSize = CURRENT_PAGE_SIZE) => {
    const offset = (page - 1) * pageSize;
    run(searchValue, offset);
  };

  const handleSearch = () => search();

  const handleSearchChanged = (e) => {
    setSearchValue(trim(e.target.value));
  };

  const goHome = (row) => async () => {
    try {
      await inbound.saveContainer(row.id, { status: IN_STOCK_STATUS, statusDate: new Date() });
      handleSearch();
    } catch (e) {
      showError(e.message);
    }
  };

  const showEditor = () => setEditorVisible(true);

  const hideEditor = () => {
    setEditorVisible(false);
    handleSearch();
  };

  const cols = [
    {
      title: 'Id',
      key: 'id',
      render: (_, row) => (
        <LinkButton to={`${ROUTE.TRANSPORT_CONTAINER}/${row.id}`} key="1">
          {row.id}
        </LinkButton>
      )
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Status date',
      dataIndex: 'statusDate',
      key: 'statusDate',
      render: (text) => <DateInline value={text} />
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) => <Tag color={text === IN_STOCK_STATUS ? 'success' : 'warning'}>{text}</Tag>
    },
    {
      title: 'Action',
      key: 'action',
      width: '180px',
      render: (_, row) =>
        row.status !== IN_STOCK_STATUS ? (
          <Button type="primary" block ghost onClick={goHome(row)}>
            Check-in home
          </Button>
        ) : null
    }
  ];

  const paginationProps = {
    current: Math.ceil(data?.pagination.offset / CURRENT_PAGE_SIZE) + 1,
    pageSize: CURRENT_PAGE_SIZE,
    total: data?.pagination.count,
    onChange: search,
    showSizeChanger: false
  };

  return (
    <>
      <PageHeader
        title="Containers"
        extra={
          canEdit && (
            <Button onClick={showEditor} icon={<PlusOutlined />} type="primary">
              Add new container
            </Button>
          )
        }
      />
      <Table
        columns={cols}
        dataSource={data?.data}
        rowKey="id"
        loading={loading}
        pagination={paginationProps}
        title={() => (
          <Input.Search
            placeholder="Search for containers"
            size="large"
            enterButton
            value={searchValue}
            onChange={handleSearchChanged}
            onSearch={handleSearch}
            onPressEnter={handleSearch}
          />
        )}
      />
      <TransportContainerEditor id={null} visible={editorVisible} onClose={hideEditor} />
    </>
  );
};

export { TransportContainersPage };
