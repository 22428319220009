import { CalendarTwoTone, EditOutlined, EnvironmentTwoTone } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { Button, Card, Col, PageHeader, Row, Skeleton, Spin, Statistic } from 'antd';
import { useContext, useState } from 'react';

import { DateInline } from '@/components';
import { ENTITY } from '@/configs/entities';
import { PERMISSION, ROUTE } from '@/configs/general';
import { useGoBack } from '@/helpers/hooks';
import userContext from '@/helpers/userContext';
import { entities } from '@/models';

import TransportContainerEditor from './TransportContainerEditor';
import TransportContainerItems from './TransportContainerItems';

const TransportContainerCard = (props) => {
  const { status, statusDate } = props;

  return (
    <Row>
      <Col>
        <Card>
          <Statistic title="Status" value={status} prefix={<EnvironmentTwoTone />} />
        </Card>
      </Col>
      <Col>
        <Card>
          <Statistic
            title="Status changed"
            value={DateInline({ value: statusDate, asString: true })}
            prefix={<CalendarTwoTone />}
          />
        </Card>
      </Col>
    </Row>
  );
};

const TransportContainerPage = (props) => {
  const id = props.match.params.id;
  const { hasPermission } = useContext(userContext);
  const goBack = useGoBack(ROUTE.TRANSPORT_CONTAINERS);
  const canEdit = hasPermission(PERMISSION.OUTBOUND);
  const [isEdit, setIsEdit] = useState(false);

  const { data, loading, refresh } = useRequest(
    () => entities.getEntityValueById(ENTITY.TRANSPORT_CONTAINER, id, false),
    {
      initialData: null
    }
  );
  const item = data?.data;
  const handleEditClick = () => setIsEdit(true);

  const handleEditChanges = () => {
    setIsEdit(false);
    refresh();
  };

  const extra =
    canEdit && id ? (
      <Button type="primary" icon={<EditOutlined />} onClick={handleEditClick}>
        Edit container
      </Button>
    ) : null;

  return (
    <>
      <PageHeader title={`Container: ${item?.name || ''}`} onBack={goBack} extra={extra} />
      <Skeleton active loading={!data}>
        <Spin spinning={loading}>
          {item && (
            <>
              <TransportContainerCard {...item} />
              <Row>
                <Col span={24}>
                  <TransportContainerItems containerId={item.id} />
                </Col>
              </Row>
            </>
          )}
        </Spin>
        <TransportContainerEditor id={id} visible={isEdit} onClose={handleEditChanges} />
      </Skeleton>
    </>
  );
};

export { TransportContainerPage };
