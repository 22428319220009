import { DeleteOutlined, EditOutlined, PlusOutlined, RedoOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { Button, Drawer, Skeleton, Space, Spin, Table } from 'antd';
import { useState } from 'react';

import { ENTITY } from '@/configs/entities';
import { SchemaForm } from '@/containers';
import { entities } from '@/models';

const RESULTS_LIMIT = 20;

const Teams = () => {
  const [teamToEditIndex, setTeamToEditIndex] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(RESULTS_LIMIT);
  const { data, loading, refresh, run } = useRequest(
    (currentPage = 1, limit = RESULTS_LIMIT) =>
      entities.getEntityValues(ENTITY.TEAM, {
        order: ['name'],
        pagination: { offset: (currentPage - 1) * limit, limit }
      }),
    { initialData: null }
  );

  const deleteEntityInstance = useRequest((id) => entities.deleteEntityInstance(ENTITY.TEAM, id), {
    manual: true,
    onSuccess: refresh
  });

  const handleEditClick = (teamIndex) => () => setTeamToEditIndex(teamIndex);
  const handleDeleteClick = (id) => () => deleteEntityInstance.run(id);

  const handleSuccessChanges = () => {
    setTeamToEditIndex(null);
    refresh();
  };

  const handlePaginationChange = (currentPage, limit) => {
    setPage(currentPage);
    setPageSize(limit);
    run(currentPage, limit);
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name'
    },
    {
      title: 'Code',
      dataIndex: 'code'
    },
    {
      title: 'Actions',
      width: 200,
      render: (__, rowData, index) => (
        <Actions
          data={rowData}
          index={index}
          onEditClick={handleEditClick}
          onDeleteClick={handleDeleteClick}
          disabled={deleteEntityInstance.loading}
        />
      )
    }
  ];

  const paginationProps = {
    disable: loading,
    page,
    pageSize,
    position: ['bottomLeft'],
    onChange: handlePaginationChange,
    total: data?.pagination?.count || 0
  };

  return (
    <Skeleton active loading={!data?.data}>
      <Spin spinning={loading}>
        <Space className="tab-actions">
          <Button type="primary" onClick={handleEditClick('new')} icon={<PlusOutlined />}>
            Add new team
          </Button>
          <Button onClick={refresh} icon={<RedoOutlined />}>
            Refresh
          </Button>
        </Space>
        <Table dataSource={data?.data} columns={columns} rowKey="id" pagination={paginationProps} />
      </Spin>
      <Drawer
        title={teamToEditIndex === 'new' ? 'Add new team' : `Edit team: ${data?.data?.[teamToEditIndex]?.name || []}`}
        visible={teamToEditIndex !== null}
        onClose={handleEditClick(null)}
        width={720}
        destroyOnClose
      >
        <SchemaForm entity={ENTITY.TEAM} id={data?.data?.[teamToEditIndex]?.id} onSuccess={handleSuccessChanges} />
      </Drawer>
    </Skeleton>
  );
};

const Actions = (props) => {
  const { data, disabled, index, onEditClick, onDeleteClick } = props;

  return (
    <Space>
      <Button type="primary" onClick={onEditClick(index)} icon={<EditOutlined />} disabled={disabled}>
        Edit
      </Button>
      <Button type="primary" danger onClick={onDeleteClick(data.id)} icon={<DeleteOutlined />} disabled={disabled}>
        Delete
      </Button>
    </Space>
  );
};

export default Teams;
