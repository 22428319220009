import UserContext from '@/helpers/userContext';

// TODO: Remove me

export default function (Component) {
  return function WithUser(props) {
    return (
      <UserContext.Consumer>
        {({ loginData }) => loginData && <Component {...props} loginData={loginData} />}
      </UserContext.Consumer>
    );
  };
}
