import { DeleteOutlined, EditOutlined, GlobalOutlined, PlusOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { Button, Collapse, Drawer, Modal, Skeleton, Space, Spin } from 'antd';
import { useState } from 'react';

import { ENTITY } from '@/configs/entities';
import { SchemaForm } from '@/containers';
import { entities } from '@/models';

import { sortEntitiesByName } from './helpers';
import { ProjectEntitiesList } from './ProjectEntitiesList';

const { Panel } = Collapse;

const ProjectsList = () => {
  const [projectToEditIndex, setProjectToEditIndex] = useState(null);
  const { data, loading, refresh } = useRequest(
    () =>
      entities.getEntityValues(
        ENTITY.AUTH_PROJECT,
        {
          relations: ['permissions', 'roles', 'roles.permissions'],
          order: ['name']
        },
        true
      ),
    {
      formatResult: (result) => sortEntitiesByName(result.data)
    }
  );

  const handleEditClick = (projectIndex) => () => setProjectToEditIndex(projectIndex);
  const handleDeleteClick = () =>
    Modal.warning({
      title: 'To remove this we have a manual fixit-routine',
      content: (
        <div>
          Please create a ticket{' '}
          <Button
            icon={<GlobalOutlined />}
            type="link"
            href="https://jira.elkjop.com/servicedesk/customer/portal/101/create/2974"
            target="_blank"
          >
            here
          </Button>
        </div>
      ),
      centered: true
    });

  const handleSuccessChanges = () => {
    setProjectToEditIndex(null);
    refresh();
  };

  return (
    <Skeleton active loading={!data}>
      <Spin spinning={loading}>
        <Space className="tab-actions">
          <Button type="primary" onClick={handleEditClick('new')} icon={<PlusOutlined />}>
            Add new project
          </Button>
        </Space>
        <Collapse bordered={false} collapsible="header">
          {data?.map((project, index) => {
            const { id, name } = project;

            return (
              <Panel
                key={id}
                header={`${name} | ${id}`}
                className="auth-collapse-item"
                extra={
                  <Space>
                    <Button
                      type="primary"
                      onClick={handleEditClick(index)}
                      icon={<EditOutlined />}
                      disabled={loading}
                      size="small"
                    >
                      Edit
                    </Button>
                    <Button
                      type="primary"
                      danger
                      onClick={handleDeleteClick}
                      icon={<DeleteOutlined />}
                      disabled={loading}
                      size="small"
                    >
                      Delete
                    </Button>
                  </Space>
                }
              >
                <ProjectEntitiesList {...project} onChange={refresh} />
              </Panel>
            );
          })}
        </Collapse>
      </Spin>
      <Drawer
        title={
          projectToEditIndex === 'new' ? 'Add new project' : `Edit project: ${data?.[projectToEditIndex]?.name || []}`
        }
        visible={projectToEditIndex !== null}
        onClose={handleEditClick(null)}
        width={720}
        destroyOnClose
      >
        <SchemaForm
          entity={ENTITY.AUTH_PROJECT}
          id={data?.[projectToEditIndex]?.id}
          onSuccess={handleSuccessChanges}
          hiddenFieldsKeys={['permissions', 'roles']}
          saveButtonText="Save project"
        />
      </Drawer>
    </Skeleton>
  );
};

export { ProjectsList };
