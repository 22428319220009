import { message, Spin } from 'antd';
import React from 'react';

import { DynamicForm } from '@/containers';
import UserContext from '@/helpers/userContext';
import { request } from '@/models';

const fillParams = (str, params) => {
  let processedStr = str;

  for (const [key, val] of Object.entries(params)) {
    processedStr = processedStr.replace(`:${key}`, val);
  }

  return processedStr;
};

class Editable extends React.Component {
  state = {
    editMode: false,
    loading: false,
    data: {}
  };

  async componentDidMount() {
    if (this.matchEndpoint().endsWith('/new')) {
      this.setState({ editMode: true });
    } else {
      await this.load();
    }
  }

  handleSubmit = async (data) => {
    this.setState({ loading: true });

    try {
      const result = await request.postJsonData(this.matchEndpoint(), { body: data });

      if (this.matchEndpoint().endsWith('/new') && this.props.history) {
        this.props.history.replace(this.props.history.location.pathname.replace(/new$/, result.id));
      }
    } catch (e) {
      console.log(e);
      message.error(`Failed to save: ${e.message}`);
    }

    this.setState({ loading: false, editMode: false });
    await this.load();
  };

  handleStartEdit = () => {
    this.setState({ editMode: true });
  };

  handleStopEdit = () => {
    this.setState({ editMode: false });
  };

  matchEndpoint() {
    const { endpoint, match } = this.props;

    if (match) {
      return fillParams(endpoint, match.params);
    }

    return endpoint;
  }

  async load() {
    this.setState({ loading: true });
    const data = await request.getJsonData(this.matchEndpoint());
    this.setState({ data, loading: false });
  }

  render() {
    const Component = this.props.component;
    const { permissionToEdit } = this.props;

    return (
      <Spin spinning={this.state.loading} delay={500}>
        {this.state.editMode ? (
          <DynamicForm
            data={this.state.data}
            {...this.props}
            onStopEdit={this.handleStopEdit}
            onSubmit={this.handleSubmit}
          />
        ) : (
          <UserContext.Consumer>
            {({ hasPermission }) => (
              <Component
                canEdit={permissionToEdit && hasPermission(permissionToEdit)}
                data={this.state.data}
                onStartEdit={this.handleStartEdit}
              />
            )}
          </UserContext.Consumer>
        )}
      </Spin>
    );
  }
}

export default (Component, props) => <Editable component={Component} {...props} />;
