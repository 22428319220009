import Request from './request';

const CustomerCenterModel = {
  getUnrepliedEmails: async ({ page, pageSize }) => {
    return Request.getJson('/api/v1/customer_center/unreplied_emails', {
      query: { offset: page * pageSize, limit: pageSize }
    });
  },
  markEmailReplied: async (recId) => {
    return Request.postJson('/api/v1/customer_center/mark_email_replied', { body: { recId } });
  }
};

export default CustomerCenterModel;
