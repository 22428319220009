// prettier-ignore
import { ApiOutlined, AppstoreOutlined, ClockCircleOutlined, ContainerOutlined, ExportOutlined, GroupOutlined, ImportOutlined, LockOutlined, LoginOutlined, LogoutOutlined, MailOutlined, SearchOutlined, SettingOutlined, ToolOutlined, UserOutlined } from '@ant-design/icons';
import { useBoolean, useDebounceFn, useMount } from 'ahooks';
import { Layout, Menu } from 'antd';
import { useContext, useState } from 'react';
import { Link, Redirect, Route, useLocation } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';

import logo from '@/assets/logo_white.svg';
import { MENU, PERMISSION, ROUTE, SETTINGS_PERMISSIONS } from '@/configs/general';
import userContext from '@/helpers/userContext';
// prettier-ignore
import { AuthPage, CasesPage, DashboardPage, ForwardedShipmentsPage, InboundPage, MyPage, NewsPage, PushActivitiesPage, ReturnedShipmentsPage, SearchPage, SettingsPage, TimeTrackerDashboardPage, TimeTrackerEmployeePage, ToolboxActivityPage, TransportContainerPage, TransportContainersPage, UnrepliedEmailsPage } from '@/pages';
import { TimeTrackerMenuWidget } from '@/pages/timeTracker/TimeTrackerMenuWidget';

const PrivateRoute = ({ component: WrappedComponent, path, permission, ...rest }) => {
  const { isAnonymous, isRegistered, hasPermission } = useContext(userContext);

  if (hasPermission(permission)) {
    return <Route {...rest} path={path} render={(props) => <WrappedComponent {...props} />} />;
  }

  return (
    <Route path={path}>
      <Redirect to={isAnonymous || isRegistered ? '/' : ROUTE.LOGIN} />
    </Route>
  );
};

const checkIsIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

const AppLayout = () => {
  const [isIframe, setIsIframe] = useState(false);
  const { pathname } = useLocation();
  const { changeLocalSettings, isAnonymous, isRegistered, hasPermission, localSettings, logout } = useContext(
    userContext
  );

  const [siderCollapsed, { toggle: toggleSider }] = useBoolean(localSettings?.siderCollapsed);
  const { run: debouncedSaveSettings } = useDebounceFn(
    () => {
      changeLocalSettings({
        siderCollapsed
      });
    },
    { wait: 1000 }
  );

  useMount(() => {
    setIsIframe(checkIsIframe());
  });

  const handleCollapse = async () => {
    toggleSider();

    debouncedSaveSettings();
  };

  const timetrackerWidgetProps = hasPermission(PERMISSION.TRACK_TIME) ? TimeTrackerMenuWidget() : null;

  return (
    <Layout>
      {!isIframe && (
        <Layout.Sider
          collapsible
          collapsed={siderCollapsed}
          onCollapse={handleCollapse}
          collapsedWidth="50"
          width="180"
        >
          <Link to="/" className="logo">
            {!siderCollapsed && <img src={logo} alt="Logo" />}
            {siderCollapsed && <strong>EC</strong>}
          </Link>
          <Menu theme="dark" mode="inline" selectedKeys={[pathname]}>
            {hasPermission(PERMISSION.VIEW_NEWS) && (
              <Menu.Item key="/" icon={<AppstoreOutlined />}>
                <Link to="/">{MENU.DASHBOARD}</Link>
              </Menu.Item>
            )}
            {hasPermission(PERMISSION.PRODUCT_ARRIVAL) && (
              <Menu.Item key={ROUTE.SEARCH} icon={<SearchOutlined />}>
                <Link to={ROUTE.SEARCH}>{MENU.SEARCH}</Link>
              </Menu.Item>
            )}
            {hasPermission(PERMISSION.PRODUCT_ARRIVAL) && (
              <Menu.Item key={ROUTE.TOOLBOX_ACTIVITY} icon={<ToolOutlined />}>
                <Link to={ROUTE.TOOLBOX_ACTIVITY}>{MENU.TOOLBOX_ACTIVITY}</Link>
              </Menu.Item>
            )}
            {hasPermission(PERMISSION.MATCH_CASES_WITH_ACTIVITIES) && (
              <Menu.Item key={ROUTE.CASES} icon={<ContainerOutlined />}>
                <Link to={ROUTE.CASES}>{MENU.CASES}</Link>
              </Menu.Item>
            )}
            {hasPermission(PERMISSION.PRODUCT_ARRIVAL) && (
              <Menu.Item key={ROUTE.FORWARDED_SHIPMENTS} icon={<ExportOutlined />}>
                <Link to={ROUTE.FORWARDED_SHIPMENTS}>{MENU.FORWARDED}</Link>
              </Menu.Item>
            )}
            {hasPermission(PERMISSION.OUTBOUND) && (
              <Menu.Item key={ROUTE.RETURNED_SHIPMENTS} icon={<ImportOutlined />}>
                <Link to={ROUTE.RETURNED_SHIPMENTS}>{MENU.RETURNED}</Link>
              </Menu.Item>
            )}
            {hasPermission(PERMISSION.OUTBOUND) && (
              <Menu.Item key={ROUTE.TRANSPORT_CONTAINERS} icon={<GroupOutlined />}>
                <Link to={ROUTE.TRANSPORT_CONTAINERS}>{MENU.CONTAINERS}</Link>
              </Menu.Item>
            )}
            {hasPermission(PERMISSION.LOGISTIC_PUSH_ACTIVITIES) && (
              <Menu.Item key={ROUTE.PUSH_ACTIVITIES} icon={<ApiOutlined />}>
                <Link to={ROUTE.PUSH_ACTIVITIES}>{MENU.PUSH_ACTIVITIES}</Link>
              </Menu.Item>
            )}
            {hasPermission(PERMISSION.VIEW_UNREPLIED_EMAILS) && (
              <Menu.Item key={ROUTE.FORWARDED_EMAILS} icon={<MailOutlined />}>
                <Link to={ROUTE.FORWARDED_EMAILS}>{MENU.FORWARDED_EMAILS}</Link>
              </Menu.Item>
            )}
            {hasPermission(PERMISSION.TIMETRACKER_SUPERVISOR) && (
              <Menu.Item key={ROUTE.TIME_TRACKER} icon={<ClockCircleOutlined />}>
                <Link to={ROUTE.TIME_TRACKER}>{MENU.TIME_TRACKER}</Link>
              </Menu.Item>
            )}
            <Menu.Divider />
            {timetrackerWidgetProps && timetrackerWidgetProps.map((itemProps) => <Menu.Item {...itemProps} />)}
            {hasPermission(PERMISSION.VIEW_MY_PAGE) && (
              <Menu.Item key={ROUTE.MY_PAGE} icon={<UserOutlined />}>
                <Link to={ROUTE.MY_PAGE}>{MENU.MY_PAGE}</Link>
              </Menu.Item>
            )}
            {hasPermission(SETTINGS_PERMISSIONS) && (
              <Menu.Item key={ROUTE.SETTINGS} icon={<SettingOutlined />}>
                <Link to={ROUTE.SETTINGS}>{MENU.SETTINGS}</Link>
              </Menu.Item>
            )}
            {hasPermission(PERMISSION.AUTH_TOOL_SUPERVISOR) && (
              <Menu.Item key={ROUTE.ACCESS_CONTROL} icon={<LockOutlined />}>
                <Link to={ROUTE.ACCESS_CONTROL}>{MENU.ACCESS_CONTROL}</Link>
              </Menu.Item>
            )}
            {isAnonymous && (
              <Menu.Item key={ROUTE.LOGIN} icon={<LoginOutlined />}>
                <Link to={ROUTE.LOGIN}>{MENU.LOGIN}</Link>
              </Menu.Item>
            )}
            {isRegistered && (
              <Menu.Item key={MENU.LOGOUT} icon={<LogoutOutlined />} onClick={logout}>
                {MENU.LOGOUT}
              </Menu.Item>
            )}
          </Menu>
        </Layout.Sider>
      )}
      <Layout>
        <Layout.Content>
          <LastLocationProvider>
            <PrivateRoute path="/" permission={PERMISSION.VIEW_NEWS} component={DashboardPage} exact />
            <PrivateRoute path={ROUTE.SEARCH} permission={PERMISSION.PRODUCT_ARRIVAL} component={SearchPage} />
            <PrivateRoute
              path={`${ROUTE.INBOUND}/:type?/:identifier?`}
              permission={PERMISSION.PRODUCT_ARRIVAL}
              component={InboundPage}
            />
            <PrivateRoute
              path={ROUTE.FORWARDED_SHIPMENTS}
              permission={PERMISSION.PRODUCT_ARRIVAL}
              component={ForwardedShipmentsPage}
            />
            <PrivateRoute
              path={ROUTE.RETURNED_SHIPMENTS}
              permission={PERMISSION.OUTBOUND}
              component={ReturnedShipmentsPage}
            />
            <PrivateRoute
              path={ROUTE.TRANSPORT_CONTAINERS}
              permission={PERMISSION.OUTBOUND}
              component={TransportContainersPage}
            />
            <PrivateRoute
              path={`${ROUTE.TRANSPORT_CONTAINER}/:id`}
              permission={PERMISSION.OUTBOUND}
              component={TransportContainerPage}
            />
            <PrivateRoute
              path={ROUTE.TOOLBOX_ACTIVITY}
              permission={PERMISSION.PRODUCT_ARRIVAL}
              component={ToolboxActivityPage}
              exact
            />
            <PrivateRoute
              path={ROUTE.CASES}
              permission={PERMISSION.MATCH_CASES_WITH_ACTIVITIES}
              component={CasesPage}
              exact
            />
            <PrivateRoute
              path={ROUTE.FORWARDED_EMAILS}
              permission={PERMISSION.VIEW_UNREPLIED_EMAILS}
              component={UnrepliedEmailsPage}
            />
            <PrivateRoute
              path={ROUTE.TIME_TRACKER}
              permission={PERMISSION.TIMETRACKER_SUPERVISOR}
              component={TimeTrackerDashboardPage}
              exact
            />
            <PrivateRoute
              path={`${ROUTE.TIME_TRACKER_EMPLOYEE}/:employeeId`}
              permission={PERMISSION.TIMETRACKER_SUPERVISOR}
              component={TimeTrackerEmployeePage}
            />
            <PrivateRoute
              path={ROUTE.PUSH_ACTIVITIES}
              permission={PERMISSION.LOGISTIC_PUSH_ACTIVITIES}
              component={PushActivitiesPage}
            />
            <PrivateRoute path={ROUTE.SETTINGS} permission={SETTINGS_PERMISSIONS} component={SettingsPage} />
            <PrivateRoute path={ROUTE.MY_PAGE} permission={PERMISSION.VIEW_MY_PAGE} component={MyPage} />
            <PrivateRoute
              path={ROUTE.ACCESS_CONTROL}
              permission={PERMISSION.AUTH_TOOL_SUPERVISOR}
              component={AuthPage}
            />
            <Route path={`${ROUTE.NEWS_PAGE}/:newsId`} component={NewsPage} />
          </LastLocationProvider>
        </Layout.Content>
        {!isIframe && <Layout.Footer>&copy; {new Date().getFullYear()}</Layout.Footer>}
      </Layout>
    </Layout>
  );
};

export default AppLayout;
