import { ReloadOutlined } from '@ant-design/icons';
import { Button, Result } from 'antd';

const NotFound = (props) => {
  const { onReload } = props;

  const reload = () => {
    if (!onReload) {
      window.location.reload();

      return;
    }

    onReload();
  };

  return (
    <Result
      title="Nothing found. If you are looking for the recently created activity, please reload this page in a few seconds."
      status="warning"
      extra={
        <Button icon={<ReloadOutlined spin />} onClick={reload} type="primary">
          Reload
        </Button>
      }
    />
  );
};

export default NotFound;
