import { Checkbox, Form, Input, Modal } from 'antd';
import PropTypes from 'prop-types';
import { useContext, useState } from 'react';

import { showError } from '@/helpers/message';
import userContext from '@/helpers/userContext';
import { inbound } from '@/models';

const AddServiceComment = (props) => {
  const { data, onClose, ...modalProps } = props;
  const { user } = useContext(userContext);
  const commentsInvisibleToCustomer = user?.logisticUser?.settings?.commentsInvisibleToCustomer;

  const [isLoading, setIsLoading] = useState(false);

  const [form] = Form.useForm();

  const handleCloseModal = (saveComment) => () => {
    if (!saveComment) {
      onClose();

      return;
    }

    form.submit();
  };

  const onFinish = async (values) => {
    setIsLoading(true);

    try {
      await inbound.addServiceComment(data.activityNumber, values);
      onClose();
    } catch (e) {
      showError(e.message);
    }

    setIsLoading(false);
  };

  return (
    <Modal
      title="Add service comment"
      {...modalProps}
      maskClosable={false}
      onClose={onClose}
      onOk={handleCloseModal(true)}
      onCancel={handleCloseModal(false)}
      okButtonProps={{ loading: isLoading }}
    >
      <Form layout="vertical" form={form} onFinish={onFinish} scrollToFirstError>
        <Form.Item label="Text" name="text" rules={[{ required: true, message: 'Please input text' }]}>
          <Input.TextArea rows={4} />
        </Form.Item>
        <Form.Item name="visibleToCustomer" initialValue={!commentsInvisibleToCustomer} valuePropName="checked">
          <Checkbox>Visible to customer</Checkbox>
        </Form.Item>
        <Form.Item name="important" valuePropName="checked">
          <Checkbox>Important</Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
};

AddServiceComment.propTypes = {
  data: PropTypes.object
};

export default AddServiceComment;
