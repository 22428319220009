import { Button, Card, Row } from 'antd';

import { Block, EmployeeStatistic } from '@/components';

const COL_PROPS = { span: 6 };

const GeneralEmployeeInfo = (props) => {
  const { user, loading, title } = props;
  const { timeTrackerUser } = user || {};

  const externalId = timeTrackerUser?.externalId ?? '-';
  const email = user?.email ? (
    <Button type="link" href={`mailto:${user.email}`} size="large">
      {user.email}
    </Button>
  ) : (
    '-'
  );
  const supervisor = timeTrackerUser?.supervisorUser
    ? `${timeTrackerUser?.supervisorUser?.firstName} ${timeTrackerUser?.supervisorUser?.lastName}`
    : '-';
  const premise = timeTrackerUser?.location
    ? `${timeTrackerUser.location.name}: ${timeTrackerUser.location.address}`
    : '-';

  return (
    <Block>
      <Card title={title} className="ant-card-descriptions">
        <Row>
          <EmployeeStatistic title="Employee number" loading={loading} value={externalId} colProps={COL_PROPS} />
          <EmployeeStatistic title="Email" loading={loading} value={email} colProps={COL_PROPS} />
          <EmployeeStatistic title="Manager" loading={loading} value={supervisor} colProps={COL_PROPS} />
          <EmployeeStatistic title="Premise" loading={loading} value={premise} colProps={COL_PROPS} />
        </Row>
      </Card>
    </Block>
  );
};

export { GeneralEmployeeInfo };
