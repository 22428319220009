import { Col, PageHeader, Row } from 'antd';
import { useState } from 'react';

import BirthdaysCard from './BirthdaysCard';
import DatesCard from './DatesCard';
import NewsFeedCard from './NewsFeedCard';

const availableWidgets = [
  {
    colSpan: 8,
    widgetId: 'newsFeed',
    Widget: NewsFeedCard
  },
  {
    colSpan: 8,
    widgetId: 'personalNews',
    Widget: BirthdaysCard
  },
  {
    colSpan: 8,
    widgetId: 'salaryInfo',
    Widget: DatesCard
  }
];

const getAvailableWidgets = (widgets, widgetProps = {}) => {
  return widgets.map(({ colSpan = 24, widgetId, Widget }) => (
    <Col span={widgets.length > 1 ? colSpan : 24} key={widgetId}>
      <Widget widgetId={widgetId} {...widgetProps} />
    </Col>
  ));
};

const DashboardPage = () => {
  const [selectedWidget, setSelectedWidget] = useState(null);

  const handleSelectWidget = (id) => {
    if (selectedWidget) {
      setSelectedWidget(null);

      return;
    }

    const filteredWidget = availableWidgets.filter(({ widgetId }) => widgetId === id);

    if (filteredWidget.length === 1) {
      setSelectedWidget(filteredWidget);
    }
  };

  const widgets = getAvailableWidgets(selectedWidget || availableWidgets, { onSelectWidget: handleSelectWidget });

  return (
    <>
      <PageHeader title="Dashboard" />
      <Row className="dashboard ant-row-cols-equal-height" gutter={[16, 16]}>
        {widgets}
      </Row>
    </>
  );
};

export { DashboardPage };
