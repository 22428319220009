import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { AppLayout } from '@/containers';
import { UserProvider } from '@/helpers/userContext';
import { LoginPage } from '@/pages';

import { ROUTE } from './configs/general';

const App = () => {
  return (
    <Router>
      <UserProvider>
        <Switch>
          <Route path={ROUTE.LOGIN} component={LoginPage} exact />
          <Route component={AppLayout} />
        </Switch>
      </UserProvider>
    </Router>
  );
};

export default App;
