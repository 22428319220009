import { useRequest } from 'ahooks';
import { Input, PageHeader, Spin } from 'antd';
import { trim } from 'lodash';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Block, NotFound } from '@/components';
import { ROUTE } from '@/configs/general';
import { isActivityNumber, isGuid } from '@/helpers/validations';
import { request } from '@/models';

import Activity from './Activity';
import Shipment from './Shipment';

const INBOUND_TYPE = {
  ACTIVITY: 'activity',
  SHIPMENT: 'shipment'
};

const getTitle = (id, type, data) => {
  const key = type.toUpperCase();

  // Extract guid from data, because we are searching by shipment guid or shipment item guid
  const result = `${INBOUND_TYPE[key]}: ${type === INBOUND_TYPE.SHIPMENT && data?.guid ? data.guid : id}`;

  return result.charAt(0).toUpperCase() + result.slice(1);
};

const getSubtitle = (id, type) => {
  if (type !== INBOUND_TYPE.ACTIVITY || isGuid(id)) {
    return null;
  }

  return <img src={`https://senderella.io/v1/barcode/code39?text=${id}&includetext=0`} alt={id} />;
};

const InboundPage = () => {
  const { identifier, type } = useParams();
  const [searchValue, changeSearchValue] = useState(identifier);
  const history = useHistory();

  const { error, data, loading, refresh } = useRequest(
    () => {
      if (type === INBOUND_TYPE.ACTIVITY) {
        return request.getJsonData(`/api/v1/inbound/item/${identifier}`);
      }

      return request.getJsonData(`/api/v1/shipment/${identifier}`);
    },
    {
      refreshDeps: [identifier],
      throwOnError: false
    }
  );

  const search = (value = searchValue) => {
    if (value === identifier) {
      return;
    }

    if (isActivityNumber(value)) {
      history.push(`${ROUTE.INBOUND}/activity/${value}`);
    } else if (isGuid(value)) {
      history.push(`${ROUTE.INBOUND}/shipment/${value}`);
    }
  };

  const handleSearchExecute = () => search();

  const handleSearchChange = (e) => {
    const value = trim(e.target.value);

    changeSearchValue(value);
    search(value);
  };

  return (
    <>
      <PageHeader
        className="ant-page-header-extra-input"
        title={!loading && getTitle(identifier, type, data)}
        subTitle={!loading && getSubtitle(identifier, type)}
        extra={
          <Input.Search
            allowClear
            flushevents={['onSearch', 'onPressEnter']}
            placeholder="Activity Number or shipment GUID"
            size="large"
            value={searchValue}
            onChange={handleSearchChange}
            onPressEnter={handleSearchExecute}
          />
        }
      />
      <Spin spinning={loading}>
        <Block>
          {!data && error && <NotFound onReload={refresh} />}
          {type === INBOUND_TYPE.ACTIVITY && data?.activityNumber && <Activity data={data} onRefresh={refresh} />}
          {type === INBOUND_TYPE.SHIPMENT && data?.guid && <Shipment data={data} onRefresh={refresh} />}
        </Block>
      </Spin>
    </>
  );
};

export { InboundPage };
