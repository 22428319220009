import { PageHeader } from 'antd';

import { SHIPMENTS_TYPE } from '@/configs/shipments';
import { ShipmentsTable } from '@/containers';

const ForwardedShipmentsPage = () => {
  return (
    <>
      <PageHeader title="Forwarder shipments" />
      <ShipmentsTable type={SHIPMENTS_TYPE.FORWARDED} />
    </>
  );
};

export { ForwardedShipmentsPage };
