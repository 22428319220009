import { PageHeader, Tabs } from 'antd';

import { Block } from '@/components';

import { ProjectsList } from './ProjectsList';
import { UsersList } from './UsersList';

const { TabPane } = Tabs;

const AuthPage = () => {
  return (
    <>
      <PageHeader title="Auth access control" />
      <Tabs
        className="tabs-with-block settings-tabs collapsed-headers-clickable-arrow"
        defaultActiveKey="users"
        type="card"
        destroyInactiveTabPane
      >
        <TabPane tab="Users" key="users">
          <Block>
            <UsersList />
          </Block>
        </TabPane>
        <TabPane tab="Projects" key="projects">
          <Block>
            <ProjectsList />
          </Block>
        </TabPane>
      </Tabs>
    </>
  );
};

export { AuthPage };
