import { FireTwoTone } from '@ant-design/icons';
import { Badge } from 'antd';

const MILLISECONDS_IN_DAY = 8.64e7;

// TODO: Change Date() to used package for consistency

const DateDisplay = (props) => {
  const { date: inputDate, inline } = props;

  const date = inputDate instanceof Date ? inputDate : new Date(inputDate);

  if (inline) {
    // eslint-disable-next-line no-restricted-globals
    return isNaN(date) ? null : date.toLocaleString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
  }

  const today = new Date();
  const passedDays = Math.floor((today.getTime() - date.getTime()) / MILLISECONDS_IN_DAY);
  const freshNews = passedDays < 5;

  const output = (
    <DateElement date={date.getDate()} month={date.toLocaleString('sv-SE', { month: 'short' }).substring(0, 3)} />
  );

  if (freshNews) {
    return <Badge count={<FireTwoTone twoToneColor="#d4380d" />}>{output}</Badge>;
  }

  return output;
};

const DateElement = (props) => {
  const { date, month } = props;

  return (
    <div className="date-display">
      <div className="date-display-date">{date}</div>
      <div className="date-display-month">{month}</div>
    </div>
  );
};

export default DateDisplay;
