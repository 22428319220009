import 'moment/locale/en-gb';
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import moment from 'moment'; // TODO: replace MomentJS with DayJS: https://next.ant.design/docs/react/getting-started#Replace-momentjs-to-Day.js
// eslint-disable-next-line no-unused-vars
import momentWeekdayCalc from 'moment-weekday-calc';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
// import registerServiceWorker from './registerServiceWorker';

moment.updateLocale('en', {
  week: {
    dow: 1
  }
});

ReactDOM.render(<App />, document.getElementById('root'));
// registerServiceWorker();
