import { get } from 'lodash';

import { SHIPMENT_ACTIVITY_STATUS } from '@/configs/shipments';

import Request from './request';

const InboundModel = {
  itemIsProcessed: (item) => item.customData && ['Arrived', 'Missing'].includes(item.customData.status),

  filterItemsByStatus: (itemsList, tab) => {
    if (tab === SHIPMENT_ACTIVITY_STATUS.ALL) {
      return itemsList;
    }

    return itemsList.filter(
      (item) =>
        (tab === SHIPMENT_ACTIVITY_STATUS.UNPROCESSED && !InboundModel.itemIsProcessed(item)) ||
        (tab === SHIPMENT_ACTIVITY_STATUS.PROCESSED && InboundModel.itemIsProcessed(item))
    );
  },

  getItemsByType(packages, itemsTab) {
    return packages
      .map((pack) => {
        const data = InboundModel.filterItemsByStatus(pack.items, itemsTab);

        if (!data.length) {
          return null;
        }

        return {
          title: pack.type,
          data
        };
      })
      .filter(Boolean);
  },

  matchItem(item, val) {
    const activity = String(get(item, 'customData.activityNumber'));

    return item.guid === val || activity === val;
  },

  findItem(shipment, val) {
    for (const pack of shipment.packages) {
      const item = pack.items.find((i) => InboundModel.matchItem(i, val));

      if (item) {
        return item;
      }
    }

    return null;
  },

  canCompleteShipment(shipment) {
    if (shipment.finalized) {
      return false;
    }

    for (const pack of shipment.packages) {
      for (const item of pack.items) {
        if (!InboundModel.itemIsProcessed(item)) {
          return false;
        }
      }
    }

    return true;
  },

  finalizeShipment: async (shipmentGuid, data = {}) =>
    await Request.postJsonData(`/api/v1/shipment/${shipmentGuid}/finalize`, { body: data }),

  addItem: async (guid, { activityNumber }) =>
    await Request.postJsonData(`/api/v1/shipment/${guid}/add_item`, { body: { activityNumber } }),

  deleteItem: async (guid, itemGuid) =>
    await Request.postJsonData(`/api/v1/shipment/${guid}/delete_item`, { body: { guid: itemGuid } }),

  setStatus: async (guid, { itemId, status, options }) =>
    await Request.postJsonData(`/api/v1/shipment/${guid}/set_item_status`, { body: { itemId, status, options } }),

  sendPrealert: async (guid, onUserEmail) =>
    await Request.postJson(`/api/v1/shipment/${guid}/send_prealert`, { body: { onUserEmail } }),

  doActionWithActivity: async (action, id, data, params) => {
    return await Request.postJson(`/api/v1/inbound/${id}/${action}`, { body: { data, params } });
  },

  printLabel: async (activityNumber, count) => {
    await Request.postJson(`/api/v1/inbound/${activityNumber}/printLabel`, { body: { count } });
  },

  printPdf: async (printerId, pdfUrl) => {
    await Request.postJson(`/api/v1/inbound/printPdf`, { body: { pdfUrl, printerId } });
  },

  getExportData: async (persDep, model) => {
    return await Request.getJsonData(`/api/v1/inbound/${persDep}/export_data/${model}`);
  },

  getTaricList: async (persDep) => {
    return await Request.getJsonData(`/api/v1/inbound/${persDep}/tarics`);
  },

  getServiceComments: async (activityNumber) => await Request.getJsonData(`/api/v1/service_comments/${activityNumber}`),

  addServiceComment: async (activityNumber, { text, visibleToCustomer, important }) =>
    await Request.postJsonData(`/api/v1/service_comments/${activityNumber}/add`, {
      body: { text, visibleToCustomer, important }
    }),

  getReturnAddresses: async (activityNumber) =>
    await Request.getJsonData(`/api/v1/activity/${activityNumber}/return_addresses`),

  findPossibleShipmentsForBundling: async ({
    customerNumber,
    serviceProviderId,
    departmentId,
    shipmentTypeId,
    logisticAction,
    unlimitedBundling
  }) =>
    await Request.getJsonData('/api/v1/inbound/possible_shipments_for_bundling', {
      query: {
        customerNumber,
        serviceProviderId,
        departmentId,
        shipmentTypeId,
        logisticAction,
        unlimitedBundling: Number(Boolean(unlimitedBundling))
      }
    }),

  getShippingMethods: async ({
    serviceType,
    productType,
    manufacturerName,
    modelName,
    actionType,
    departmentId,
    clientPostalCode
  }) =>
    await Request.getJsonData('/api/v1/inbound/shipping_methods', {
      query: {
        serviceType,
        productType,
        manufacturerName,
        modelName,
        actionType,
        departmentId,
        clientPostalCode
      }
    }),

  getAllowedContainers: async () => await Request.getJsonData('/api/v1/containers/allowed'),

  saveContainer: async (id, data = {}) =>
    await Request.postJsonData(`/api/v1/containers/${id}`, { body: { id, ...data } }),

  createActivity: async (data) => await Request.postJsonData('/api/v1/activity/create', { body: data }),

  pushActivities: async (data, params) => {
    return await Request.postJson('/api/v1/inbound/push_activities', { body: { data, params } });
  },

  search: async (search, includeHubCase = false) => {
    if (!search) {
      return null;
    }

    return await Request.getJsonData('/api/v1/inbound/search', { query: { search, includeHubCase } });
  },

  changeCaseCreatedStatus: async (shipmentGuid, itemGuid, status) =>
    await Request.postJsonData(`/api/v1/shipment/change_case_created_status`, {
      body: { shipmentGuid, itemGuid, status }
    }),

  matchCase: async (guid, activityNumber) =>
    await Request.postJsonData(`/api/v1/inbound/match_case`, { body: { guid, activityNumber } }),

  getLabelUrl: (guid) =>
    `${process.env.NODE_ENV === 'development' ? 'http://localhost:4000' : ''}/api/v1/shipment/get_label/${guid}`
};

export default InboundModel;
