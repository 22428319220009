import { SaveOutlined } from '@ant-design/icons';
import { useMount, useRequest } from 'ahooks';
import { Button, Checkbox, DatePicker, Divider, Radio, Select, Space } from 'antd';
import moment from 'moment';
import { useState } from 'react';

import { TIMETRACKER_SCHEMA_LABEL } from '@/configs/entities';
import { showError } from '@/helpers/message';
import { timetracker } from '@/models';

// prettier-ignore
import { DAY_ABSENCE, DAY_ABSENCE_LABEL, DAY_ABSENCE_LABEL_NO, DAY_ABSENCE_NO, HOURLY_ABSENCE, HOURLY_ABSENCE_LABEL, HOURLY_ABSENCE_LABEL_NO, HOURLY_ABSENCE_NO } from '../configs';

const SCHEMA_OPTIONS = (country, includeZero = true) =>
  Object.keys(TIMETRACKER_SCHEMA_LABEL[country] || [])
    .map((key) =>
      includeZero || key !== '0'
        ? {
            label: TIMETRACKER_SCHEMA_LABEL[country][key],
            value: Number(key)
          }
        : null
    )
    .filter(Boolean);

const PARTDAY_ABSENCE_OPTIONS = {
  SE: Object.values(HOURLY_ABSENCE).map((value) => ({ label: HOURLY_ABSENCE_LABEL[value], value })),
  NO: Object.values(HOURLY_ABSENCE_NO).map((value) => ({ label: HOURLY_ABSENCE_LABEL_NO[value], value }))
};

const FULLDAY_ABSENCE_OPTIONS = {
  SE: Object.values(DAY_ABSENCE).map((value) => ({ label: DAY_ABSENCE_LABEL[value], value })),
  NO: Object.values(DAY_ABSENCE_NO).map((value) => ({ label: DAY_ABSENCE_LABEL_NO[value], value }))
};

const getEqualValueByKey = (arr, key) => {
  if (!arr?.length || !key) {
    return null;
  }

  return arr.every((value) => value?.[key] === arr[0]?.[key]) ? arr[0][key] : null;
};

const Form = (props) => {
  const { workdays, selectedDates, user, onUpdate } = props;
  const { timeTrackerUser } = user;

  const selectedStart = moment(selectedDates.startStr);
  const selectedEnd = moment(selectedDates.endStr).subtract(1, 'day');
  const selectedWorkdays = workdays.filter(({ date }) =>
    moment(date).isBetween(selectedStart, selectedEnd, 'day', '[]')
  );

  const [schema, setSchema] = useState(getEqualValueByKey(selectedWorkdays, 'schema'));
  const [code, setCode] = useState(getEqualValueByKey(selectedWorkdays, 'code'));
  const [partDayAbsenceSelected, setPartDayAbsenceSelected] = useState(false);
  const [partDayCode, setPartDayCode] = useState(null);
  const [partDaySchema, setPartDaySchema] = useState(null);
  const { run, loading } = useRequest(timetracker.changeWorkday, { manual: true, throwOnError: true });

  useMount(() => {
    if (code) {
      setSchema(null);
    }
  });

  const isRangeSelected = Boolean(selectedWorkdays.length > 1);

  const handleChangeSchema = (e) => {
    setSchema(e.target.value);
    setCode(null);
  };

  const handlePartDaySelect = (e) => {
    const value = e.target.checked;
    setPartDayAbsenceSelected(value);

    if (!value) {
      return;
    }

    if (!schema) {
      setSchema(timeTrackerUser.schema);
    }

    setCode(null);
  };

  const handleChangePartFayAbsenceCode = (e) => {
    setPartDayCode(e);

    if (partDaySchema) {
      return;
    }

    setPartDaySchema(25);
  };

  const handleChangePartDaySchema = (e) => {
    setPartDaySchema(e.target.value);
  };

  const handleChangeAbsenceCode = (e) => {
    setSchema(null);
    setPartDayAbsenceSelected(false);
    setPartDayCode(null);
    setPartDaySchema(null);
    setCode(e);
  };

  const handleSave = async () => {
    try {
      do {
        await run({
          userId: timeTrackerUser.userId,
          date: selectedStart.format('YYYY-MM-DD'),
          schema: code ? timeTrackerUser.schema : schema,
          partDayCode: partDayCode && partDaySchema ? `${partDayCode}_${partDaySchema}` : null,
          code
        });

        selectedStart.add(1, 'day');
      } while (selectedEnd.diff(selectedStart, 'days') >= 0);

      if (onUpdate) {
        onUpdate();
      }
    } catch (e) {
      showError(e.message, { messagePrefix: 'Time tracker error: ' });
    }
  };

  return (
    <Space direction="vertical">
      {!isRangeSelected && (
        <DatePicker defaultValue={selectedStart} className="ant-picker-remove-disabled-styles" disabled />
      )}
      {isRangeSelected && (
        <DatePicker.RangePicker
          defaultValue={[selectedStart, selectedEnd]}
          className="ant-picker-remove-disabled-styles"
          disabled
        />
      )}
      <Radio.Group
        className="ant-radio-vertical-group ant-radio-vertical-group-small"
        options={SCHEMA_OPTIONS(user.country)}
        onChange={handleChangeSchema}
        value={schema}
      />
      <Checkbox onChange={handlePartDaySelect} checked={partDayAbsenceSelected}>
        Add a part-day absence
      </Checkbox>
      {partDayAbsenceSelected && (
        <>
          <Select
            options={PARTDAY_ABSENCE_OPTIONS[user.country]}
            onChange={handleChangePartFayAbsenceCode}
            value={partDayCode}
            placeholder="Select a part-day absence"
            style={{ width: '100%' }}
          />
          <Radio.Group
            className="ant-radio-vertical-group ant-radio-vertical-group-small"
            options={SCHEMA_OPTIONS(user.country, false)}
            onChange={handleChangePartDaySchema}
            value={partDaySchema}
          />
        </>
      )}
      <Divider>or set full-day absence</Divider>
      <Select
        options={FULLDAY_ABSENCE_OPTIONS[user.country]}
        onChange={handleChangeAbsenceCode}
        value={code}
        placeholder="Select a full-day absence"
        style={{ width: '100%' }}
      />
      <Button type="primary" icon={<SaveOutlined />} onClick={handleSave} loading={loading}>
        Save
      </Button>
    </Space>
  );
};

const TimeTrackerSchemaEditForm = (props) => {
  const { workdays, selectedDates } = props;

  if (!workdays || !selectedDates) {
    return null;
  }

  return <Form {...props} />;
};

export { TimeTrackerSchemaEditForm };
