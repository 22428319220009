export const LOAD = {
  ADDITIONAL_SHIPMENT: 'additionalShipment',
  MANUFACTURERS: 'manufacturers',
  MATCHED_MODEL: 'matchedModel',
  STATUSES_LIST: 'statusesList',
  PRODUCT_TYPES: 'productTypes',
  ROUTING: 'routing',
  SERVICE_TYPES: 'serviceTypes',
  SHIPMENTS_CONTAINING_ACTIVITY: 'shipmentsContainingActivity'
};

export const FIELD = {
  MANUFACTURER: 'manufacturer',
  SERVICE_TYPE: 'serviceType',
  PRODUCT_TYPE: 'productType',
  IMEI: 'imei',
  SERIAL: 'serial',
  MODEL: 'model',
  GUAR_REF: 'guarRef',
  PERSON: 'logisticPerson',
  SERVICE_CODE: 'logisticServiceCode',
  TEAM: 'logisticTeam',
  DISTRICT: 'logisticDistrict',
  PERS_DEP: 'logisticPersDep',
  STATUS: 'logisticStatus',
  PRODUCT_DESCRIPTION: 'productDescription',
  PACKAGE_MATERIALS: 'packageMaterials',
  PRODUCT_LOCATION: 'productLocation',
  NO_ACCESSORIES: 'noAccessories',
  UNKNOWN_ACCESSORIES: 'unknownAccessories',
  ACCESSORIES: 'accessories',
  SOLUTION: 'solution',
  SALES_DATE: 'salesDate',
  LABELS_COUNT: 'labelsCount'
};

export const FIELD_SETTINGS = {
  [FIELD.MANUFACTURER]: {
    label: 'Brand',
    name: FIELD.MANUFACTURER,
    rules: [{ required: true, message: 'Please input brand' }]
  },
  [FIELD.SERVICE_TYPE]: {
    label: 'Service Type',
    name: FIELD.SERVICE_TYPE,
    rules: [{ required: true, message: 'Please input service type' }]
  },
  [FIELD.PRODUCT_TYPE]: {
    label: 'Product type',
    name: FIELD.PRODUCT_TYPE,
    rules: [{ required: true, message: 'Please input product type' }]
  },
  [FIELD.IMEI]: {
    label: 'IMEI',
    name: FIELD.IMEI,
    rules: [{ required: true, message: 'Please input IMEI' }]
  },
  [FIELD.SERIAL]: {
    label: 'Serial',
    name: FIELD.SERIAL,
    rules: [{ required: true, message: 'Please input serial' }]
  },
  [FIELD.MODEL]: {
    label: 'Model',
    name: FIELD.MODEL
  },
  [FIELD.GUAR_REF]: {
    label: 'Guar Ref',
    name: FIELD.GUAR_REF
  },
  [FIELD.PERSON]: {
    label: 'Person',
    name: FIELD.PERSON
  },
  [FIELD.SERVICE_CODE]: {
    label: 'Service code',
    name: FIELD.SERVICE_CODE,
    rules: [{ required: true, message: 'Please input service code' }]
  },
  [FIELD.TEAM]: {
    label: 'Group',
    name: FIELD.TEAM
  },
  [FIELD.DISTRICT]: {
    label: 'District',
    name: FIELD.DISTRICT,
    rules: [{ required: true, message: 'Please input district' }]
  },
  [FIELD.PERS_DEP]: {
    label: 'Department',
    name: FIELD.PERS_DEP,
    rules: [{ required: true, message: 'Please input department' }]
  },
  [FIELD.STATUS]: {
    label: 'Status',
    name: FIELD.STATUS,
    rules: [{ required: true, message: 'Please input status' }],
    trigger: 'onSelect'
  },
  [FIELD.PRODUCT_DESCRIPTION]: {
    label: 'Product description',
    name: FIELD.PRODUCT_DESCRIPTION
  },
  [FIELD.PACKAGE_MATERIALS]: {
    label: 'Package materials',
    name: FIELD.PACKAGE_MATERIALS
  },
  [FIELD.PRODUCT_LOCATION]: {
    label: 'Product location',
    name: FIELD.PRODUCT_LOCATION
  },
  [FIELD.NO_ACCESSORIES]: {
    name: FIELD.NO_ACCESSORIES,
    valuePropName: 'checked'
  },
  [FIELD.UNKNOWN_ACCESSORIES]: {
    name: FIELD.UNKNOWN_ACCESSORIES,
    valuePropName: 'checked'
  },
  [FIELD.ACCESSORIES]: {
    label: 'Accessories',
    name: FIELD.ACCESSORIES,
    rules: [{ required: true, message: 'Please input accessories' }]
  },
  [FIELD.SOLUTION]: {
    label: 'Solution',
    name: FIELD.SOLUTION
  },
  [FIELD.SALES_DATE]: {
    label: 'Sales date',
    name: FIELD.SALES_DATE
  },
  [FIELD.LABELS_COUNT]: {
    label: 'Labels',
    name: FIELD.LABELS_COUNT
  }
};

export const LOGISTIC_FIELD = [
  FIELD.PERSON,
  FIELD.SERVICE_CODE,
  FIELD.TEAM,
  FIELD.DISTRICT,
  FIELD.PERS_DEP,
  FIELD.STATUS
];

export const OTHER = {
  ID: 'other',
  LABEL: 'Other'
};

export const ACTION = {
  ARRIVE: 'arrive',
  FORWARD: 'forward',
  EXPORT: 'export',
  SET_STATUS: 'set_status',
  RETURN: 'return'
};

export const ACTION_LABEL = {
  [ACTION.ARRIVE]: 'Arrive',
  [ACTION.FORWARD]: 'Forward',
  [ACTION.EXPORT]: 'Export',
  [ACTION.SET_STATUS]: 'Set status',
  [ACTION.RETURN]: 'Return'
};

export const ACTIVITY_STATUS = {
  FAILED: 'failed',
  PROCESSED: 'processed'
};

export const EXPORT_FIELD = {
  TARIC_ID: 'taricId',
  ORIGIN: 'origin',
  WEIGHT: 'weight',
  VALUE: 'value'
};

export const EXPORT_FIELD_SETTINGS = {
  [EXPORT_FIELD.TARIC_ID]: {
    label: 'Taric',
    name: EXPORT_FIELD.TARIC_ID,
    rules: [{ required: true }]
  },
  [EXPORT_FIELD.ORIGIN]: {
    label: 'Origin',
    name: EXPORT_FIELD.ORIGIN,
    rules: [{ required: true }]
  },
  [EXPORT_FIELD.WEIGHT]: {
    label: 'Weight',
    name: EXPORT_FIELD.WEIGHT,
    rules: [{ required: true }]
  },
  [EXPORT_FIELD.VALUE]: {
    label: 'Value',
    name: EXPORT_FIELD.VALUE,
    rules: [{ required: true }]
  }
};

export const NEW_SHIPMENT_TAB = {
  BUNDLE: 'bundle',
  NEW_SHIPMENT: 'newShipment'
};

export const NEW_SHIPMENT_FIELD = {
  CONTAINER_ID: 'containerId',
  NAME: 'name',
  ADDRESS: 'address',
  POSTAL_CODE: 'postalCode',
  CITY: 'city',
  COUNTRY_CODE: 'countryCode',
  EMAIL: 'email'
};

export const NEW_SHIPMENT_FIELD_SETTINGS = {
  [NEW_SHIPMENT_FIELD.CONTAINER_ID]: {
    label: 'Container',
    name: NEW_SHIPMENT_FIELD.CONTAINER_ID,
    rules: [{ required: true, message: 'Please select a container' }]
  },
  [NEW_SHIPMENT_FIELD.NAME]: {
    label: 'Name',
    name: NEW_SHIPMENT_FIELD.NAME,
    rules: [{ required: true }]
  },
  [NEW_SHIPMENT_FIELD.ADDRESS]: {
    label: 'Address',
    name: NEW_SHIPMENT_FIELD.ADDRESS,
    rules: [{ required: true }]
  },
  [NEW_SHIPMENT_FIELD.POSTAL_CODE]: {
    label: 'Postal code',
    name: NEW_SHIPMENT_FIELD.POSTAL_CODE,
    rules: [{ required: true }]
  },
  [NEW_SHIPMENT_FIELD.CITY]: {
    label: 'City',
    name: NEW_SHIPMENT_FIELD.CITY,
    rules: [{ required: true }]
  },
  [NEW_SHIPMENT_FIELD.COUNTRY_CODE]: {
    label: 'Country code',
    name: NEW_SHIPMENT_FIELD.COUNTRY_CODE,
    rules: [{ required: true }]
  },
  [NEW_SHIPMENT_FIELD.EMAIL]: {
    label: 'Email',
    name: NEW_SHIPMENT_FIELD.EMAIL,
    rules: [{ required: true }]
  }
};
